import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { login } from '../../../../src/login/Actions';
import { addMenuItems } from '../../../../src/menu/Actions';
import Confirm from '../../../../src/confirm/Confirm';
import Message from '../../../../src/message/Message';
import Notice from '../../../../src/notice/Notice';
import Menu from '../../../../src/menu/MenuComplete';

const menuList = [
  {url: '/new', header: 'Havainnoi'},
  {url: '/reports', header: 'Raportit'}
];

class App extends React.Component {

  constructor(props) {
    super(props);

    localStorage.loadingSuccess = true;
    this.props.addMenuItems(menuList);

    this.path = this.props.location.pathname;
    this.login = false;
  }

  render() {
    if (this.props.user == null) {
      return (
        <div>
          <Navigate to='/login' push/>
          <Message store={this.props.store} />
        </div>
      );
    }
    else {
      if (!this.props.location.pathname.includes('/new') &&
          !this.props.location.pathname.includes('/edit') &&
          !this.props.location.pathname.includes('/reports') &&
          !this.props.location.pathname.match('/report/edit/[1-9]d*') &&
          !this.props.location.pathname.match('/report/[1-9]d*')) {
        if (this.path !== this.props.location.pathname) {
          return <Navigate to={this.path} push/>;
        }
        return <Navigate to='/new' push/>;
      }
    }

    return (
      <div>
        { this.props.user != null ? <Menu store={this.props.store} /> : null }
        <Message store={this.props.store} />
        <Notice store={this.props.store} />
        <Confirm store={this.props.store} />
      </div>
    );
  }
}


export default connect(state => ({
  user: state.login.get('user'),
}), { login, addMenuItems })(App);
