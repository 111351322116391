export const addReports = reports => ({
  type: 'ADD_REPORTS',
  reports
});


export const removeReport = report => ({
  type: 'REMOVE_REPORT',
  report
});


export const addReport = report => ({
  type: 'ADD_REPORT',
  report
});


export const changeReport = report => ({
  type: 'CHANGE_REPORT',
  report
});

export const addTemplates = templates => ({
  type: 'ADD_TEMPLATES',
  templates
});

export const addTemplate = template => ({
  type: 'ADD_TEMPLATE',
  template
});

export const changeTemplate = template => ({
  type: 'CHANGE_TEMPLATE',
  template
});

export const removeTemplate = template => ({
  type: 'REMOVE_TEMPLATE',
  template
});
