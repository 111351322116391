import { fromJS } from 'immutable';

const initialState = fromJS({
  observations: []
});

export default (state = initialState,
    action) => {
  switch (action.type) {
    case 'ADD_OBSERVATIONS': {
      return state.set('observations', fromJS(action.observations));
    }

    case 'REMOVE_OBSERVATION': {
      const removableObservation = state.get('observations').findIndex(observation => observation.get('id') === fromJS(action.observation));
      if (removableObservation !== -1) {
        return state.set('observations', state.get('observations').delete(removableObservation));
      }
      return state;
    }
  
    case 'ADD_OBSERVATION': {
     const newObservation = fromJS(action.observation);
      if (state.get('observations').find(observation => observation.get('id') === newObservation.get('id')) === undefined) {
        return state.set('observations', state.get('observations').push(newObservation));
      }
      return state;
    }

    case 'CHANGE_OBSERVATION': {
      const changingIndex = state.get('observations').findIndex(observation => observation.get('id') === action.observation.id);
      
      if (changingIndex === -1) return state;

      if (state.get('observations').get(changingIndex) === fromJS(action.observation)) {
        return state;
      }

      let observation = fromJS(action.observation);
      let fields = observation.get('fields');

      for (let field of fields) {
        const key = field[0];
        fields = fields.set(key, action.observation.fields[key]);
      }

      observation = observation.set('fields', fields);
      const newObservations = state.get('observations').set(changingIndex, observation);
      return state.set('observations', newObservations);
    }

    case 'CLEAR_OBSERVATION': {
      return state.set('observations', fromJS([]));
    }

    case 'CHANGE_OBSERVATION_ORDER': {
      const changingIndex = state.get('observations').findIndex(observation => observation.get('id') === action.observationId);
      
      if (changingIndex === -1) return state;

      let observation = state.get('observations').get(changingIndex);

      observation = observation.set('order', action.order);

      const newObservations = state.get('observations').set(changingIndex, observation);
      return state.set('observations', newObservations);
    }

    case 'TOGGLE_HIDE_OBSERVATION': {
      const changingIndex = state.get('observations').findIndex(observation => observation.get('id') === action.observationId);
      
      if (changingIndex === -1) return state;

      let observation = state.get('observations').get(changingIndex);

      observation = observation.set('hide', !observation.get('hide'));

      const newObservations = state.get('observations').set(changingIndex, observation);
      return state.set('observations', newObservations);
    }

    default: {
      return state;
    }
  }
};