import React from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { showNotice } from '../../../../src/notice/Actions';
import List from '../../../../src/list/List';
import { addReports, addReport, changeReport, removeReport } from './Actions';
import { fetch, Socket } from '../utils';
import { fromJS } from 'immutable';
import './Report.css';

export const STATE_NAMES = {
  1: 'Käynnissä',
  2: 'Alustava',
  3: 'Valmis'
}

class Reports extends React.Component {

  constructor(props) {
    super(props);

    this.socket = null

    this.state = {
      loading: false,
      reports: []
    };

    this.showReport = this.showReport.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.getReports();
    this.setupSocket();
  }

  componentWillUnmount() {
    if (this.socket != null) this.socket.close();
  }

  setupSocket() {
    if (this.socket == null && typeof(WebSocket) !== 'undefined') {
      this.socket = Socket();
      this.socket.onmessage = async function(e) {
        const data = JSON.parse(e.data);

        if (data.modelName !== 'report') {
          return;
        }

        if (data['operation'] === 'create') {
          const exist = this.props.reports.find(report =>
            report.get('id') === data.model.id);
          if (exist) {
            return;
          }

          let report = data.model;

          report.observationCount = 0;
          report.state.name = STATE_NAMES[report.state.id];

          this.props.addReport(report);
        }
        else if (data['operation'] === 'update') {
          let report = data.model;

          report.observationCount = report.observations.length;
          report.state.name = STATE_NAMES[report.state.id];

          this.props.changeReport(report);
        }
        else if (data['operation'] === 'delete') {
          this.props.removeReport(data.model.id);
        }
      }.bind(this)
    }
  }

  async sendSavedReports() {
    let reports = JSON.parse(localStorage['savedReports']);
    let newReports = reports.slice();

    for (let index in reports) {
      const report = reports[index];

      try {
        await fetch(`mutation NewReport {
                      reportCreate(report: {
                          name:` + JSON.stringify(report.name) +
                          ` startTime:` + JSON.stringify(report.startTime) +
                          ` receivers:` + JSON.stringify(report.receivers) +
                          ` state: {id: 1}
                      }) {
                          id
                          reporter{id}
                          state{
                              id
                              name
                          }
                      }
                    }`
        );

        newReports.splice(newReports.findIndex(m => m['id'] === report['id']), 1);
      } catch(err) {
        console.log(err);
      }
    }
    
    localStorage['savedReports'] = JSON.stringify(newReports);
    this.getReports(false);
  }

  getReports(sendSaved=true) {
    this.setState({ loadingReports: true });

    let reportsData = [];

    fetch(
      `query { reportsByTokenOrg { id, name, startTime, reporter{id, first_name, last_name},
       state{id}, receivers, observations{id} } }`
    ).then(response => {
      let data = response.data.reportsByTokenOrg;

      if (sendSaved &&
          typeof(Storage) !== 'undefined' &&
          localStorage['savedReports'] != null &&
          JSON.parse(localStorage['savedReports']).length !== 0) {
        this.sendSavedReports();
        return;
      }

      data.sort((a,b) => (new Date(b.startTime) - new Date(a.startTime)));

      for (let report of data) {
        report.observationCount = report.observations.length;
        report.state.name = STATE_NAMES[report.state.id];
      }

      reportsData = data;
    }).catch(async error => {
      console.log(error);

      if (typeof(Storage) !== 'undefined' &&
          localStorage['savedReports'] != null &&
          JSON.parse(localStorage['savedReports']).length !== 0) {
        let reports = JSON.parse(localStorage['savedReports']);
        let observations = [];

        if (typeof(Storage) !== 'undefined' &&
            localStorage['savedObservations'] != null &&
            JSON.parse(localStorage['savedObservations']).length !== 0) {
          observations = JSON.parse(localStorage['savedObservations']);
        }

        for (let report of reports) {
          const observationsOfReport = observations.filter(
                                        observation => observation.report === report.id);
          report.observationCount = observationsOfReport.lenght;
        }

        reportsData = reports;
      }

      this.props.showNotice('Palvelimeen ei saatu yhteyttä', 'Warning');
    }).then(() => {
      this.setState({
        loadingReports: false,
        reports: fromJS(reportsData)
      });
    });
  }

  showReport(id) {
    localStorage.selectedReport = id;
    const foundReport = this.state.reports.find(report => report.get('id') === id);
    
    if (foundReport.get('state').get('id') === 1) {
      this.setState({
        redirect: '/new'
      });
    }
    else if (foundReport.get('state').get('id') === 2) {
      this.setState({
        redirect: '/edit'
      });
    }
    else {
      this.setState({
        redirect: '/report/' + id
      });
    }
  }

  render() {
    if (this.state.redirect) return <Navigate to={this.state.redirect} push/>;

    return (
      <div>
        <div className="container">
          <h1>Raportit</h1>
          <div className='center'>
            <Link to="/reports/new">
              <button type="button">Uusi raportti</button>
            </Link>
          </div>
          <div id='report-table'>
            { this.state.loadingReports ?
              <div className='loader'/>
              :
              <List many={25}
                    emptyText={'Ei yhtään raporttia'}
                    header={['Nimi', 'Aloitus aika', 'Tila', 'Havain. kpl', '', '']}
                    fields={['name', 'startTime', 'state.name', 'observationCount', 'button|Näytä|0#id',
                            'button#Muokkaa#/report/edit/{id}']}
                    data={this.state.reports.reverse()}
                    functions={[this.showReport]}/>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
}), { showNotice, addReports, addReport, changeReport, removeReport })(Reports);
