import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import {
  addObservation, addObservations, removeObservation, changeObservation,
  clearObservation, changeObservationOrder, toggleHideObservation
} from './Actions';
import {
  addReports, changeReport, removeReport, addTemplates, addReport,
  addTemplate, changeTemplate, removeTemplate
} from '../report/Actions';
import { showNotice } from '../../../../src/notice/Actions';
import { showConfirm } from '../../../../src/confirm/Actions';
import { showMessage } from '../../../../src/message/Actions';
import Excel from '../../../../src/excel/Excel.js';
import ExtraMenu from '../../../../src/extraMenu/ExtraMenu.js';
import {
  fetch, integerValue, Socket, toETRSTM35FIN, toWGS84, timer, toRadians,
  toDegrees, calculateOffsetCoordinates, fetchSensor, calculateDistance, ROAD_URL,
  ROAD_URL2, stateValueParser, mode, getRoadData, getRoadPartMaxDistance
} from '../utils.js';
import { ReportSelect, ObservationTemplateSelect } from '../report/Components';
import {
  MapView, ChangeObservation, ViewObservation, ChangeProfile, LockToRoad,
  NameTheNewReport, Settings, TouchLock, InputCoordinates
} from './Components';
import jsPDF from 'jspdf';
import leafletImage from 'leaflet-image';
import { fromJS } from 'immutable';
import { OBSERVATION_COLORS } from './Components';
import { kalmanFilter, resetKalmanFilter } from './KalmanFilter';
import * as XLSX from 'xlsx'
import './Observation.css';


const paddedNumber = number => number <= 99 ? ('0' + number).slice(-2) : number;
const ACCURACY_FILTER_METRES = 30;
let map;

const BasicInfo = props => {
  return (
    <div id='basic-info'>
      {props.readOnly && props.reportName ?
        <h4>{props.reportName}</h4>
        :
        <ReportSelect reports={props.reports} value={props.selectedReport}
          onChange={props.onChange} />
      }
    </div>
  );
}

const NewObservation = props => {
  if (props.notShow) return null;

  if (props.reports.size === 0) {
    return (
      <button id='observation-info'
        disabled={true}>
        Uusi havainto
      </button>
    );
  }

  const report = props.reports.find(r => r.get('id') === props.selectedReport);

  if (report == null) return null;

  let templates = props.templates;

  if (props.profile != null) {
    templates = props.templates.filter(template => template.get('profile') === props.profile);
  }

  if (!props.showNewObservation) {
    if (templates.size === 1) {
      const template = templates.first();
      return (
        <button id='observation-info' onClick={props.selectTemplate.bind(this, template.get('id'))}>
          Uusi havainto ({template.get('name')})
        </button>
      );
    }
    return (
      <button id='observation-info' onClick={props.toggleNewObservation}>
        Uusi havainto
      </button>
    );
  }

  return (
    <div onClick={props.toggleNewObservation} className='modal-message'>
      <div onClick={e => e.stopPropagation()} id='new-observation-modal'>
        {!props.selectedTemplate ?
          <ObservationTemplate templates={templates} onChange={props.onChange}
            toggleNewObservation={props.toggleNewObservation} runningObservations={props.runningObservations}
            selectedTemplate={props.selectedTemplate} state={props.state} selectTemplate={props.selectTemplate}
            endObservation={props.endObservation} toggleTemplateOrdering={props.toggleTemplateOrdering}
            orderTemplates={props.orderTemplates} templateOrder={props.templateOrder}
            creatingObservation={props.creatingObservation} />
          :
          <ObservationTypeSelect selectObservationType={props.selectObservationType}
            selectedObservationType={props.selectedObservationType}
            makeObservation={props.makeObservation}
            clearSelectedTemplate={props.clearSelectedTemplate}
            selectedTemplate={props.selectedTemplate} templates={props.templates} />
        }
      </div>
    </div>
  );
};

const ObservationTemplate = props => {
  return (
    <div>
      <div className='close' onClick={props.toggleNewObservation} />
      <button id='template-order-button'
        className={props.orderTemplates ? 'toggled' : ''}
        onClick={props.toggleTemplateOrdering}>
        Järjestele
      </button>
      <h4>Valitse havaintopohja</h4>
      <ObservationTemplateSelect templates={props.templates} selectTemplate={props.selectTemplate}
        value={props.selectedTemplate} runningObservations={props.runningObservations}
        endObservation={props.endObservation} templateOrder={props.templateOrder}
        creatingObservation={props.creatingObservation} />
    </div>
  );
};

const ObservationTypeSelect = props => {
  const types = props.templates.find(template => template.get('id') === props.selectedTemplate).get('types');

  return (
    <div>
      <h4 className='center'>Havaintotyyppi</h4>
      {types.includes(0) ?
        <div className={'observation-type-select'}
          onClick={props.selectObservationType.bind(null, 0)}>
          Paikka
        </div>
        :
        null
      }
      {types.includes(1) ?
        <div className={'observation-type-select'}
          onClick={props.selectObservationType.bind(null, 1)}>
          Reitti
        </div>
        :
        null
      }
      {types.includes(2) ?
        <div className={'observation-type-select'}
          onClick={props.selectObservationType.bind(null, 2)}>
          Alue
        </div>
        :
        null
      }
      <button className='button-outline' onClick={props.clearSelectedTemplate}>
        Takaisin
      </button>
    </div>
  );
};

const Observations = props => {
  if (!props.showObservations) {
    return (
      <button id='observations' onClick={props.toggleObservations}
        disabled={props.observations.size === 0 && props.runningObservations.length === 0}>
        Havainnot
      </button>
    );
  }

  const hiddenAmount = props.observations.filter(observation => observation.get('hide')).size;

  if (props.readOnly) {
    return (
      <div onClick={props.toggleObservations} className='modal-message'>
        <div id='side-observations-container' onClick={e => e.stopPropagation()}>
          <button onClick={props.toggleObservations}>
            Sulje
          </button>
          <div id='side-observations'>
            {
              props.observations.map(observation => {
                const templateName = props.templates.find(template => template.get('id') === observation.get('template')).get('name');

                let observationType;

                if (observation.get('path').size !== 0) {
                  observationType = 'Reitti (' + Math.round(observation.get('distance')) + ' m)';
                }
                else if (observation.get('area')) {
                  observationType = 'Alue';
                }
                else observationType = 'Paikka';

                return (
                  <div key={observation.get('id')}
                    className='side-observation'
                    onClick={props.selectObservation.bind(null, observation.get('id'))}>
                    {observation.get('order') + ' ' + templateName + ', ' + observationType}
                    <button onClick={props.toggleHideObservation.bind(null, observation.get('id'))}>
                      {observation.get('hide') ? 'Tuo näkyviin' : 'Piilota'}
                    </button>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    );
  }

  if (props.editOnly) {
    return (
      <div onClick={props.toggleObservations} className='modal'>
        <div onClick={e => e.stopPropagation()} id='observation-modal'>
          <div className='close' onClick={props.toggleObservations} />
          <h4>Raportin havainnot</h4>
          {props.observations.size !== 0 ?
            <table className='observations' cellSpacing={0}>
              <tbody>
                {
                  props.observations.map(observation => {
                    const templateName = props.templates.find(template => template.get('id') === observation.get('template')).get('name');

                    let observationType;

                    if (observation.get('path').size !== 0) {
                      observationType = 'Reitti (' + Math.round(observation.get('distance')) + ' m)';
                    }
                    else if (observation.get('area')) {
                      observationType = 'Alue';
                    }
                    else observationType = 'Paikka';

                    return (
                      <tr key={observation.get('id')}
                        className='table-observation'
                        onClick={props.selectObservation.bind(null, observation.get('id'))}>
                        <td>
                          {observation.get('order') + ' ' + templateName + ', ' + observationType}
                        </td>
                        <td>
                          <button onClick={props.toggleHideObservation.bind(null, observation.get('id'))}>
                            {observation.get('hide') ? 'Tuo näkyviin' : 'Piilota'}
                          </button>
                        </td>
                        <td>
                          <button onClick={props.goChangeObservation.bind(null, observation)}>
                            Muokkaa
                          </button>
                        </td>
                        <td>
                          <button onClick={props.confirmRemoveObservation.bind(null, observation)}>
                            Poista
                          </button>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
            :
            <h6>Ei yhtään</h6>
          }
        </div>
      </div>
    );
  }

  return (
    <div onClick={props.toggleObservations} className='modal'>
      <div onClick={e => e.stopPropagation()} id='observation-modal'>
        <div className='close' onClick={props.toggleObservations} />
        <h4>Raportin havainnot</h4>
        {props.filterOn ? <strong>Filteröinti päällä<br /></strong> : null}
        <strong>Havaintoja:</strong> {props.observations.size ? props.observations.size : '0'}
        {!hiddenAmount ? null :
          <div>
            (Piilotettu: {hiddenAmount})
          </div>
        }
        <h5>Käynnissä</h5>
        {props.runningObservations.length !== 0 ?
          <table className='observations' cellSpacing={0}>
            <thead>
              <tr>
                <th>
                  Havainto
                </th>
                <th>
                  Pituus
                </th>
                <th>
                </th>
              </tr>
            </thead>
            <tbody>
              {
                props.runningObservations.map((observation, index) => {
                  const templateName = props.templates.find(template => template.get('id') === observation.template).get('name');
                  return (
                    <tr key={observation.id}
                      className='table-observation'
                      onClick={props.selectObservation.bind(null, observation.id)}>
                      <td>
                        {observation.order + ' ' + templateName}
                      </td>
                      <td>
                        {Math.round((observation.distance / 1000) * 10) / 10 + ' km'}
                      </td>
                      <td>
                        <button onClick={props.endObservation.bind(null, index)}>
                          Pysäytä
                        </button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          :
          <h6>Ei yhtään</h6>
        }
        <h5>Tehdyt</h5>
        {props.observations.size !== 0 ?
          <table className='observations' cellSpacing={0}>
            <tbody>
              {
                props.observations.map(observation => {
                  const template = props.templates.find(template => template.get('id') === observation.get('template'));

                  let observationType;

                  if (observation.get('path').size !== 0) {
                    observationType = 'Reitti (' + Math.round(observation.get('distance') * 10) / 10 + ' m)';
                  }
                  else if (observation.get('area')) {
                    observationType = 'Alue';
                  }
                  else observationType = 'Paikka';

                  return (
                    <tr key={observation.get('id')}
                      className='table-observation'
                      onClick={props.selectObservation.bind(null, observation.get('id'))}>
                      <td>
                        {observation.get('order') + ' ' + template.get('name') + ' ' + observationType}
                      </td>
                      <td>
                        <button onClick={props.goChangeObservation.bind(null, observation)}
                          disabled={observation.get('area') == null && template.get('fields').size === 0}>
                          Muokkaa
                        </button>
                      </td>
                      <td>
                        <button onClick={props.confirmRemoveObservation.bind(null, observation)}>
                          Poista
                        </button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          :
          <h6>Ei yhtään</h6>
        }
      </div>
    </div>
  );
};

const CurrentLocation = props => {
  if (props.notShow) return null;
  var accuracyColor;
  var accuracy = Math.round(props.accuracy);

  if (accuracy > 20) accuracyColor = 'red';
  else if (accuracy > 10) accuracyColor = 'yellow';
  else if (accuracyColor != null) accuracyColor = 'green';

  if (accuracy >= 10000) accuracy = '-';

  const profile = props.profiles.find(profile => profile.id === props.profile);
  return (
    <div id='current-location'>
      <div className='current-location-part large'>
        {(props.roadNumber || '-') + ' / ' + (props.roadPart || '-') + ' / ' + (props.roadDistance || '-')}
      </div>
      <div className='current-location-part'>
        {(props.address ? (props.address + ', ' + props.city) : '-')}
      </div>
      <div className='current-location-part small'>
        <strong>Päivitetty:</strong> {(props.time || '-')}
      </div>
      <div className='current-location-part small'>
        <strong>Tarkkuus:</strong> <span id={'location-accuracy-' + accuracyColor}>{accuracy || '-'} </span>m
      </div>
      {props.setLockedRoad != null ?
        <div className='current-location-part green small'>
          <strong>Lukittu tiehen:</strong> {(props.setLockedRoad || '-')}
        </div>
        : null
      }
      {props.targetMark && props.targetMark.roadDistanceToMark ?
        <div className='current-location-part small'>
          <strong>Tie etäisyys:</strong> {props.targetMark.roadDistanceToMark} m
        </div>
        : null
      }
      {props.targetMark && props.targetMark.distanceToMark ?
        <div className='current-location-part small'>
          <strong>Etäisyys:</strong> {props.targetMark.distanceToMark} m
        </div>
        : null
      }
      {profile != null ?
        <div className='current-location-part small'>
          <strong>Profiili:</strong> {profile.name}
        </div>
        : null
      }
    </div>
  );
}

const ObservationsInfo = props => {
  if (props.selectedReport == null || props.notShow) return null;

  return (
    <div id='all-observations-info'>
      {props.runningObservations.length === 0 ? null : (
        <div>
          <strong>Käynnissä olevia havaintoja:</strong>
          <br />
          {
            props.runningObservations.map((observation, index) => {
              const template = props.templates.find(template => template.get('id') === observation.template).get('name');
              let distance;

              if (observation.distance < 100) {
                distance = Math.round(observation.distance) + ' m '
              }
              else {
                distance = Math.round(observation.distance / 1000 * 10) / 10 + ' km '
              }

              return (
                <span key={observation.id}>
                  {template + ' '}
                  {distance}
                  <button onClick={props.endObservation.bind(null, index)}>
                    Pysäytä
                  </button>
                  <br />
                </span>
              );
            })
          }
        </div>
      )
      }
    </div>
  );
}

const SelectAreaObservationType = props => {
  if (!props.show) return null;

  return (
    <div onClick={props.toggle} className='modal'>
      <div onClick={e => e.stopPropagation()} id='observation-modal'>
        <div className='close' onClick={props.toggle} />
        <h4 className='center'>Valitse alueen muoto</h4>
        <div className={'observation-type-select'}
          onClick={props.select.bind(null, 1)}>
          Ympyrä
        </div>
        <div className={'observation-type-select'}
          onClick={props.select.bind(null, 2)}>
          Suorakulma
        </div>
      </div>
    </div>
  );
};

const PdfLoader = props => {
  if (!props.show) return null;

  return (
    <div className='modal'>
      <div id='pdf-loader'>
        <h4>Luodaan PDF-raporttia</h4>
        <div className='loader' />
      </div>
    </div>
  );
};

const FilterObservations = props => {
  if (!props.show) return null;

  let templates = props.templates;

  for (let i = 0; i < templates.size; i++) {
    let template = templates.get(i);
    const color = template.get('color').replace('#', 'color-');
    template = template.set('color', color);
    templates = templates.set(i, template);
  }

  const templateOrder = props.templateOrder;
  templates = templates.sort((a, b) => {
    const valueA = templateOrder[a.get('id')] || 0;
    const valueB = templateOrder[b.get('id')] || 0;
    return valueB - valueA;
  });

  return (
    <div onClick={props.toggle} className='modal'>
      <div onClick={e => e.stopPropagation()} id='observation-modal'>
        <div className='close' onClick={props.toggle} />
        <h4 className='center'>Filteröi havaintoja</h4>
        <h5>Havaintopohjan mukaan</h5>
        {
          templates.map(template => {
            const selectedIndex = props.selectedFilterTemplates.findIndex(
              selectedTemplate => selectedTemplate === template.get('id'));
            const selected = selectedIndex !== -1;

            return (
              <div key={template.get('id')} className={'observation-template-select' +
                (selected ? ' selected' : '') + ' ' + template.get('color')}
                onClick={selected ? props.removeFilterTemplate.bind(null, selectedIndex)
                  : props.addFilterTemplate.bind(null, template.get('id'))}>
                {template.get('name')}
              </div>
            );
          })
        }
        <label htmlFor='startTime'>Aika haarukka:</label>
        <input type='datetime-local' id='startTime'
          onChange={props.onChange.bind(this, 'filterStartTime', 'string', '')}
          value={props.filterStartTime} />
        <br />
        <input type='datetime-local' id='endTime'
          onChange={props.onChange.bind(this, 'filterEndTime', 'string', '')}
          value={props.filterEndTime} />
        <br />
        <div className='row'>
          <div className='column'>
            <button onClick={props.filter}>
              Ok
            </button>
          </div>
          <div className='column'>
            <button className='button-outline' onClick={props.clearfilters}>
              Tyhjää
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


class Observation extends React.Component {

  constructor(props) {
    super(props);

    this.watchID = null;
    this.previousLatitude = null;
    this.previousLongitude = null;
    this.editOnly = false;
    this.readOnly = false;
    this.externalGPSTimer = null;
    this.socket = null;
    this.xDown = null;
    this.yDown = null;

    this.state = {
      selectedReport: integerValue(localStorage.selectedReport, null),
      selectedTemplate: null,
      showNewObservation: false,
      showObservations: false,
      setAreaObservation: false,
      observations: [],
      runningObservations: [],
      lockedRoad: null,
      setLockedRoad: null,
      creatingAreaObservation: null,
      loadingPdf: false,
      viewObservation: null,
      selectedFilterTemplates: [],
      filterStartTime: '',
      filterEndTime: '',
      setFilterTemplates: [],
      setFilterStartTime: null,
      setFilterEndTime: null,
      showChangeProfile: false,
      profile: parseInt(localStorage.profile, 10) || null,
      profiles: [],
      lockedRoadPath: [],
      areaBound: null,
      templateOrder: localStorage.templateOrder ? JSON.parse(localStorage.templateOrder) : {},
      excelHeaders: [],
      excelDataHeaders: [],
      reportObservations: [],
      extraTag: '',
      useRoadData: true,
      neviLocatorGPS: false,
      inputCoordinates: false,
      selectedInputCoordinatesFile: null,
      selectedInputCoordinatesTemplate: null,
      lastRoadNumbers: []
    };

    this.changeState = this.changeState.bind(this);
    this.setLocation = this.setLocation.bind(this);
    this.locationError = this.locationError.bind(this);
    this.getReports = this.getReports.bind(this);
    this.toggleNewObservation = this.toggleNewObservation.bind(this);
    this.makeObservation = this.makeObservation.bind(this);
    this.endObservation = this.endObservation.bind(this);
    this.changeObservation = this.changeObservation.bind(this);
    this.changeObservationLocation = this.changeObservationLocation.bind(this);
    this.goChangeObservation = this.goChangeObservation.bind(this);
    this.clearChangeObservation = this.clearChangeObservation.bind(this);
    this.confirmRemoveObservation = this.confirmRemoveObservation.bind(this);
    this.removeObservation = this.removeObservation.bind(this);
    this.selectTemplate = this.selectTemplate.bind(this);
    this.clearSelectedTemplate = this.clearSelectedTemplate.bind(this);
    this.selectObservationType = this.selectObservationType.bind(this);
    this.toggleObservations = this.toggleObservations.bind(this);
    this.selectObservation = this.selectObservation.bind(this);
    this.toggleShowChangeProfile = this.toggleShowChangeProfile.bind(this);
    this.setLockedRoad = this.setLockedRoad.bind(this);
    this.toggleLockToRoad = this.toggleLockToRoad.bind(this);
    this.toggleShowMakeMark = this.toggleShowMakeMark.bind(this);
    this.toggleInputCoordinates = this.toggleInputCoordinates.bind(this);
    this.toggleShowFindRoadInfo = this.toggleShowFindRoadInfo.bind(this);
    this.toggleSetAreaObservation = this.toggleSetAreaObservation.bind(this);
    this.createAreaObservation = this.createAreaObservation.bind(this);
    this.changeReportState = this.changeReportState.bind(this);
    this.confirmChangeReportToPreliminary = this.confirmChangeReportToPreliminary.bind(this);
    this.confirmChangeReportToPublished = this.confirmChangeReportToPublished.bind(this);
    this.makeExcel = this.makeExcel.bind(this);
    this.makePathExcel = this.makePathExcel.bind(this);
    this.toggleMakeExcel = this.toggleMakeExcel.bind(this);
    this.makePdf = this.makePdf.bind(this);
    this.viewObservation = this.viewObservation.bind(this);
    this.resetViewObservation = this.resetViewObservation.bind(this);
    this.toggleFilterObservations = this.toggleFilterObservations.bind(this);
    this.toggleCreateNewReport = this.toggleCreateNewReport.bind(this);
    this.createNewReport = this.createNewReport.bind(this);
    this.addFilterTemplate = this.addFilterTemplate.bind(this);
    this.removeFilterTemplate = this.removeFilterTemplate.bind(this);
    this.filterObservations = this.filterObservations.bind(this);
    this.clearFilterObservations = this.clearFilterObservations.bind(this);
    this.toggleSettings = this.toggleSettings.bind(this);
    this.toggleExternalGPS = this.toggleExternalGPS.bind(this);
    this.getExternalGPS = this.getExternalGPS.bind(this);
    this.toggleTemplateOrdering = this.toggleTemplateOrdering.bind(this);
    this.toggleHideObservation = this.toggleHideObservation.bind(this);
    this.update = this.update.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.fullscreenEvent = this.fullscreenEvent.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.move = this.move.bind(this);
    this.toggleNeviLocatorGPS = this.toggleNeviLocatorGPS.bind(this);
    this.testNeviLocator = this.testNeviLocator.bind(this);
    this.getNeviLocatorCoordinates = this.getNeviLocatorCoordinates.bind(this);
    this.createObservationFromCoordinateFile = this.createObservationFromCoordinateFile.bind(this);
    this.getRoadDataAgain = this.getRoadDataAgain.bind(this);
  }

  async UNSAFE_componentWillMount () {
    if (this.props.user == null) {
      return;
    }

    await this.getTemplates();

    this.setupSocket();

    if (this.props.location.pathname.match('/report/[1-9]d*')) {
      this.readOnly = true;
      const reportId = parseInt(
        this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('t/') + 2), 10);
      await this.getReports(null, reportId);
      this.extraMenuItems = ['Filtteröi havaintoja', 'Lataa pdf', 'Lataa excel', 'Lataa sijainti excel', 'Etsi tierekisteri'];
      this.extraMenuFunctions = [this.toggleFilterObservations, this.makePdf, this.makeExcel, this.makePathExcel,
      this.toggleShowFindRoadInfo];
      this.forceUpdate();
      return;
    }

    if (localStorage.externalGPS === 'true') {
      this.toggleExternalGPS();
    }

    if (!navigator.geolocation) {
      this.props.showMessage('Virhe',
        'Paikan haku ei ole tuettu tällä selaimella eli et voi saada omaa sijaintia', 'Error');
      return;
    }

    navigator.geolocation.getCurrentPosition(this.setLocation, this.locationError, { enableHighAccuracy: true });
    this.watchID = navigator.geolocation.watchPosition(this.setLocation, this.locationError,
      { enableHighAccuracy: true, distanceFilter: 0 });

    if (this.props.location.pathname === '/edit') {
      this.editOnly = true;
      await this.getReports(2);
      this.extraMenuItems = ['Filtteröi havaintoja', 'Lataa pdf', 'Lataa excel', 'Lataa sijainti excel',
        'Etsi tierekisteri', 'Lataa tierekisterit uudestaan', 'Tallenna valmiiksi',];
      this.extraMenuFunctions = [this.toggleFilterObservations, this.makePdf, this.makeExcel, this.makePathExcel,
      this.toggleShowFindRoadInfo, this.getRoadDataAgain,
      this.confirmChangeReportToPublished];
      this.forceUpdate();
      return;
    }

    this.extraMenuItems = ['Vaihda profiilia', 'Lukitse tiettyyn tiehen',
      'Merkitse karttaan', 'Havainto koordinaateista',
      'Etsi tierekisteri', 'Filtteröi havaintoja',
      'Muuta alustavaksi', 'Luo uusi raportti',
      'Lataa tierekisterit uudestaan', 'Asetukset'];
    this.extraMenuFunctions = [this.toggleShowChangeProfile, this.toggleLockToRoad,
    this.toggleShowMakeMark, this.toggleInputCoordinates,
    this.toggleShowFindRoadInfo, this.toggleFilterObservations,
    this.confirmChangeReportToPreliminary, this.toggleCreateNewReport,
    this.getRoadDataAgain, this.toggleSettings];

    this.getProfiles();
    this.toggleShowChangeProfile();
    await this.getReports();
    this.refresher = setInterval(this.getReports.bind(this, 1, null, true), 60000);

    if (await this.testNeviLocator()) {
      this.props.showConfirm('Nevilocator löydetty. Otetaanko käyttön?', this.toggleNeviLocatorGPS);
    }

    document.addEventListener('fullscreenchange', this.fullscreenEvent, false);
    document.addEventListener('mozfullscreenchange', this.fullscreenEvent, false);
    document.addEventListener('MSFullscreenChange', this.fullscreenEvent, false);
    document.addEventListener('webkitfullscreenchange', this.fullscreenEvent, false);
    document.addEventListener('mousedown', this.handleMouseDown, false);
    document.addEventListener('mousemove', this.handleMouseMove, false);
    document.addEventListener('touchstart', this.handleTouchStart, false);
    document.addEventListener('touchmove', this.handleTouchMove, false);
  }

  componentWillUnmount () {
    if (this.watchID != null) {
      navigator.geolocation.clearWatch(this.watchID);
    }
    clearInterval(this.externalGPSTimer);
    clearInterval(this.refresher);
    if (this.socket != null) this.socket.close();
  }

  componentDidUpdate (lastProps, lastState) {
    if ((this.state.setFilterTemplates.length !== 0 || this.state.setFilterStartTime != null)
      && (lastProps.observations !== this.props.observations ||
        lastState.setFilterTemplates !== this.state.setFilterTemplates ||
        lastState.setFilterStartTime !== this.state.setFilterStartTime ||
        lastState.setFilterEndTime !== this.state.setFilterEndTime)) {
      const filteredObservations = this.props.observations.filter(observation => {
        let startTime = this.state.setFilterStartTime;
        let endTime = this.state.setFilterEndTime;
        let pass = true;

        if (startTime != null && endTime != null) {
          const userTimezoneOffset = startTime.getTimezoneOffset() * 60000;
          startTime = new Date(startTime.getTime() + userTimezoneOffset);
          endTime = new Date(endTime.getTime() + userTimezoneOffset);
          const observationTime = new Date(observation.get('time'));
          if (startTime > observationTime || observationTime > endTime) {
            pass = false;
          }
        }

        if (this.state.setFilterTemplates.length !== 0 &&
          !this.state.setFilterTemplates.includes(observation.get('template'))) {
          pass = false;
        }

        return pass;
      });

      this.setState({
        observations: filteredObservations
      });
    }
    else if (lastProps.observations !== this.props.observations ||
      (lastState.setFilterTemplates !== this.state.setFilterTemplates &&
        this.state.setFilterTemplates.length === 0)) {
      this.setState({
        observations: this.props.observations
      });
    }

    if (lastProps.reports !== this.props.reports && this.state.selectedReport != null) {
      if (this.props.reports.find(report => report.get('id') === this.state.selectedReport) == null) {
        this.setState({ selectedReport: null });
      }
    }
    if (lastState.selectedReport !== this.state.selectedReport) {
      this.getObservations(this.state.selectedReport);

      if (this.state.selectedReport != null && this.props.reports.size !== 0) {
        const report = this.props.reports.find(report => report.get('id') === this.state.selectedReport);
        if (report == null) {
          this.setState({ selectedReport: null });
          return;
        }

        this.setState({ reportName: report.get('name') });
      }
    }
  }

  setupSocket () {
    if (this.socket == null && typeof (WebSocket) !== 'undefined') {
      this.socket = Socket();
      this.socket.onmessage = async function (e) {
        if (this.state.selectedReport == null ||
          this.state.loadingObservations) {
          return;
        }

        const data = JSON.parse(e.data);

        if (data.modelName === 'template') {
          if (data['operation'] === 'create') {
            const exist = this.props.templates.find(template =>
              template.get('id') === data.model.id);
            if (exist) {
              return;
            }

            let template = data.model;

            template.profile = template.profileData
            template.fields.sort((a, b) => {
              return a.id - b.id;
            });
            const index = this.props.templates.size;
            const colorIndex = index - (OBSERVATION_COLORS.length * Math.floor(index / OBSERVATION_COLORS.length));
            template.color = OBSERVATION_COLORS[colorIndex];

            this.props.addTemplate(template);
          }
          else if (data['operation'] === 'update') {
            let template = data.model;
            template.profile = template.profileData
            template.fields.sort((a, b) => {
              return a.id - b.id;
            });
            const color = this.props.templates.find(t => t.get('id') === template.id).get('color');
            template.color = color;
            this.props.changeTemplate(template);
          }
          else if (data['operation'] === 'delete') {
            this.props.removeTemplate(data.model.id);
          }
        }
        else if (data.modelName === 'report') {
          if (data['operation'] === 'create') {
            const exist = this.props.reports.find(report =>
              report.get('id') === data.model.id);
            if (exist) {
              return;
            }

            const report = data.model;
            this.props.addReport(report);
          }
          else if (data['operation'] === 'update') {
            const report = data.model;
            this.props.changeReport(report);
          }
          else if (data['operation'] === 'delete') {
            this.props.removeReport(data.model.id);
          }
        }
        else if (data.modelName === 'observation') {
          if (data['operation'] === 'create') {
            if (this.state.selectedReport !== data.model.report.id) {
              return;
            }
            else {
              const exist = this.props.observations.find(observation =>
                observation.get('id') === data.model.id);
              if (exist) {
                return;
              }
            }

            let observation = data.model;

            if (observation.path) {
              observation.path.sort((a, b) => {
                return a.order - b.order
              });
            }

            if (observation.area) {
              const position = fromJS(JSON.parse(observation.area.position));
              observation.area.position = position;
            }

            if (observation.roadNumber) {
              observation.roadData = true;
            }

            this.props.addObservation(observation);
          }
          else if (data['operation'] === 'update') {
            let observation = data.model;

            if (observation.path) {
              observation.path.sort((a, b) => {
                return a.order - b.order
              });
            }

            if (observation.area) {
              const position = fromJS(JSON.parse(observation.area.position));
              observation.area.position = position;
            }

            const template = this.props.templates.find(t =>
              t.get('id') === observation.template);

            if (template != null) {
              for (let f = 0; f < template.get('fields').size; f++) {
                const field = template.get('fields').get(f);

                if ((field.get('type') === 'image' || field.get('type') === 'video' ||
                  field.get('type') === 'audio' || field.get('type') === 'file')) {
                  let fileNames = observation.fields[field.get('name')];

                  if (fileNames !== '-') {
                    let files = [];

                    for (let i = 0; i < fileNames.length; i++) {
                      const file = fileNames[0];
                      const type = field.get('type') + '/' + file.name.split('.')[1];
                      try {
                        const response = await fetch(null, 'GET', file.file, type);
                        const image = await this.loadImage(response);
                        files.push({ name: file.name, file: image || '-' });
                      } catch (error) {

                      }
                    }

                    observation.fields[field.get('name')] = files;
                  }
                }
              }
            }
            else {
              observation.path = [];
              observation.fields = {};
              observation.error = true;
            }

            this.props.changeObservation(observation);
          }
          else if (data['operation'] === 'delete') {
            this.props.removeObservation(data.model.id);
          }
        }
      }.bind(this)
    }
  }

  async sendSavedReports () {
    let reports = JSON.parse(localStorage['savedReports']);
    let newReports = reports.slice();

    for (let index in reports) {
      const report = reports[index];

      try {
        const response = await fetch(`mutation NewReport {
                                        reportCreate(report: {
                                            name:` + JSON.stringify(report.name) +
          ` startTime:` + JSON.stringify(report.startTime) +
          ` state: {id:` + JSON.stringify(report.state.id) + `}
                                        }) {
                                            id
                                        }
                                      }`);

        const newReport = response.data.reportCreate;

        if (newReport != null) {
          await this.sendSavedObservations(report.id, newReport.id);
        }

        newReports.splice(newReports.findIndex(r => r['id'] === report['id']), 1);

      } catch (error) {

      }
    }

    localStorage['savedReports'] = JSON.stringify(newReports);
    this.getReports();
  }

  async sendSavedObservations (oldId = null, newId = null) {
    if (localStorage['savedObservations'] == null) {
      return;
    }

    let observations = JSON.parse(localStorage['savedObservations']);
    let newObservations = observations.slice();

    for (let index in observations) {
      const observation = observations[index];

      if (oldId != null && observation.report.id !== oldId) {
        continue;
      }

      const id = newId || observation.report.id;

      try {
        const response = await fetch(`mutation NewObservation {
                                        observationCreate(observation: {
                                              time:` + JSON.stringify(observation.time) +
          ` report: {id: ` + JSON.stringify(id) + `}
                                              latitude:` + observation.latitude +
          ` longitude:` + observation.longitude +
          ` roadData:` + JSON.stringify(observation.roadData || false) +
          (observation.roadNumber ? (` roadNumber:` + JSON.stringify(observation.roadNumber)) : '') +
          (observation.roadPart ? (` roadPart:` + JSON.stringify(observation.roadPart)) : '') +
          (observation.roadDistance ? (` roadDistance:` + JSON.stringify(observation.roadDistance)) : '') +
          ` order:` + JSON.stringify(observation.order) +
          ` template:` + JSON.stringify(observation.template) +
          `}) {
                                          id, time, videos {file, name, field {name}}, photos {file, name, field {name}},
                                          report { id }, roadNumber, roadPart, roadDistance,
                                          path { roadNumber, roadPart, roadDistance, latitude, longitude },
                                          latitude, longitude, order, roadData, template
                                        }
                                    }`);

        const newObservation = response.data.observationCreate;

        if (newObservation != null) {
          newObservations.splice(newObservations.findIndex(obs => obs['id'] === observation['id']), 1);
          newObservation.fields = observation.fields;

          this.changeObservation(newObservation);
        }
      } catch (error) {

      }
    }

    localStorage['savedObservations'] = JSON.stringify(newObservations);
  }

  async getProfiles () {
    let profiles = [];

    try {
      profiles = (await fetch(
        'query Query { profilesByTokenOrg { id, name } }'
      )).data.profilesByTokenOrg;

      if (typeof (Storage) !== 'undefined') {
        localStorage['profiles'] = JSON.stringify(profiles);
      }
    } catch (error) {
      if (typeof (Storage) !== 'undefined' &&
        localStorage['profiles'] != null &&
        JSON.parse(localStorage['profiles']).length !== 0) {
        profiles = JSON.parse(localStorage['profiles']);
      }
      this.setState({
        locally: true
      });
    };

    this.setState({
      profiles: profiles
    });
  }

  async getTemplates () {
    let templates = [];

    try {
      templates = (await fetch(
        'query Template { templatesByTokenOrg{ id, name, types, roadData, profile, fields{id, name, type, values, order}, order, hidden } }'
      )).data.templatesByTokenOrg;

      let ordersExists = true;

      for (let index in templates) {
        if (templates[index].order == null) {
          ordersExists = false;
          break;
        }
      }

      templates.sort((a, b) => {
        if (ordersExists) {
          return a.order - b.order;
        }

        return a.id - b.id;
      });

      templates.forEach((template, index) => {
        template.fields.sort((a, b) => {
          if (a.order && b.order) {
            return a.order - b.order;
          }

          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }

          return 0;
        });
        const colorIndex = index - (OBSERVATION_COLORS.length * Math.floor(index / OBSERVATION_COLORS.length));
        template.color = OBSERVATION_COLORS[colorIndex];
      });

      if (typeof (Storage) !== 'undefined') {
        localStorage['templates'] = JSON.stringify(templates);
      }
    } catch (error) {
      if (typeof (Storage) !== 'undefined' &&
        localStorage['templates'] != null &&
        JSON.parse(localStorage['templates']).length !== 0) {
        templates = JSON.parse(localStorage['templates']);
      }
      this.setState({
        locally: true
      });
    };

    this.props.addTemplates(templates);
  }

  async getReports (state = 1, id, refresh = false) {
    if (!refresh) {
      this.setState({ loadingReports: true });
    }

    let reports = [];

    try {
      reports = (await fetch(`query { reportsByTokenOrg { id, name, startTime,
                                                          reporter{id, first_name, last_name}, state{id}, receivers
                                                        } 
                                    }`
      )).data.reportsByTokenOrg;
      if (typeof (Storage) !== 'undefined' &&
        localStorage['savedReports'] != null &&
        JSON.parse(localStorage['savedReports']).length !== 0) {
        this.sendSavedReports();
        return;
      }
    } catch (error) {
      this.setState({ locally: true });
    }

    if (localStorage['savedReports'] != null) {
      const savedReports = JSON.parse(localStorage['savedReports']);
      reports = reports.concat(savedReports);
    }

    reports = reports.filter(r => r.state.id === state || id);

    if (reports.length === 0) {
      this.setState({ selectedReport: null });
    }
    else {
      const report = reports.find(report => report.id === this.state.selectedReport);

      if (report == null) {
        this.setState({ selectedReport: null });
        localStorage.removeItem('selectedReport');
      }
      else {
        this.setState({ reportName: report.name });
      }
    }

    this.props.addReports(reports);
    this.getObservations(this.state.selectedReport, refresh);

    if (!refresh) {
      this.setState({ loadingReports: false });
    }
  }

  loadImage (blod) {
    return new Promise(function (resolved, rejected) {
      const reader = new FileReader();
      reader.readAsDataURL(blod);
      reader.onloadend = function () {
        const base64data = reader.result;
        resolved(base64data);
      }
    });
  }

  async getObservations (report, refresh = false) {
    await this.sendSavedObservations();

    if (!refresh) {
      this.props.clearObservation();
      this.setState({ runningObservations: [] });
    }

    if (report == null) {
      this.setState({ selectedReport: null });
      return;
    }

    if (!refresh) {
      this.setState({ loadingObservations: true });
    }

    let reportObservations = [];

    try {
      reportObservations = (await fetch('query Report{ report(id: ' + report + `) { observations { id, time, videos {file, name, field {name}},
                                                  photos {file, name, field {name}},
                                                  report { id }, roadNumber, roadPart, roadDistance,
                                                  path { roadNumber, roadPart, roadDistance, latitude, longitude, order },
                                                  area { position, width, height, type, radius },
                                                  latitude, longitude, order, fields, roadData, template, distance
                                                  } 
                                    } 
                                  }`
      )).data.report.observations;
    } catch (error) {

    }

    let observations = fromJS(reportObservations);

    if (localStorage['savedObservations'] != null) {
      let savedObservations = JSON.parse(localStorage['savedObservations']);
      observations = observations.concat(fromJS(savedObservations));
    }

    observations = observations.sort((a, b) => {
      return a.get('order') - b.get('order')
    });

    let index = 0;

    for (let observation of observations) {
      if (!observation.get('notSaved')) {
        let fields = fromJS(JSON.parse(observation.get('fields')));

        if (fields == null) {
          fields = fromJS({});
        }

        observation = observation.set('fields', fields);

        if (observation.get('area')) {
          const position = fromJS(JSON.parse(observation.get('area').get('position')));
          let area = observation.get('area');
          area = area.set('position', position);
          observation = observation.set('area', area);
        }
      }

      if (observation.get('path')) {
        let path = observation.get('path');

        path = path.sort((a, b) => {
          return a.get('order') - b.get('order')
        });

        observation = observation.set('path', path);
      }

      const template = this.props.templates.find(t => t.get('id') === observation.get('template'));

      if (template != null) {
        for (let f = 0; f < template.get('fields').size; f++) {
          const field = template.get('fields').get(f);

          let fields = observation.get('fields');

          if ((field.get('type') === 'image' || field.get('type') === 'video' ||
            field.get('type') === 'audio' || field.get('type') === 'file')) {
            let fileNames = fields.get(field.get('name'));

            if (fileNames == null) {
              break;
            }

            if (fileNames !== '-') {
              let files = [];

              for (let i = 0; i < fileNames.size; i++) {
                const file = fileNames.get(i);

                if (!observation.get('notSaved')) {

                  const type = field.get('type') + '/' + file.get('name').split('.')[1];

                  try {
                    const response = await fetch(null, 'GET', file.get('file'), type);
                    const image = await this.loadImage(response);
                    files.push({ name: file.get('name'), file: image || '-' });
                  } catch (error) {

                  }
                }
                else {
                  files.push({ name: file.get('name'), file: file.get('file') });
                }
              }

              fields = fields.set(field.get('name'), files);
              observation = observation.set('fields', fields);
            }
          }
        }
      }
      else {
        observation = observation.set('path', fromJS([]));
        observation = observation.set('fields', fromJS({}));
        observation = observation.set('error', true);
      }

      observations = observations.set(index, observation);
      index++;
    }

    this.props.addObservations(observations);

    if (!refresh && localStorage['savedRunningObservations'] != null) {
      const runningObservations = JSON.parse(localStorage['savedRunningObservations']).filter(
        observation => observation.report.id === this.state.selectedReport);

      this.setState({ runningObservations: runningObservations }, () => {
        this.updateRunningObservation();
      });
    }

    if ((this.editOnly || this.readOnly) && observations.length !== 0) {
      const R = 6371e3; // metres
      let allX = 0;
      let allY = 0;
      let allZ = 0;

      observations.forEach(observation => {
        let latitude;
        let longitude;

        if (observation.get('path') != null && observation.get('path').size !== 0) {
          latitude = observation.get('path').get(Math.floor(observation.get('path').size / 2)).get('latitude');
          longitude = observation.get('path').get(Math.floor(observation.get('path').size / 2)).get('longitude');
        }
        else if (observation.get('area') != null) {
          if (observation.get('area').get('type') === 1) {
            latitude = observation.get('area').get('position').get('lat');
            longitude = observation.get('area').get('position').get('lng');
          }
          else {
            latitude = observation.get('area').get('position').get(0).get(0);
            longitude = observation.get('area').get('position').get(0).get(1);
          }
        }
        else {
          latitude = observation.get('latitude');
          longitude = observation.get('longitude');
        }

        latitude = toRadians(latitude);
        longitude = toRadians(longitude);

        allX += R * Math.cos(latitude) * Math.cos(longitude);
        allY += R * Math.sin(latitude) * Math.cos(longitude);
        allZ += R * Math.sin(longitude);
      });

      const x = allX / this.props.observations.size;
      const y = allY / this.props.observations.size;
      const z = allZ / this.props.observations.size;

      const centerLatitude = toDegrees(Math.atan2(y, x));
      const centerLongitude = toDegrees(Math.atan2(z, Math.sqrt(x * x + y * y)));

      let highestDistance = 0;

      observations.forEach(observation => {
        const distance = calculateDistance(centerLatitude, centerLongitude,
          observation.get('latitude'), observation.get('longitude'));

        if (highestDistance < distance) {
          highestDistance = distance;
        }
      });


      const leftCoordinates = calculateOffsetCoordinates(centerLatitude, centerLongitude,
        -highestDistance, -highestDistance);
      const rightCoordinates = calculateOffsetCoordinates(centerLatitude, centerLongitude,
        highestDistance, highestDistance);

      this.setState({
        areaBound: [[leftCoordinates.latitude, leftCoordinates.longitude],
        [rightCoordinates.latitude, rightCoordinates.longitude]]
      });

      this.forceUpdate();
    }

    if (!refresh) {
      this.setState({ loadingObservations: false });
    }
  }

  changeState (propertyName, type, defaultValue, event) {
    if (type === 'file') {
      const files = event.target.files;

      this.setState({
        [propertyName]: files[0]
      });

      return;
    }

    const value = stateValueParser(event, type, defaultValue);

    if (value === undefined) {
      return;
    }

    this.setState({ [propertyName]: value });

    if (typeof (Storage) !== 'undefined') {
      localStorage[propertyName] = value;
    }
  }

  async setLocation (position) {
    let latitude = null;
    let longitude = null;
    let accuracy = null;
    let timeMilliseconds = null;
    let speed = null

    if (position.coords) {
      if (this.state.externalGPS || this.state.neviLocatorGPS) {
        return;
      }

      accuracy = Math.ceil(position.coords.accuracy);
      latitude = position.coords.latitude;
      longitude = position.coords.longitude;
      speed = position.coords.speed;
    }
    else if (position.time) {
      latitude = position.latitude;
      longitude = position.longitude;
      accuracy = position.accuracy;
      timeMilliseconds = position.time;
      speed = position.speed;
    }
    else {
      latitude = position.lat;
      longitude = position.lon;
      accuracy = 10;
      speed = position.speed;
    }

    if (accuracy >= ACCURACY_FILTER_METRES) {
      return;
    }

    let time;

    if (timeMilliseconds == null) {
      time = new Date();
    }
    else {
      time = new Date(timeMilliseconds);
    }

    if (this.state.latitude != null && calculateDistance(
      latitude, longitude, this.state.latitude, this.state.longitude) >= 100) {
      resetKalmanFilter();
    }

    const kalmanCoordinates = kalmanFilter(latitude, longitude, accuracy, time.getTime(), speed);

    latitude = kalmanCoordinates.latitude;
    longitude = kalmanCoordinates.longitude;

    this.setState({
      latitude: latitude,
      longitude: longitude
    });

    let roadNumber = null;
    let roadPart = null;
    let roadDistance = null;

    const converted = toETRSTM35FIN(latitude, longitude);

    let lastRoadNumberMode;

    if (this.state.setLockedRoad != null) {
      lastRoadNumberMode = this.state.setLockedRoad;
    }
    else if (this.state.lastRoadNumbers.length > 10) {
      lastRoadNumberMode = mode(this.state.lastRoadNumbers);
    }

    const data = await getRoadData(converted.y, converted.x, accuracy, lastRoadNumberMode);

    if (this.state.useRoadData && data != null) {
      const x = data.x - converted.x;
      const y = data.y - converted.y;

      if (Math.sqrt((x * x) + (y * y)) <= 10) {
        roadNumber = data.road;
        roadPart = data.part;
        roadDistance = data.distance;
      }

      if (roadNumber != null) {
        let lastRoadNumbers = this.state.lastRoadNumbers.concat(roadNumber);

        if (lastRoadNumbers.length === 20) {
          lastRoadNumbers.pop();
        }
      }
    }

    await new Promise(resolved => {
      this.setState({
        accuracy: accuracy,
        locationTime: paddedNumber(time.getHours()) + ':' + paddedNumber(time.getMinutes()) + ':' + paddedNumber(time.getSeconds()),
        roadNumber: roadNumber,
        roadPart: roadPart,
        roadDistance: roadDistance
      }, () => {
        this.loadLockedRoad();
        resolved();
      });
    });

    this.updateRunningObservation();

    let address = data ? data.address : null;
    let city = data ? data.city : null;

    if (address == null) {
      const addressUrl = ROAD_URL2 + '/muunna?y=' + converted.y +
        '&x=' + converted.x + '&sade=10';

      const addressData = await (await window.fetch(addressUrl)).json();
      address = addressData.osoite;
      city = addressData.kunta;
    }

    this.setState({
      city: address,
      address: city
    });
  }

  updateRunningObservation () {
    let runningObservations = this.state.runningObservations;

    if (runningObservations.length !== 0) {
      runningObservations.forEach(async (observation, index) => {
        const lastPoint = observation.path[observation.path.length - 1];
        const distance = calculateDistance(this.state.latitude, this.state.longitude, lastPoint.latitude, lastPoint.longitude);
        console.log(distance)

        if (distance >= 1) {
          let point = {};

          if (observation.roadData && this.state.roadNumber != null) {
            point.roadNumber = this.state.roadNumber;
            point.roadPart = this.state.roadPart;
            point.roadDistance = this.state.roadDistance;
          }

          point.latitude = this.state.latitude;
          point.longitude = this.state.longitude;
          observation.path.push(point);
          observation.distance += distance;
          runningObservations[index] = observation;

          console.log(runningObservations)

          this.setState({ runningObservations: runningObservations });
        }
      });
    }
  }

  locationError (error) {
    if (this.state.externalGPS) {
      return;
    }
    console.log(error);
  }

  async makeObservation () {
    const template = this.props.templates.find(template => template.get('id') === this.state.selectedTemplate);

    if (template.get('chooseLocation') && !this.state.selectLocation) {
      this.setState({ selectLocation: true });
      this.props.showNotice('Valitse kartalta havainnon paikka', 'Ok');
      return;
    }

    this.setState({
      selectLocation: false,
      selectedTemplate: null,
      creatingObservation: true
    });

    const date = new Date();

    let observation = {
      id: Date.now(),
      report: { id: this.state.selectedReport },
      time: date.toISOString(),
      order: this.props.observations.size + this.state.runningObservations.length + 1,
      template: template.get('id'),
      path: [],
      fields: {}
    }

    template.get('fields').forEach(field => {
      if (field.get('default') != null) {
        observation.fields[field.get('name')] = field.get('default');
      }
      else {
        observation.fields[field.get('name')] = '-';
      }
    });

    const addressField = template.get('fields').find(field => field.get('type') === 'address');

    if (addressField != null) {
      observation.fields[addressField.get('name')] = this.state.address;
    }

    let latitude = this.state.latitude;
    let longitude = this.state.longitude;
    let accuracy = this.state.accuracy;

    if (template.get('chooseLocation')) {
      latitude = this.map.selectedLatitude;
      longitude = this.map.selectedLongitude;
    }

    observation.latitude = latitude;
    observation.longitude = longitude;

    if (template.get('roadData')) {
      observation.roadData = true;
      const converted = toETRSTM35FIN(latitude, longitude);

      let lastRoadNumberMode;

      if (this.state.setLockedRoad != null) {
        lastRoadNumberMode = this.state.setLockedRoad;
      }
      else if (this.state.lastRoadNumbers.length > 10) {
        lastRoadNumberMode = mode(this.state.lastRoadNumbers);
      }

      const data = await getRoadData(converted.y, converted.x, accuracy, lastRoadNumberMode);

      if (data != null) {
        const x = data.x - converted.x;
        const y = data.y - converted.y;

        if (Math.sqrt((x * x) + (y * y)) <= 10) {
          observation.roadNumber = data.road;
          observation.roadPart = data.part;
          observation.roadDistance = data.distance;
        }
      }
    }

    if (this.state.selectedObservationType === 0) {
      try {
        const response = await fetch(`mutation NewObservation {
                                        observationCreate(observation: {
                                              time:` + JSON.stringify(observation.time) +
          ` report: {id: ` + JSON.stringify(observation.report.id) + `}
                                              latitude:` + observation.latitude +
          ` longitude:` + observation.longitude +
          ` roadData:` + JSON.stringify(observation.roadData || false) +
          (observation.roadNumber ? (` roadNumber:` + JSON.stringify(observation.roadNumber)) : '') +
          (observation.roadPart ? (` roadPart:` + JSON.stringify(observation.roadPart)) : '') +
          (observation.roadDistance ? (` roadDistance:` + JSON.stringify(observation.roadDistance)) : '') +
          ` order:` + JSON.stringify(observation.order) +
          ` template:` + JSON.stringify(observation.template) +
          ` 
                                        }) {
                                          id, time, videos {file, name, field {name}}, photos {file, name, field {name}},
                                          report { id }, roadNumber, roadPart, roadDistance,
                                          path { roadNumber, roadPart, roadDistance, latitude, longitude },
                                          latitude, longitude, order, fields, roadData, template
                                        }
                                    }`);

        const data = response.data;

        if (data != null) {
          let newObservation = data.observationCreate;
          newObservation.path = [];
          newObservation.fields = JSON.parse(newObservation.fields);
          newObservation.roadData = observation.roadData;

          this.props.addObservation(newObservation);

          if (template.get('fields').size !== 0) {
            this.goChangeObservation(fromJS(newObservation));
          }

          this.setState({ creatingObservation: false });
          this.props.showNotice('Havainto luotu', 'Ok');
          return;
        }
      } catch (error) {
        console.log(error);
      }

      if (localStorage['savedObservations'] == null) {
        localStorage['savedObservations'] = JSON.stringify([]);
      }

      let observations = JSON.parse(localStorage['savedObservations']);
      observation.notSaved = true;
      observations.push(observation);

      try {
        localStorage['savedObservations'] = JSON.stringify(observations);
        this.props.addObservation(observation);

        if (template.get('fields').size !== 0) {
          this.goChangeObservation(fromJS(observation));
        }
      } catch (error) {
        if (error.code === 22 || error.code === 1014) {
          this.props.showMessage('Virhe', 'Havaintoa ei voida tallentaa, koska paikallinen tila ei riitä.', 'Warning');
        }
      }
    }
    else if (this.state.selectedObservationType === 1) {
      observation.distance = 0;
      observation.running = true;

      let point = {};

      point.latitude = latitude;
      point.longitude = longitude
      observation.path = [point];

      if (localStorage['savedRunningObservations'] == null) {
        localStorage['savedRunningObservations'] = JSON.stringify([]);
      }

      let observations = JSON.parse(localStorage['savedRunningObservations']);
      observations.push(observation);

      try {
        localStorage['savedRunningObservations'] = JSON.stringify(observations);
        this.setState({
          runningObservations: observations,
        });
      } catch (error) {
        if (error.code === 22 || error.code === 1014) {
          this.props.showMessage('Virhe', 'Havaintoa ei voida tallentaa, koska paikallinen tila ei riitä.', 'Warning');
        }
      }
    }
    else {
      this.setState({
        creatingAreaObservation: observation
      });
      this.toggleSetAreaObservation();
    }

    this.setState({ creatingObservation: false });
  }

  async endObservation (index) {
    let observation = this.state.runningObservations[index];
    let runningObservations = this.state.runningObservations;
    runningObservations.splice(index, 1);
    localStorage['savedRunningObservations'] = JSON.stringify(runningObservations);
    this.setState({ runningObservations: runningObservations });

    let path = '[';

    for (let order in observation.path) {
      let point = observation.path[order];
      point.order = order;
      path += '{latitude:' + point.latitude + ', longitude:' + point.longitude;

      if (point.roadNumber) {
        path += ', roadNumber:' + point.roadNumber + ', roadPart:' + point.roadPart +
          ', roadDistance:' + point.roadDistance;
      }

      path += ', order:' + point.order + '}, ';
    }

    path += ']';

    const template = this.props.templates.find(template => template.get('id') === observation.template);

    try {
      const response = await fetch(`mutation NewObservation {
                                      observationCreate(observation: {
                                            time:` + JSON.stringify(observation.time) +
        ` report: {id: ` + JSON.stringify(observation.report.id) + `}` +
        ` roadData:` + JSON.stringify(observation.roadData || false) +
        (observation.roadNumber ? (` roadNumber:` + JSON.stringify(observation.roadNumber)) : '') +
        (observation.roadPart ? (` roadPart:` + JSON.stringify(observation.roadPart)) : '') +
        (observation.roadDistance ? (` roadDistance:` + JSON.stringify(observation.roadDistance)) : '') +
        ` path:` + path +
        ` latitude:` + observation.path[0].latitude +
        ` longitude:` + observation.path[0].longitude +
        ` order:` + JSON.stringify(observation.order) +
        ` template:` + JSON.stringify(observation.template) +
        ` distance:` + JSON.stringify(observation.distance) +
        `
                                      }) {
                                        id, time, videos {file, name, field {name}}, photos {file, name, field {name}},
                                        report { id }, roadNumber, roadPart, roadDistance,
                                        path { roadNumber, roadPart, roadDistance, latitude, longitude, order },
                                        latitude, longitude, order, fields, roadData, template, distance
                                      }
                                  }`);

      const data = response.data;

      if (data != null) {
        const newObservation = data.observationCreate;
        newObservation.fields = JSON.parse(newObservation.fields);
        if (newObservation.path) {
          newObservation.path.sort((a, b) => {
            return a.order - b.order
          });
        }
        this.props.addObservation(newObservation);
        if (template.get('fields').size !== 0) {
          this.goChangeObservation(fromJS(newObservation));
        }
        this.props.showNotice('Havainto lopetettu', 'Ok');
        return;
      }
    } catch (error) {

    }

    if (localStorage['savedObservations'] == null) {
      localStorage['savedObservations'] = JSON.stringify([]);
    }

    let observations = JSON.parse(localStorage['savedObservations']);
    observation.notSaved = true;
    observation.running = false;
    observations.push(observation);
    localStorage['savedObservations'] = JSON.stringify(observations);
    this.props.addObservation(observation);

    if (template.get('fields').size !== 0) {
      this.goChangeObservation(fromJS(observation));
    }

    this.props.showNotice('Havainto lopetettu', 'Ok');
  }

  goChangeObservation (observation) {
    this.setState({
      changingObservation: observation
    });
  }

  clearChangeObservation () {
    this.setState({
      changingObservation: null
    });
  }

  b64toBlob (b64Data, contentType, sliceSize) {
    contentType = contentType || '';

    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  async changeObservation (observation) {
    if (observation.notSaved == null) {
      let area = null;

      if (observation.area) {
        if (observation.area.type === 1) {
          area = '{ position:' + JSON.stringify('{"lat":' + observation.area.position.lat + ', "lng":' + observation.area.position.lng + '}')
            + ', type:' + observation.area.type + ',  radius:' + observation.area.radius + ' }';
        }
        else {
          area = '{ position: "' + JSON.stringify(observation.area.position)
            + '" , type:' + observation.area.type + ',  width:' + observation.area.width + ', height:' + observation.area.height + ' }';
        }
      }

      const template = this.props.templates.find(template => template.get('id') === observation.template);

      const path = 'neviobs/' + observation.id + '/';

      try {
        await fetch(null, 'DELETE', path);
      } catch (err) { }

      let fields = '{';

      for (let f = 0; f < template.get('fields').size; f++) {
        const field = template.get('fields').get(f);

        if (field.get('type') === 'image' || field.get('type') === 'video' ||
          field.get('type') === 'audio' || field.get('type') === 'file') {
          if (observation.fields[field.get('name')] !== '-') {
            let files = '[';
            let allSuccess = true;

            for (let i = 0; i < observation.fields[field.get('name')].length; i++) {
              try {
                const file = observation.fields[field.get('name')][i];
                const block = file.file.split(";");
                const contentType = block[0].split(":")[1];
                const realData = block[1].split(",")[1];
                const blob = this.b64toBlob(realData, contentType);
                let formData = new FormData();
                formData.append('file', blob, file.name);
                await fetch(formData, 'POST', path);
                files += '{"name":"' + file.name + '","file":"' + path + file.name + '"},';
              } catch (error) {
                allSuccess = false;
              }
            }

            files += ']';

            if (!allSuccess) {
              this.props.showNotice('Yhtä tai useampaa tiedostoa ei onnistuttu tallentamaan', 'Error');
            }

            fields += '"' + field.get('name') + '":' + files + ', ';
          }
        }
        else if (field.get('type') === 'float' || field.get('type') === 'integer') {
          fields += '"' + field.get('name') + '":' + observation.fields[field.get('name')] + ', ';
        }
        else {
          fields += '"' + field.get('name') + '":"' + observation.fields[field.get('name')] + '", ';
        }
      }

      fields += '}';

      const response = await fetch(`mutation ObservationChange {
                                      observationUpdate(id:` + observation.id + `,
                                        observation: {
                                          fields:` + JSON.stringify(fields) +
        (observation.area != null ? ` area:` + area : '') +
        `}
                                      )
                                      { id, time, videos {file, name, field {name}}, photos {file, name, field {name}},
                                      report { id }, roadNumber, roadPart, roadDistance,
                                      path { roadNumber, roadPart, roadDistance, latitude, longitude },
                                      area { position, width, height, type, radius },
                                      latitude, longitude, order, fields, roadData, template }
                                    }`);

      if (response.data == null || response.data.observationUpdate == null) {
        this.props.showNotice('Havainnon muokkaus epäonnistui', 'Error');
        return;
      }

      observation = response.data.observationUpdate;

      observation.fields = JSON.parse(observation.fields);

      for (let f = 0; f < template.get('fields').size; f++) {
        const field = template.get('fields').get(f);

        if ((field.get('type') === 'image' || field.get('type') === 'video' ||
          field.get('type') === 'audio' || field.get('type') === 'file')) {
          if (observation.fields[field.get('name')] !== '-') {
            let files = [];

            for (let i = 0; i < observation.fields[field.get('name')].length; i++) {
              const file = observation.fields[field.get('name')][i];
              const type = field.get('type') + '/' + file.name.split('.')[1];
              try {
                const response = await fetch(null, 'GET', file.file, type);
                const image = await this.loadImage(response);
                files.push({ name: file.name, file: image || '-' });
              } catch (error) {

              }
            }

            observation.fields[field.get('name')] = files;
          }
        }
      }

      if (observation.area) {
        observation.area.position = JSON.parse(observation.area.position);
      }
    }
    else {
      let observations = JSON.parse(localStorage['savedObservations']);
      const index = observations.findIndex(observation => observation['id'] === this.state.changingObservation.get('id'));
      observations[index] = observation;
      try {
        localStorage['savedObservations'] = JSON.stringify(observations);
      } catch (error) {
        if (error.code === 22 || error.code === 1014) {
          this.props.showMessage('Virhe', 'Havaintoa ei voida tallentaa, koska paikallinen tila ei riitä.', 'Warning');
        }
      }
    }

    this.props.showNotice('Havainto muokattu', 'Ok');
    this.props.changeObservation(observation);
    this.clearChangeObservation();
  }

  async changeObservationLocation (id, position) {
    let observation = this.props.observations.find(obs => obs.get('id') === id);

    let latitude = position.latitude;
    let longitude = position.longitude;

    let roadData;

    if (observation.get('roadData')) {
      const converted = toETRSTM35FIN(latitude, longitude);
      roadData = await getRoadData(converted.y, converted.x, 10);
    }

    if (!observation.get('notSaved')) {
      if (observation.get('area') != null) {
        if (observation.get('area').get('type') === 1) {
          latitude = position.lat;
          longitude = position.lng;
          position = '{"lat":' + latitude + ', "lng":' + longitude + '}';
        }
        else {
          latitude = position[0][0];
          longitude = position[0][1];
          position = JSON.stringify(position);
        }
      }
      const response = await fetch(
        `mutation ObservationChange{
                    observationUpdate(id:` + id + `,
                      observation: {
                        latitude:` + latitude +
        ` longitude:` + longitude +
        (observation.get('roadData') ? (
          (' roadNumber:' + (roadData ? roadData.road : '-')) +
          (' roadPart:' + (roadData ? roadData.part : '-')) +
          (' roadDistance:' + (roadData ? roadData.distance : '-')))
          : '') +
        (observation.get('area') != null ? ` area: { position:` + JSON.stringify(position) + '}' : '') +
        `}
                    )
                    { id, time, videos {file, name, field {name}}, photos {file, name, field {name}},
                    report { id }, roadNumber, roadPart, roadDistance,
                    path { roadNumber, roadPart, roadDistance, latitude, longitude },
                    area { position, width, height, type, radius },
                    latitude, longitude, order, fields, roadData, template }
                  }`);

      if (response.data == null) {
        this.props.showNotice('Havainnon muokkaus epäonnistui', 'Error');
      }
      else {
        observation = response.data.observationUpdate;

        const currentObservation = this.props.observations.find(
          obs => obs.get('id') === observation.id);
        const currentFields = currentObservation.get('fields');

        observation.fields = {};

        for (let field of currentFields) {
          const key = field[0];
          const value = field[1];

          observation.fields[key] = value;
        }

        if (observation.area) {
          observation.area.position = JSON.parse(observation.area.position);
        }
      }
    }
    else {
      let observations = JSON.parse(localStorage['savedObservations']);
      const index = observations.findIndex(obs => obs['id'] === id);
      observation = observations[index];

      if (observation.area != null) {
        observation.area.position = position;
      }
      else {
        observation.latitude = latitude;
        observation.longitude = longitude;
      }

      observations[index] = observation;
      localStorage['savedObservations'] = JSON.stringify(observations);
    }

    this.props.changeObservation(observation);
  }

  confirmRemoveObservation (observation) {
    this.setState({
      removingObservation: observation
    });
    this.props.showConfirm('Poistetaanko havainto ' + observation.get('order') + '?', this.removeObservation);
  }

  removeObservation () {
    // Without timer does not work. Error: (Reducers may not dispatch actions.)
    timer(0).then(async () => {
      let savedObservations = [];
      if (localStorage['savedObservations'] != null) {
        savedObservations = JSON.parse(localStorage['savedObservations']);
      }

      if (!this.state.removingObservation.get('notSaved')) {
        await fetch(`mutation RemoveObservation{
                observationDelete(id:` + this.state.removingObservation.get('id') + `)
                { success }
              }`
        ).catch(error => {
          this.props.showNotice('Havainnon poisto epäonnistui', 'Error');
          return;
        });
        const path = 'neviobs/' + this.state.removingObservation.get('id') + '/';
        try {
          await fetch(null, 'DELETE', path);
        } catch (err) {

        }
      }
      else {
        savedObservations = savedObservations.filter(
          observation => observation.id !== this.state.removingObservation.get('id'));
      }

      const higherOrderObservations = this.props.observations.filter(
        observation => observation.get('order') > this.state.removingObservation.get('order'));

      higherOrderObservations.forEach(async observation => {
        if (!observation.get('notSaved')) {
          await fetch(`mutation ObservationChange{
            observationUpdate(id:` + observation.get('id') + `,
              observation: {
                order:` + JSON.stringify(observation.get('order') - 1) +
            `}
            )
            { id }
          }`);
        }
        else {
          let editingObservation = savedObservations.find(obs => obs.id === observation.get('id'));
          editingObservation.order--;
        }

        this.props.changeObservationOrder(observation.get('id'), observation.get('order') - 1);
      });

      localStorage['savedObservations'] = JSON.stringify(savedObservations);

      if (localStorage['savedRunningObservations'] != null) {
        let runningObservations = JSON.parse(localStorage['savedRunningObservations']);
        runningObservations.forEach(observation => {
          if (observation.order > this.state.removingObservation.get('order')) {
            observation.order--;
          }
        });
        localStorage['savedRunningObservations'] = JSON.stringify(runningObservations);
        this.setState({ runningObservations: runningObservations });
      }

      this.props.removeObservation(this.state.removingObservation.get('id'));
      this.props.showNotice('Havainto poistettu', 'Ok');
    });
  }

  toggleNewObservation () {
    this.setState({
      showNewObservation: !this.state.showNewObservation,
      selectedTemplate: null
    });
  }

  selectTemplate (id) {
    if (this.state.orderTemplates) {
      let templateOrder = this.state.templateOrder;

      if (templateOrder[id] == null) {
        templateOrder[id] = Object.keys(templateOrder).length + 1;
      }
      else {
        for (let key in templateOrder) {
          if (templateOrder[key] !== 1) {
            templateOrder[key]--;
          }
        }
        templateOrder[id] = Object.keys(templateOrder).length;
      }

      this.setState({ templateOrder: templateOrder });
      localStorage.templateOrder = JSON.stringify(templateOrder);
      return;
    }

    if (this.state.latitude == null || this.state.longitude == null) {
      this.props.showNotice('Sijaintia ei saatu, joten havaintoa ei voida tehdä', 'Warning');
      return;
    }

    this.setState({
      selectedTemplate: id
    }, () => {
      const template = this.props.templates.find(template => template.get('id') === id);
      if (template.get('types').size === 1) {
        this.selectObservationType(template.get('types').first());
      }
    });
  }

  clearSelectedTemplate () {
    this.setState({
      selectedTemplate: null
    });
  }

  selectObservationType (type) {
    this.setState({
      selectedObservationType: type
    }, () => this.makeObservation());
  }

  toggleObservations () {
    this.setState({
      showObservations: !this.state.showObservations
    });
  }

  selectObservation (id) {
    this.setState({
      goToObservation: id
    }, () => this.setState({ goToObservation: null }));
  }

  toggleShowChangeProfile () {
    this.setState({
      showChangeProfile: !this.state.showChangeProfile
    });
  }

  setLockedRoad () {
    if (this.state.lockedRoad == null || this.state.lockedRoad === '' ||
      this.state.lockedRoad === 0) {
      this.setState({
        setLockedRoad: null
      });
    }
    else {
      this.setState({
        setLockedRoad: this.state.lockedRoad
      }, () => navigator.geolocation.getCurrentPosition(this.setLocation, this.locationError,
        { enableHighAccuracy: true }));
    }
    this.toggleLockToRoad();
  }

  toggleLockToRoad () {
    this.setState({
      showLockToRoad: !this.state.showLockToRoad
    });
  }

  toggleShowMakeMark () {
    this.setState({
      showMakeMark: !this.state.showMakeMark
    });
  }

  toggleInputCoordinates () {
    this.setState({ inputCoordinates: !this.state.inputCoordinates });
  }

  toggleShowFindRoadInfo () {
    this.setState({
      showFindRoadInfo: !this.state.showFindRoadInfo
    });
  }

  toggleSetAreaObservation () {
    this.setState({
      setAreaObservation: !this.state.setAreaObservation
    });
  }

  async createAreaObservation (type) {
    this.setState({
      setAreaObservation: false
    });
    let observation = this.state.creatingAreaObservation;

    let area;

    if (type === 1) {
      observation.area = {
        position: { lat: this.state.latitude, lng: this.state.longitude },
        radius: 10,
        type: type
      };

      area = '{ position:' + JSON.stringify('{"lat":' + observation.area.position.lat + ', "lng":' + observation.area.position.lng + '}')
        + ', type:' + type + ',  radius:' + observation.area.radius + ' }';
    }
    else {
      const width = 20;
      const height = 20;
      const coordinates = calculateOffsetCoordinates(this.state.latitude, this.state.longitude,
        -width / 2, -height / 2);
      const otherSideCoordinates = calculateOffsetCoordinates(this.state.latitude, this.state.longitude,
        width / 2, height / 2);
      observation.area = {
        position: [[coordinates.latitude, coordinates.longitude],
        [otherSideCoordinates.latitude, otherSideCoordinates.longitude]],
        width: width,
        height: height,
        type: type
      };

      area = '{ position: "' + JSON.stringify(observation.area.position)
        + '" , type:' + type + ',  width:' + width + ', height:' + height + ' }';
    }

    const template = this.props.templates.find(template => template.get('id') === observation.template);

    let fields = '{';

    template.get('fields').forEach(field => {
      fields += '"' + field.get('name') + '":"' + observation.fields[field.get('name')] + '", ';
    });

    fields += '}';

    try {
      const response = await fetch(`mutation NewObservation {
                                      observationCreate(observation: {
                                            time:` + JSON.stringify(observation.time) +
        ` report: {id: ` + JSON.stringify(observation.report.id) + `}
                                            latitude:` + observation.latitude +
        ` longitude:` + observation.longitude +
        ` roadData:` + (observation.roadData || false) +
        ` order:` + JSON.stringify(observation.order) +
        ` template:` + JSON.stringify(observation.template) +
        ` fields:` + JSON.stringify(fields) +
        ` area:` + area +
        `}) {
                                        id, time, videos {file, name, field {name}}, photos {file, name, field {name}},
                                        report { id }, roadNumber, roadPart, roadDistance,
                                        path { roadNumber, roadPart, roadDistance, latitude, longitude },
                                        latitude, longitude, order, fields, roadData, template,
                                        area {position, type, radius, width, height}
                                      }
                                  }`);

      const data = response.data;

      if (data != null) {
        const newObservation = data.observationCreate;
        newObservation.path = [];
        newObservation.fields = JSON.parse(newObservation.fields);
        newObservation.area.position = JSON.parse(newObservation.area.position);
        this.props.addObservation(newObservation);
        this.goChangeObservation(fromJS(newObservation));
        this.props.showNotice('Havainto luotu', 'Ok');
        return;
      }
    } catch (error) {

    }

    if (localStorage['savedObservations'] == null) {
      localStorage['savedObservations'] = JSON.stringify([]);
    }

    let observations = JSON.parse(localStorage['savedObservations']);
    observation.notSaved = true;
    observations.push(observation);
    localStorage['savedObservations'] = JSON.stringify(observations);

    this.props.addObservation(observation);
    this.goChangeObservation(fromJS(observation));
    this.props.showNotice('Havainto luotu paikallisesti', 'Warning');
  }

  saveReport (report) {
    if (typeof (Storage) === 'undefined') {
      this.props.showMessage('Virhe', 'Raporttia ei voitu tallentaa paikallisesti eikä palvelimelle', 'Error');
      return;
    }

    if (report.id == null) {
      if (localStorage['savedReports'] == null) {
        localStorage['savedReports'] = JSON.stringify([]);
      }

      report['id'] = Date.now();
      report['state'] = { id: 1, name: 'Käynnissä' };
      report['not_saved'] = true;

      let reports = JSON.parse(localStorage['savedReports']);
      reports.push(report);
      localStorage['savedReports'] = JSON.stringify(reports);
      this.props.addReport(report);
      this.props.showNotice('Raportti luotu laitteelle ja lisätään palvelimelle kun saadaan yhteys palautettua', 'Warning');
      return report['id'];
    }

    if (localStorage['savedReports'] == null) {
      this.props.showMessage('Virhe', 'Raporttia ei voitu tallentaa paikallisesti eikä palvelimelle', 'Error');
      return;
    }

    let reports = JSON.parse(localStorage['savedReports']);
    const foundReportIndex = reports.findIndex(r => r.id === report.id)

    if (foundReportIndex === -1) {
      this.props.showMessage('Virhe', 'Raporttia ei voitu tallentaa paikallisesti eikä palvelimelle', 'Error');
      return;
    }

    let newReport = reports[foundReportIndex];
    reports.splice(foundReportIndex, 1);

    if (report.state === 0) newReport['state'] = { id: 0, name: 'Käynnissä' };
    else if (report.state === 1) newReport['state'] = { id: 1, name: 'Alustava' };
    else newReport['state'] = { id: 2, name: 'Julkaistu' };

    reports.push(newReport);
    localStorage['savedReports'] = JSON.stringify(reports);

    this.props.clearObservation();
    this.setState({
      runningObservations: []
    });

    this.props.removeReport(newReport['id']);
    this.props.showNotice('Raportti muokattu laitteelle ja muokataan myös palvelimelle kun saadaan yhteys palautettua', 'Warning');
    return report.id;
  }

  async changeReportState () {
    const report = {
      id: this.state.selectedReport,
      state: this.newState,
    };

    try {
      const response = await fetch(`mutation ReportChange{
                                  reportUpdate(id:` + report.id + `,
                                    report: {
                                      state: {id:` + report.state + `}
                                    }
                                  )
                                  { id }
                                }`);
      if (response.data.reportUpdate.id != null) {
        this.props.showNotice('Raportti muokattu', 'Ok');
      }
      else {
        this.saveReport(report);
      }
    } catch (error) {
      this.saveReport(report);
    }

    if (report.state === 2) {
      this.setState({
        redirectToEdit: true
      });
    }
    else {
      this.setState({
        redirectToView: report.id
      });
    }
  }

  confirmChangeReportToPreliminary () {
    if (this.state.selectedReport == null) {
      this.props.showNotice('Raporttia ei ole valittu', 'Warning');
      return;
    }
    if (this.state.runningObservations.length !== 0) {
      this.props.showNotice('Lopeta kaikki käynnissä olevat havainnot ensin.', 'Warning');
      return;
    }
    const report = this.props.reports.find(report => report.get('id') === this.state.selectedReport);
    this.newState = 2;
    this.props.showConfirm('Muutetaanko tämä raportti (' + report.get('name') +
      ') alustavaksi?', this.changeReportState);
  }

  confirmChangeReportToPublished () {
    if (this.state.selectedReport == null) {
      this.props.showNotice('Raporttia ei ole valittu', 'Warning');
      return;
    }
    const report = this.props.reports.find(report => report.get('id') === this.state.selectedReport);
    this.newState = 3;
    this.props.showConfirm('Muutetaanko valmiiksi raportti (' + report.get('name') +
      ')?', this.changeReportState);
  }

  makeExcel () {
    let observations = this.props.observations.filter(observation => !observation.get('hide'));

    if (observations.size === 0) {
      this.props.showNotice('Raportissa ei olisi mitään näytettävää', 'Warning');
      return;
    }

    let excelHeaders = ['Havainto', 'Havainto pohja', 'Aika', 'Latitude', 'Longitude',
      'Tie', 'Tieosa', 'Paalu'];
    let excelDataHeaders = ['order', 'template', 'time', 'latitude', 'longitude',
      'roadNumber', 'roadPart', 'roadDistance'];

    observations.forEach((observation, index) => {
      if (observation.get('path').size !== 0) {
        if (!excelHeaders.includes('Aloitus latitude')) {
          excelHeaders.push('Aloitus latitude');
          excelDataHeaders.push('path.0.latitude');
          excelHeaders.push('Aloitus longitude');
          excelDataHeaders.push('path.0.longitude');
          excelHeaders.push('Aloitus tie');
          excelDataHeaders.push('path.0.roadNumber');
          excelHeaders.push('Aloitus tieosa');
          excelDataHeaders.push('path.0.roadPart');
          excelHeaders.push('Aloitus paalu');
          excelDataHeaders.push('path.0.roadDistance');

          const lastIndex = observation.get('path').size - 1;

          excelHeaders.push('Lopetus latitude');
          excelDataHeaders.push('path.' + lastIndex + '.latitude');
          excelHeaders.push('Lopetus longitude');
          excelDataHeaders.push('path.' + lastIndex + '.longitude');
          excelHeaders.push('Lopetus tie');
          excelDataHeaders.push('path.' + lastIndex + '.roadNumber');
          excelHeaders.push('Lopetus tieosa');
          excelDataHeaders.push('path.' + lastIndex + '.roadPart');
          excelHeaders.push('Lopetus paalu');
          excelDataHeaders.push('path.' + lastIndex + '.roadDistance');
        }
      }

      const observationTemplate = this.props.templates.find(template => template.get('id') === observation.get('template'));
      const templateFields = observationTemplate.get('fields');

      let changedObservation = observation;

      let fields = observation.get('fields');

      for (let field of templateFields) {
        const key = field.get('name');
        const value = fields.get(key);

        if (!excelHeaders.includes(key)) {
          excelHeaders.push(key);
          excelDataHeaders.push('fields.' + key);
        }

        let valueString;

        if (field.get('type') === 'checkbox') {
          valueString = value === 'true' ? 'Kyllä' : 'Ei';
        }
        else if (field.get('type') === 'date' && value) {
          const dateValue = new Date(value);
          valueString = dateValue.getDate() + '.' + (dateValue.getMonth() + 1) + '.' + dateValue.getFullYear()
            + ' ' + paddedNumber(dateValue.getHours()) + ':' + paddedNumber(dateValue.getMinutes());
        }
        else if (field.get('type') === 'radio' || field.get('type') === 'dropdown') {
          valueString = field.get('values').get(value);
        }
        else if ((field.get('type') === 'image' || field.get('type') === 'video' ||
          field.get('type') === 'audio' || field.get('type') === 'file') && value !== '-') {
          valueString = value.length + ' kpl';
        }
        else {
          valueString = value;
        }

        fields = changedObservation.get('fields').set(key, valueString);
        changedObservation = changedObservation.set('fields', fields);
      }

      observations = observations.set(index, changedObservation.set('template', observationTemplate.get('name')));
    });

    this.setState({
      excelHeaders: excelHeaders,
      excelDataHeaders: excelDataHeaders,
      reportObservations: observations,
      extraTag: ''
    }, () => this.toggleMakeExcel());
  }

  makePathExcel () {
    const observations = this.props.observations.filter(observation => !observation.get('hide'));

    if (observations.size === 0) {
      this.props.showNotice('Raportissa ei olisi mitään näytettävää', 'Warning');
      return;
    }

    let list = [];
    let excelHeaders = ['Havainto', 'Havainto pohja', 'Latitude', 'Longitude',
      'Tie', 'Tieosa', 'Paalu'];
    let excelDataHeaders = ['order', 'template', 'latitude', 'longitude',
      'roadNumber', 'roadPart', 'roadDistance'];

    observations.forEach(observation => {
      const templateName = this.props.templates.find(template =>
        template.get('id') === observation.get('template')).get('name');

      if (observation.get('path').size !== 0) {
        observation.get('path').forEach(point => {
          list.push({
            order: observation.get('order'),
            template: templateName,
            latitude: point.get('latitude'),
            longitude: point.get('longitude'),
            roadNumber: point.get('roadNumber'),
            roadPart: point.get('roadPart'),
            roadDistance: point.get('roadDistance')
          });
        });
      }
      else {
        list.push({
          order: observation.get('order'),
          template: templateName,
          latitude: observation.get('latitude'),
          longitude: observation.get('longitude'),
          roadNumber: observation.get('roadNumber'),
          roadPart: observation.get('roadPart'),
          roadDistance: observation.get('roadDistance')
        });
      }
    });

    this.setState({
      excelHeaders: excelHeaders,
      excelDataHeaders: excelDataHeaders,
      reportObservations: fromJS(list),
      extraTag: ' - reitti'
    }, () => this.toggleMakeExcel());
  }

  toggleMakeExcel () {
    this.setState({
      makeExcel: !this.state.makeExcel
    });
  }

  makePdf () {
    const observations = this.props.observations.filter(observation => !observation.get('hide'));

    if (observations.size === 0) {
      this.props.showNotice('Raportissa ei ole mitään näytettävää', 'Warning');
      return;
    }

    this.setState({
      loadingPdf: true,
      extraTag: ''
    }, async () => {
      let pdf = new jsPDF();

      if (this.state.reportName.length > 25) {
        pdf.setFontSize(30);
      }
      else {
        pdf.setFontSize(40);
      }
      pdf.setFontType('bold');
      let textWidth = pdf.getStringUnitWidth(this.state.reportName) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
      let textOffset = (pdf.internal.pageSize.width - textWidth) / 2;
      pdf.text(this.state.reportName, textOffset, 50);
      textWidth = pdf.getStringUnitWidth('Raportti') * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
      textOffset = (pdf.internal.pageSize.width - textWidth) / 2;
      pdf.text('Raportti', textOffset, 70);
      pdf.setFontSize(40);

      for (let i = 0; i < observations.size; i++) {
        const observation = observations.get(i);

        const template = this.props.templates.find(template => template.get('id') === observation.get('template'));

        pdf.addPage();
        pdf.setFontSize(30);
        pdf.setFontType('bold');
        pdf.text('Havainto ' + observation.get('order') + ' - ' + template.get('name'), 15, 25);
        pdf.setFontSize(15);
        pdf.setFontType('normal');
        const date = new Date(observation.get('time'));
        const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
          + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) +
          ':' + paddedNumber(date.getSeconds());
        pdf.text(time, 15, 35);

        let heightLocation = 42;

        if (observation.get('path').size !== 0) {
          pdf.setFontType('bold');
          pdf.text('Aloitus:', 15, heightLocation);
          pdf.setFontType('normal');
          heightLocation += 7;
          const firtPoint = observation.get('path').first();
          const coordinates = firtPoint.get('latitude') + ' ' + firtPoint.get('longitude');
          pdf.text(coordinates, 15, heightLocation);
          heightLocation += 7;

          if (firtPoint.get('roadNumber') != null) {
            const roadInfo = firtPoint.get('roadNumber') + ' / ' + firtPoint.get('roadPart') + ' / ' + firtPoint.get('roadDistance');
            pdf.text(roadInfo, 15, heightLocation);
            heightLocation += 7;
          }

          if (observation.get('path').size !== 1) {
            pdf.setFontType('bold');
            pdf.text('Lopetus:', 15, heightLocation);
            pdf.setFontType('normal');
            heightLocation += 7;
            const lastPoint = observation.get('path').last();
            const coordinates = lastPoint.get('latitude') + ' ' + lastPoint.get('longitude');
            pdf.text(coordinates, 15, heightLocation);
            heightLocation += 7;

            if (lastPoint.get('roadNumber') != null) {
              const roadInfo = lastPoint.get('roadNumber') + ' / ' + lastPoint.get('roadPart') + ' / ' + lastPoint.get('roadDistance');
              pdf.text(roadInfo, 15, heightLocation);
              heightLocation += 7;
            }
          }
        }
        else {
          const coordinates = observation.get('latitude') + ' ' + observation.get('longitude');
          pdf.text(coordinates, 15, heightLocation);
          heightLocation += 7;

          if (observation.get('roadData') && observation.get('roadNumber') != null) {
            const roadInfo = observation.get('roadNumber') + ' / ' + observation.get('roadPart') + ' / ' + observation.get('roadDistance');
            pdf.text(roadInfo, 15, heightLocation);
            heightLocation += 7;
          }
        }

        const templateFields = template.get('fields');

        const fields = observation.get('fields').toJS();
        let images = [];

        for (let field of templateFields) {
          const value = fields[field.get('name')];

          if (value == null) {
            continue;
          }

          let valueString = '-'

          if (field.get('type') === 'checkbox') {
            valueString = value === 'true' ? 'Kyllä' : 'Ei';
          }
          else if (field.get('type') === 'date' && value !== '-') {
            const dateValue = new Date(value);
            valueString = dateValue.getDate() + '.' + (dateValue.getMonth() + 1) + '.' + dateValue.getFullYear()
              + ' ' + paddedNumber(dateValue.getHours()) + ':' + paddedNumber(dateValue.getMinutes());
          }
          else if (field.get('type') === 'radio' || field.get('type') === 'dropdown') {
            valueString = field.get('values').get(value) || '-';
          }
          else if (field.get('type') === 'image') {
            if (value === '-') {
              continue;
            };

            for (let imgI = 0; imgI < value.length; imgI++) {
              const image = value[imgI];
              const file = image.file;
              const fileType = file.substring(11, file.indexOf(';')).toUpperCase();
              let dimensions = await this.getImageDimensions(file);
              const width = 180;
              const height = dimensions.height * (width / dimensions.width)

              images.push({
                file: file,
                fileType: fileType,
                width: width,
                height: height
              });
            }
            break;
          }
          else {
            valueString = value;
          }

          pdf.setFontType('bold');

          if (valueString.length > 40) {

          }

          pdf.text(field.get('name') + ': ', 15, heightLocation);
          pdf.setFontType('normal');
          pdf.text(valueString, 15, heightLocation + 7);
          heightLocation += 14;
        }

        for (let index in images) {
          const image = images[index];

          if (heightLocation + image.height > 290) {
            pdf.addPage();
            heightLocation = 15;
          }

          pdf.addImage(image.file, image.fileType, 15, heightLocation, image.width, image.height);
          heightLocation += 95;
        }
      }

      this.map.once('moveend', function () {
        leafletImage(this.map, function (error, canvas) {
          let mapImage = canvas.toDataURL("image/png");
          pdf.setPage(1);
          pdf.addImage(mapImage, 'PNG', 0, 100, 210, canvas.height * (210 / canvas.width));
          pdf.save(this.state.reportName + '.pdf');
          this.setState({
            loadingPdf: false
          });
        }.bind(this));
      }.bind(this));

      this.map.fitBounds(this.state.areaBound);
    });
  }

  getImageDimensions (file) {
    return new Promise(function (resolved, rejected) {
      let image = new Image();
      image.onload = function () {
        resolved({ width: image.width, height: image.height })
      };
      image.src = file;
    });
  }

  viewObservation (observation) {
    this.setState({
      viewObservation: observation
    });
  }

  resetViewObservation () {
    this.setState({
      viewObservation: null
    });
  }

  toggleFilterObservations () {
    if (this.state.selectedReport == null) {
      this.props.showNotice('Raportia ei ole valittu', 'Warning');
      return;
    }

    if (this.props.observations.size === 0) {
      this.props.showNotice('Raportissa ei ole yhtään havaintoa', 'Warning');
      return;
    }

    if (!this.state.showFilterObservations) {
      this.setState({
        selectedFilterTemplates: this.state.setFilterTemplates.slice(),
        filterStartTime: this.state.setFilterStartTime != null ?
          this.state.setFilterStartTime.toISOString().substring(0, 16) : '',
        filterEndTime: this.state.setFilterEndTime != null ?
          this.state.setFilterEndTime.toISOString().substring(0, 16) : ''
      });
    }

    this.setState({
      showFilterObservations: !this.state.showFilterObservations
    });
  }

  filterObservations () {
    let startTime = null;
    let endTime = null;

    if (this.state.filterStartTime !== '' && this.state.filterEndTime !== '') {
      startTime = new Date(this.state.filterStartTime);
      endTime = new Date(this.state.filterEndTime);

      const userTimezoneOffset = startTime.getTimezoneOffset() * 60000;

      startTime = new Date(startTime.getTime() - userTimezoneOffset);
      endTime = new Date(endTime.getTime() - userTimezoneOffset);

      if (isNaN(startTime.getDate())) {
        startTime = null;
        endTime = null;
      }
    }

    this.setState({
      showFilterObservations: !this.state.showFilterObservations,
      setFilterTemplates: this.state.selectedFilterTemplates,
      setFilterStartTime: startTime,
      setFilterEndTime: endTime
    });
  }

  toggleCreateNewReport () {
    this.setState({ showCreateNewReport: !this.state.showCreateNewReport });
  }

  async createNewReport () {
    let reportName = this.state.newReportName;

    if (reportName === '' || reportName == null) {
      this.props.showNotice('Raportin nimi ei voi olla tyhjä', 'Warning')
      return;
    }

    this.setState({ creatingNewReport: true });

    if (this.props.reports.find(report => report.get('name') === reportName)) {
      let number = 2;

      while (this.props.reports.find(report => report.get('name') === reportName + ' ' + number)) {
        number++;
      }

      reportName = reportName + ' ' + number;
    }

    const date = new Date().toISOString().substring(0, 10);

    const report = {
      name: reportName,
      startTime: date,
      receivers: []
    };

    let newReportId;

    try {
      const response = await fetch(`mutation NewReport{
                                      reportCreate(report: {
                                          name:` + JSON.stringify(report.name) +
        ` startTime:` + JSON.stringify(report.startTime) +
        ` state: {id: 1}
                                      }) {
                                        id, name, startTime, reporter{id, first_name, last_name},
                                        state{id}, receivers
                                      }
                                    }`);

      const newReport = response.data.reportCreate;
      newReport.observations = [];
      newReportId = newReport.id;
      newReport.receivers = [];

      if (newReport != null) {
        this.props.addReport(newReport);
        this.props.showNotice('Raportti luotu', 'Ok');
      }
      else {
        newReportId = this.saveReport(report);
      }
    } catch (error) {
      newReportId = this.saveReport(report);
    }

    localStorage.selectedReport = newReportId;

    this.toggleCreateNewReport();

    this.setState({
      selectedReport: newReportId,
      creatingNewReport: false
    });
  }

  addFilterTemplate (templateId) {
    let selectedFilterTemplates = this.state.selectedFilterTemplates;
    selectedFilterTemplates.push(templateId);
    this.setState({ selectedFilterTemplates: selectedFilterTemplates });
  }

  removeFilterTemplate (index) {
    let selectedFilterTemplates = this.state.selectedFilterTemplates;
    selectedFilterTemplates.splice(index, 1);
    this.setState({
      selectedFilterTemplates: selectedFilterTemplates
    });
  }

  clearFilterObservations () {
    this.setState({
      selectedFilterTemplates: [],
      filterStartTime: '',
      filterEndTime: ''
    });
  }

  async getRoadArray (number, startPart, startDistance, endPart, endDistance) {
    let road = [];

    let url = '?tie=' + number + '&osa=' + startPart + '&etaisyys=' + startDistance +
      '&osa_loppu=' + endPart + '&etaisyys_loppu=' + endDistance +
      '&ajorata=0,1,2&valihaku=true&palautusarvot=5';

    try {
      const fetchURL = ROAD_URL + url;

      const data = await (await window.fetch(fetchURL)).json();
      const features = data.features;

      for (let index in features) {
        const paths = features[index].geometry.coordinates;

        for (let p in paths) {
          const path = paths[p];
          let roadPath = [];

          if (path[0][0] == null) {
            const converted = toWGS84(path[1], path[0]);

            road.push({
              latitude: converted.latitude,
              longitude: converted.longitude,
            });
          }
          else {
            for (let c in path) {
              const coordinate = path[c];
              const converted = toWGS84(coordinate[1], coordinate[0]);

              roadPath.push({
                latitude: converted.latitude,
                longitude: converted.longitude,
              });
            }

            road.push(roadPath);
          }
        }
      }

      return road;
    } catch (error) {
      console.log(error);
    }

    const risingPart = endPart >= startPart;

    let skipAmount = 40;

    if (!risingPart) {
      skipAmount = -skipAmount;
    }

    for (let part = startPart; risingPart ? part < endPart : part > endPart; risingPart ? part++ : part--) {
      let distance = 0;

      if (startPart === part) {
        distance = startDistance;
      }

      while (true) {
        const data = await (await window.fetch(ROAD_URL2 + '/muunna?tie=' + number +
          '&osa=' + part + '&etaisyys=' + distance)).json();

        if (data['virhe'] != null) {
          break;
        }

        const x = data['alkupiste']['tieosoitteet'][0]['point']['x'];
        const y = data['alkupiste']['tieosoitteet'][0]['point']['y'];
        const converted = toWGS84(y, x);

        road.push({
          latitude: converted.latitude,
          longitude: converted.longitude,
          part: part,
          distance: distance
        });

        distance += skipAmount;
      }
    }

    return road;
  }

  async loadLockedRoad () {
    if (this.state.roadNumber !== this.state.setLockedRoad) {
      return;
    }

    const lastDistance = await getRoadPartMaxDistance(this.state.road, this.state.part);

    if (lastDistance == null) {
      return;
    }

    let road = await this.getRoadArray(this.state.road, this.state.part, 0, this.state.part, lastDistance);

    this.setState({
      lockedRoadPath: road
    });
  }

  toggleSettings () {
    this.setState({ showSettings: !this.state.showSettings });
  }

  async toggleExternalGPS () {
    let value = !this.state.externalGPS;

    if (value) {
      this.setState({ loadingExternalGPS: true });

      if (await this.getExternalGPS()) {
        this.externalGPSTimer = setInterval(this.getExternalGPS, 1000);
      }
      else {
        value = false;
      }

      this.setState({ loadingExternalGPS: false });
    }
    else {
      clearInterval(this.externalGPSTimer);
    }

    this.setState({ externalGPS: value });
    localStorage.externalGPS = value;
  }

  async getExternalGPS () {
    try {
      const gps = await fetchSensor('http://127.0.0.1:8081');

      if (gps.data != null && gps.data.lat != null) {
        this.setLocation(gps.data);
        return true;
      }
      else {
        this.props.showNotice('Ulkoiseen GPS ei saatu yhteyttä', 'Warning');
      }
    } catch (error) {
      this.props.showNotice('Ulkoiseen GPS ei saatu yhteyttä', 'Warning');
    }

    return false;
  }

  toggleTemplateOrdering () {
    if (!this.state.orderTemplates) {
      this.props.showNotice('Valittu siirtyy ensimmäiseksi', 'Ok');
    }
    else {
      this.props.showNotice('Järjestäminen lopetettu', 'Ok');
    }

    this.setState({ orderTemplates: !this.state.orderTemplates });
  }

  toggleHideObservation (id) {
    this.props.toggleHideObservation(id);
  }

  update () {
    this.forceUpdate();
  }

  toggleFullscreen () {
    const element = document.documentElement;

    if (!this.state.fullscreen) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) { /* Firefox */
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) { /* IE/Edge */
        element.msRequestFullscreen();
      }
    }
    else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
      }
    }
  }

  fullscreenEvent () {
    if (document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement) {
      this.setState({ fullscreen: true });
    }
    else {
      this.setState({ fullscreen: false });
    }
  }

  handleMouseDown (event) {
    if (this.state.touchLock) {
      this.xDown = event.clientX;
      this.yDown = event.clientY;
    }
  }

  handleMouseMove (event) {
    if (!this.xDown || !this.yDown) {
      return;
    }

    const x = event.clientX;
    const y = event.clientY

    this.move(x, y, 5);
  }

  getTouches (event) {
    return event.touches;
  }

  handleTouchStart (event) {
    const firstTouch = this.getTouches(event)[0];
    this.xDown = firstTouch.clientX;
    this.yDown = firstTouch.clientY;
  }

  handleTouchMove (event) {
    if (!this.xDown || !this.yDown) {
      return;
    }

    const x = event.touches[0].clientX;
    const y = event.touches[0].clientY

    this.move(x, y, 18);
  }

  move (x, y, sensity) {
    const xDiff = this.xDown - x;
    const yDiff = this.yDown - y;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > sensity) {
        this.setState({ touchLock: false });
      }
    }

    this.xDown = null;
    this.yDown = null;
  }

  async toggleNeviLocatorGPS () {
    let value = !this.state.neviLocatorGPS;

    this.setState({
      loadingNeviLocatorGPS: true
    });

    if (value) {
      if (!(await this.testNeviLocator())) {
        value = false;
        this.props.showNotice('NeviLocator sijaintia ei saatu', 'Warning');
      }
      else {
        this.externalGPSTimer = setInterval(this.getNeviLocatorCoordinates, 2000);
      }
    }
    else {
      clearInterval(this.externalGPSTimer);
    }

    this.setState({
      neviLocatorGPS: value,
      loadingNeviLocatorGPS: false
    });
    localStorage.neviLocatorGPS = value
  }

  async testNeviLocator () {
    try {
      let neviLocatorCoordinates = (await fetch(
        `query {
          GPSLocationByVehicle(vehicle_id:"` + this.props.user.get('userId') + `"){
            id,latitude,longitude,time,vehicle_id,accuracy
          }
        }`)).data.GPSLocationByVehicle;

      let now = new Date();
      now.setHours(now.getHours());

      let past12HoursFromNow = new Date(now);
      past12HoursFromNow.setHours(past12HoursFromNow.getHours() - 12);

      let latestTime = new Date(neviLocatorCoordinates[neviLocatorCoordinates.length - 1].time);

      if (latestTime <= past12HoursFromNow) {
        await fetch(
          `mutation {
            GPSLocationDeleteByVehicle(vehicle_id:"` + this.props.user.get('userId') + `"){
              success, deletedCount
            }
          }`);
        return false;
      }

      if (!neviLocatorCoordinates.length) {
        return false;
      }

      return true;
    } catch (error) {
      return false;
    };
  }

  async getNeviLocatorCoordinates () {
    if (this.state.gettingNeviLocatorCoordinates) {
      return;
    }

    let neviLocatorCoordinates = [];

    try {
      this.setState({ gettingNeviLocatorCoordinates: true });
      neviLocatorCoordinates = (await fetch(
        `query {
          GPSLocationByVehicle(vehicle_id:"` + this.props.user.get('userId') + `"){
            id,latitude,longitude,time,vehicle_id,accuracy
          }
        }`)).data.GPSLocationByVehicle;

      await fetch(
        `mutation {
          GPSLocationDeleteByVehicle(vehicle_id:"` + this.props.user.get('userId') + `"){
            success, deletedCount
          }
        }`);

      if (!neviLocatorCoordinates.length) {
        this.setState({ gettingNeviLocatorCoordinates: false });
        return false;
      }

      if (this.state.runningObservations.length) {
        for (let index in neviLocatorCoordinates) {
          await this.setLocation(neviLocatorCoordinates[index]);
        }
      }
      else {
        await this.setLocation(neviLocatorCoordinates[neviLocatorCoordinates.length - 1]);
      }
    } catch (error) {
      this.setState({ gettingNeviLocatorCoordinates: false });
      return false;
    };

    this.setState({
      neviLocatorCoordinates: neviLocatorCoordinates,
      gettingNeviLocatorCoordinates: false
    });
    return true;
  }

  createObservationFromCoordinateFile () {
    if (this.state.selectedInputCoordinatesTemplate == null) {
      this.props.showNotice('Valitse Havaintopohja', 'Warning');
      return;
    }

    const reader = new FileReader();

    reader.onload = async function (e) {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheet = workbook.Sheets.Sheet1;

      let coordinates = [];
      let latitude = null;

      for (let row in sheet) {
        const data = sheet[row];

        if (data.w == null) {
          continue;
        }

        if (latitude != null) {
          coordinates.push({ latitude: latitude, longitude: data.v });
          latitude = null
        }
        else {
          latitude = data.v;
        }
      }

      let path = '[';
      let distance = 0;
      let lastPoint;

      for (let order in coordinates) {
        let point = coordinates[order];
        point.order = order;
        path += '{latitude:' + point.latitude + ', longitude:' + point.longitude;

        path += ', order:' + point.order + '}, ';

        if (lastPoint != null) {
          distance += calculateDistance(point.latitude, point.longitude,
            lastPoint.latitude, lastPoint.longitude);
        }

        lastPoint = point;
      }

      path += ']';

      const template = this.props.templates.find(template => template.get('id') ===
        this.state.selectedInputCoordinatesTemplate);

      const date = new Date();

      let observation = {
        id: Date.now(),
        report: { id: this.state.selectedReport },
        time: date.toISOString(),
        order: this.props.observations.size + this.state.runningObservations.length + 1,
        template: template.get('id'),
        distance: distance
      }

      try {
        const response = await fetch(`mutation NewObservation {
                                        observationCreate(observation: {
                                              time:` + JSON.stringify(observation.time) +
          ` report: {id: ` + JSON.stringify(observation.report.id) + `}` +
          ` roadData:` + JSON.stringify(observation.roadData || false) +
          ` path:` + path +
          ` latitude:` + coordinates[0].latitude +
          ` longitude:` + coordinates[0].longitude +
          ` order:` + JSON.stringify(observation.order) +
          ` template:` + JSON.stringify(observation.template) +
          ` distance:` + JSON.stringify(observation.distance) +
          `
                                        }) {
                                          id, time, videos {file, name, field {name}}, photos {file, name, field {name}},
                                          report { id }, roadNumber, roadPart, roadDistance,
                                          path { roadNumber, roadPart, roadDistance, latitude, longitude, order },
                                          latitude, longitude, order, fields, roadData, template, distance
                                        }
                                    }`);

        const data = response.data;

        if (data != null) {
          const newObservation = data.observationCreate;
          newObservation.fields = JSON.parse(newObservation.fields);

          if (newObservation.path) {
            newObservation.path.sort((a, b) => {
              return a.order - b.order
            });
          }

          this.props.addObservation(newObservation);
          this.props.showNotice('Havainto luotu', 'Ok');
          this.setState({
            inputCoordinates: false,
            selectedInputCoordinatesFile: null,
            selectedInputCoordinatesTemplate: null
          });
        }
      } catch (error) {

      }
    }.bind(this);

    reader.readAsArrayBuffer(this.state.selectedInputCoordinatesFile);
  }

  async getRoadDataAgain () {
    if (this.state.selectedReport == null) {
      this.props.showNotice('Valitse raportti', 'Warning')
    }

    this.setState({ loadingObservations: true });

    for (let observation of this.props.observations) {
      await this.changeObservationLocation(observation.get('id'),
        { latitude: observation.get('latitude'), longitude: observation.get('longitude') });
    }

    this.setState({ loadingObservations: false });
  }

  targetMarkSet = (mark) => {
    this.setState({ targetMark: mark });
  };

  setMap = (map) => {
    this.map = map;
  };

  render () {
    if (this.state.redirectToEdit) {
      return <Navigate to='/edit' push />;
    }
    if (this.state.redirectToView) {
      return <Navigate to={'/report/' + this.state.redirectToView} push />;
    }

    const filterOn = this.state.setFilterTemplates.length !== 0 ||
      this.state.setFilterStartTime != null;

    return (
      <div>
        <BasicInfo reports={this.props.reports} onChange={this.changeState}
          selectedReport={this.state.selectedReport}
          reportName={this.state.reportName}
          readOnly={this.readOnly} />
        <div id='top'>
          <ExtraMenu items={this.extraMenuItems}
            functions={this.extraMenuFunctions} />
          <CurrentLocation roadNumber={this.state.roadNumber} roadPart={this.state.roadPart}
            roadDistance={this.state.roadDistance} loading={this.state.loadingLocation}
            accuracy={this.state.accuracy} time={this.state.locationTime}
            address={this.state.address} city={this.state.city}
            setLockedRoad={this.state.setLockedRoad}
            notShow={this.editOnly || this.readOnly}
            profile={this.state.profile}
            profiles={this.state.profiles}
            map={this.map}
            targetMark={this.state.targetMark} />
          {
            <div>
              {this.state.selectedReport != null ?
                <div>
                  <NewObservation selectedReport={this.state.selectedReport}
                    reports={this.props.reports}
                    clearSelectedTemplate={this.clearSelectedTemplate}
                    showNewObservation={this.state.showNewObservation}
                    makeObservation={this.makeObservation}
                    toggleNewObservation={this.toggleNewObservation}
                    selectedObservationType={this.state.selectedObservationType}
                    templates={this.props.templates} onChange={this.changeState}
                    selectedTemplate={this.state.selectedTemplate}
                    selectTemplate={this.selectTemplate}
                    selectObservationType={this.selectObservationType}
                    state={this.state} notShow={this.editOnly || this.readOnly}
                    runningObservations={this.state.runningObservations}
                    profile={this.state.profile}
                    endObservation={this.endObservation}
                    toggleTemplateOrdering={this.toggleTemplateOrdering}
                    orderTemplates={this.state.orderTemplates}
                    templateOrder={this.state.templateOrder}
                    creatingObservation={this.state.creatingObservation} />
                  {this.state.showNewObservation ?
                    null
                    :
                    <Observations observations={this.props.observations}
                      runningObservations={this.state.runningObservations}
                      showObservations={this.state.showObservations}
                      toggleObservations={this.toggleObservations}
                      goChangeObservation={this.goChangeObservation}
                      endObservation={this.endObservation}
                      confirmRemoveObservation={this.confirmRemoveObservation}
                      hoverObservation={this.hoverObservation}
                      selectObservation={this.selectObservation}
                      hoveringObservation={this.state.hoveringObservation}
                      templates={this.props.templates}
                      editOnly={this.editOnly}
                      readOnly={this.readOnly}
                      toggleHideObservation={this.toggleHideObservation} />
                  }
                </div>
                :
                <button id='observation-info' onClick={this.toggleCreateNewReport}>
                  Luo uusi raportti
                </button>
              }
              {this.state.showNewObservation ?
                null
                :
                <div>
                  <ObservationsInfo selectedReport={this.state.selectedReport}
                    observations={this.props.observations}
                    runningObservations={this.state.runningObservations}
                    endObservation={this.endObservation}
                    templates={this.props.templates}
                    notShow={this.readOnly}
                    filterOn={filterOn} />
                </div>
              }
            </div>
          }
        </div>
        <MapView observations={this.props.observations}
          runningObservations={this.state.runningObservations}
          yourLatitude={this.state.latitude}
          yourLongitude={this.state.longitude}
          yourroadNumber={this.state.roadNumber}
          yourroadPart={this.state.roadPart}
          yourRoadDistance={this.state.roadDistance}
          goChangeObservation={this.goChangeObservation}
          changeObservationLocation={this.changeObservationLocation}
          confirmRemoveObservation={this.confirmRemoveObservation}
          endObservation={this.endObservation}
          goToObservation={this.state.goToObservation}
          templates={this.props.templates}
          showMakeMark={this.state.showMakeMark}
          toggleShowMakeMark={this.toggleShowMakeMark}
          showFindRoadInfo={this.state.showFindRoadInfo}
          toggleShowFindRoadInfo={this.toggleShowFindRoadInfo}
          showNotice={this.props.showNotice}
          noOwnLocation={this.readOnly}
          areaBound={this.state.areaBound}
          readOnly={this.readOnly}
          targetMarkSet={this.targetMarkSet}
          setMap={this.setMap}
          drawingPdf={this.state.loadingPdf}
          viewObservation={this.viewObservation}
          selectLocation={this.state.selectLocation}
          makeObservation={this.makeObservation}
          setDistanceToMarker={this.setDistanceToMarker}
          setRoadDistanceToMarker={this.setRoadDistanceToMarker}
          distanceToMarker={this.state.distanceToMarker}
          roadDistanceToMarker={this.state.roadDistanceToMarker}
          targetMarkerLatitude={this.state.targetMarkerLatitude}
          targetMarkerLongitude={this.state.targetMarkerLongitude}
          lockedRoadPath={this.state.lockedRoadPath}
          showNewObservation={this.state.showNewObservation}
          update={this.update}
          showConfirm={this.props.showConfirm}
          showMessage={this.props.showMessage} />
        <ChangeObservation observation={this.state.changingObservation}
          changeObservation={this.changeObservation}
          templates={this.props.templates}
          clear={this.clearChangeObservation} />
        <SelectAreaObservationType show={this.state.setAreaObservation}
          toggle={this.toggleSetAreaObservation}
          select={this.createAreaObservation} />
        <Excel show={this.state.makeExcel} toggle={this.toggleMakeExcel}
          name={this.state.reportName + this.state.extraTag}
          headers={this.state.excelHeaders}
          dataHeaders={this.state.excelDataHeaders}
          timeField={'time'}
          data={this.state.reportObservations} />
        <PdfLoader show={this.state.loadingPdf} />
        <ViewObservation observation={this.state.viewObservation}
          close={this.resetViewObservation}
          templates={this.props.templates} />
        <ChangeProfile profile={this.state.profile}
          profiles={this.state.profiles}
          show={this.state.showChangeProfile}
          toggle={this.toggleShowChangeProfile}
          onChange={this.changeState} />
        <FilterObservations show={this.state.showFilterObservations}
          toggle={this.toggleFilterObservations}
          templates={this.props.templates}
          selectedFilterTemplates={this.state.selectedFilterTemplates}
          addFilterTemplate={this.addFilterTemplate}
          removeFilterTemplate={this.removeFilterTemplate}
          onChange={this.changeState}
          filterStartTime={this.state.filterStartTime}
          filterEndTime={this.state.filterEndTime}
          filter={this.filterObservations}
          clearfilters={this.clearFilterObservations}
          templateOrder={this.state.templateOrder} />
        <LockToRoad lockedRoad={this.state.lockedRoad}
          show={this.state.showLockToRoad}
          toggle={this.toggleLockToRoad}
          set={this.setLockedRoad}
          onChange={this.changeState} />
        <NameTheNewReport newReportName={this.state.newReportName}
          show={this.state.showCreateNewReport}
          toggle={this.toggleCreateNewReport}
          create={this.createNewReport}
          onChange={this.changeState}
          loading={this.state.creatingNewReport} />
        <Settings show={this.state.showSettings}
          toggle={this.toggleSettings}
          toggleExternalGPS={this.toggleExternalGPS}
          externalGPS={this.state.externalGPS}
          loadingExternalGPS={this.state.loadingExternalGPS}
          onChange={this.changeState}
          useRoadData={this.state.useRoadData}
          toggleFullscreen={this.toggleFullscreen}
          fullscreen={this.state.fullscreen}
          touchLock={this.state.touchLock}
          toggleNeviLocatorGPS={this.toggleNeviLocatorGPS}
          neviLocatorGPS={this.state.neviLocatorGPS}
          loadingNeviLocatorGPS={this.state.loadingNeviLocatorGPS} />
        <TouchLock show={this.state.touchLock} />
        <InputCoordinates show={this.state.inputCoordinates}
          templates={this.props.templates}
          selectedInputCoordinatesFile={this.state.selectedInputCoordinatesFile}
          selectedInputCoordinatesTemplate={this.state.selectedInputCoordinatesTemplate}
          onChange={this.changeState}
          createObservation={this.createObservationFromCoordinateFile} />
        {this.state.loadingObservations ? <div className='main loader' /> : null}
      </div>
    );
  }
}

export default connect(state => ({
  observations: state.observation.get('observations'),
  reports: state.report.get('reports'),
  templates: state.report.get('templates'),
  user: state.login.get('user'),
}), {
  addObservation, showNotice, showConfirm, showMessage, addObservations,
  addReports, addReport, removeObservation, changeObservation, changeReport,
  clearObservation, removeReport, addTemplates, changeObservationOrder,
  toggleHideObservation, addTemplate, changeTemplate, removeTemplate
})(Observation);
