export const addObservations = observations => ({
  type: 'ADD_OBSERVATIONS',
  observations
});


export const removeObservation = observation => ({
  type: 'REMOVE_OBSERVATION',
  observation
});


export const addObservation = observation => ({
  type: 'ADD_OBSERVATION',
  observation
});


export const changeObservation = observation => ({
  type: 'CHANGE_OBSERVATION',
  observation
});

export const clearObservation = () => ({
  type: 'CLEAR_OBSERVATION'
});

export const changeObservationOrder = (observationId, order) => ({
  type: 'CHANGE_OBSERVATION_ORDER',
  observationId,
  order
});

export const toggleHideObservation = observationId => ({
  type: 'TOGGLE_HIDE_OBSERVATION',
  observationId
});
