let config = {
  api: 'i0noysekw1.execute-api.eu-west-1.amazonaws.com/v1',
  //api: '52dfffj6il.execute-api.eu-west-1.amazonaws.com/Teststage',
  socket: 'socket.nevia-app.com:9000',
  //socket: 'test.ec2.nevia-app.com:9000',
  road: 'avoinapi.vaylapilvi.fi/viitekehysmuunnin/muunna',
  road2: 'avoinapi.vaylapilvi.fi/viitekehysmuunnin',
  cors: 'socket.nevia-app.com:1212',
}

export default config;
