import React, { useState, useEffect, useMemo } from 'react';
import { fromJS, setIn } from 'immutable';
import L from 'leaflet';
import { useMap, MapContainer, Marker, Popup, TileLayer, Circle, CircleMarker, Rectangle, Polyline, useMapEvent } from 'react-leaflet';
import MapTiles from '../../../../src/mapTiles/MapTiles.js';
import AntPath from './AntPath.js';
import { toETRSTM35FIN, toWGS84, calculateOffsetCoordinates,
         calculateDistance, calculateRoadDistance, stateValueParser, ROAD_URL2,
         getRoadData, getRoadCoordinates } from '../utils.js';
import 'leaflet/dist/leaflet.css';         

const paddedNumber = number => number <= 99 ? ('0'+number).slice(-2) : number;
let fileInputs = {};

export const OBSERVATION_COLORS = [
  '#07548c', '#a2c741', '#8000ff', '#fa8000', '#ffff00', 
  '#00ff00', '#ff0000', '#0000ff', '#ff00ff', '#646464', 
  '#32ff64', '#000000'
];

export const DropDownSelect = props => (
  <div>
    <label htmlFor={props.name}>{props.name}</label>
    <select id={props.name}
            onChange={props.onChange.bind(this, props.name, 'string', props.default)}
            value={props.value}>
      {
        props.values.sort().map(value => (
          <option key={value} value={value}>
            {value}
          </option>
        ))
      }
    </select>
  </div>
);

export const RadioSelect = props => (
  <div>
    <label>{props.name}</label>
    {
      props.values.map(value => (
        <label key={value} className='radio'>
          {value}
          <input type='radio' name={props.name} value={value}
            onClick={props.onChange.bind(this, props.name, 'string', props.default)}
            defaultChecked={props.value === value}/>
        </label>
      ))
    }
  </div>
);

export const CheckboxSelect = props => (
  <label className='checkbox-container'>
    {props.name}
    <input type='checkbox'
           onClick={props.onChange.bind(this, props.name, 'boolean', false)}
           defaultChecked={props.value} />
      <span className="checkmark" />
  </label>
);

export const TextField = props => {
  let type = 'text';

  if (props.type === 'float') {
    type = 'tel';
  }
  else if (props.type === 'integer') {
    type = 'number';
  }

  return (
    <div>
      <label htmlFor={props.name}>{props.name}</label>
      <input id={props.name} type={type}
            onChange={props.onChange.bind(this, props.name, props.type, props.default)}
            value={props.value || ''} />
    </div>
  );
}

export const DateField = props => {
  return (
    <div>
      <label htmlFor={props.name}>{props.name}</label>
      <input id={props.name} type='datetime-local'
             onChange={props.onChange.bind(this, props.name, props.type, props.default)}
             value={props.value || ''} />
    </div>
  );
}

export const File = props => {
  return (
    <label>
      {props.name}
      <input ref={element => fileInputs[props.name] = element} type='file' multiple accept={props.type !== 'file' ? props.type + '/*' : ''}
            onChange={props.onChange.bind(this, props.name, 'file', null)} />
    </label>
  );
}

export const TemplateForm = props => {
  if (props.template == null) return null;

  const data = props.template.get('fields');
  let fields = [];

  data.forEach(part => {
    let field = null;
    let extra = [];

    if (part.get('type') === 'text' || part.get('type') === 'address'
        || part.get('type') === 'float' || part.get('type') === 'integer') {
      field = <TextField name={part.get('name')}
                         onChange={props.onChange}
                         value={props.state[part.get('name')]}
                         type={part.get('type')}/>
    }
    else if (part.get('type') === 'date') {
      field = <DateField name={part.get('name')}
                         onChange={props.onChange}
                         value={props.state[part.get('name')]} />
    }
    else if (part.get('type') === 'checkbox') {
      field = <CheckboxSelect name={part.get('name')}
                              onChange={props.onChange}
                              value={props.state[part.get('name')]}/>
    }
    else if (part.get('type') === 'radio') {
      field = <RadioSelect name={part.get('name')}
                            onChange={props.onChange}
                            value={props.state[part.get('name')]}
                            values={part.get('values')}/>
    }
    else if (part.get('type') === 'dropdown') {
      field = <DropDownSelect name={part.get('name')}
                              onChange={props.onChange}
                              value={props.state[part.get('name')]}
                              values={part.get('values')}/>
    }
    else if (part.get('type') === 'image' ||
             part.get('type') === 'video' ||
             part.get('type') === 'audio' ||
             part.get('type') === 'file') {
      field = <File name={part.get('name')}
                    type={part.get('type')}
                    onChange={props.onChange}
                    value={props.state[part.get('name')]}/>

      if (props.state[part.get('name')] != null && props.state[part.get('name')].length !== 0) {
        extra.push(<button key={part.get('name') + '-clear'} className='button-outline observation-image-clear-button'
                           onClick={props.clearFiles.bind(null, part.get('name'))}>
                      Tyhjää
                    </button>);
        if (part.get('type') === 'image') {
          props.state[part.get('name')].forEach((image, index) => {
            extra.push(<img key={index} className='observation-image-edit'
                            alt={'Kuva ' + index} src={image['file'] || image.get('file')} />);
          });
        }
        else if (part.get('type') === 'video') {
          props.state[part.get('name')].forEach((video, index) => {
            const file = video['file'] || video.get('file');
            extra.push(<video key={index} controls className='observation-image-edit'>
                        <source src={file} type={file.substring(5, file.indexOf(';'))}/>
                        Your browser does not support the video tag.
                       </video>);
          });
        }
        else if (part.get('type') === 'audio') {
          props.state[part.get('name')].forEach((audio, index) => {
            const file = audio['file'] || audio.get('file');
            extra.push(<audio  key={index} controls className='observation-image-edit'>
                        <source src={file} type={file.substring(5, file.indexOf(';'))}/>
                        Your browser does not support the audio element.
                       </audio >);
          });
        }
        else {
          props.state[part.get('name')].forEach((audio, index) => {
            const name = audio['name'] || audio.get('name');
            extra.push(<span key={index}>
                        {name}
                       </span>);
            extra.push(<br/>);
          });
        }
      }
    }

    fields.push(
      <div key={part.get('name')} className='template-field'>
        {field}
        {extra}
      </div>
    );
  });

  return (
    <div>
      {fields}
    </div>
  );
}

export const ChangeProfile = props => {
  if (!props.show) return null;

  return (
    <div onClick={props.toggle} className='modal'>
      <div onClick={e => e.stopPropagation()} id='observation-modal'>
        <div className='close' onClick={props.toggle}/>
        <h3>Valitse profiili</h3>
        <select onChange={props.onChange.bind(this, 'profile', 'integer', null)}
                value={props.profile || ''}>
          <option value={''}>
            Ei profiilia
          </option>
          {
            props.profiles.map(profile => (
              <option key={profile.id} value={profile.id}>
                {profile.name}
              </option>
            ))
          }
        </select>
        <br/>
        <button onClick={props.toggle}>
          Ok
        </button>
      </div>
    </div>
  );
};

export const LockToRoad = props => {
  if (!props.show) return null;

  return (
    <div onClick={props.toggle} className='modal'>
      <div onClick={e => e.stopPropagation()} id='lock-to-road-modal'>
        <div className='close' onClick={props.toggle}/>
        <h4>Lukitse tiettyyn tiehen</h4>
        <label htmlFor='roadNumber'>Tienumero</label>
        <input id='roadNumber' type='number'
               onChange={props.onChange.bind(this, 'lockedRoad', 'integer', '')}
               value={props.lockedRoad || ''} />
        <br/>
        <button onClick={props.set}>
          Lukitse
        </button>
      </div>
    </div>
  );
};

const MakeMark = props => {
  if (!props.show) return null;

  return (
    <div onClick={props.toggle} className='modal'>
      <div onClick={e => e.stopPropagation()} id='observation-modal'>
        <div className='close' onClick={props.toggle}/>
        <h4>Luo merkintä</h4>
        <div className='row'>
          <div className='column'>
            <label htmlFor='newMarkName'>Nimi</label>
            <input id='newMarkName' type='text'
                  onChange={props.onChange.bind(this, 'newMarkName', 'string', 0)}
                  value={props.newMarkName}/>
          </div>
          <div className='column'>
            <label htmlFor='newMarkRoadNumber'>Tienumero</label>
            <input id='newMarkRoadNumber' type='tel'
                  onChange={props.onChange.bind(this, 'newMarkRoadNumber', 'integer', 0)}
                  value={props.newMarkRoadNumber}/>
          </div>
          <div className='column'>
            <label htmlFor='newMarkRoadPart'>Tieosa</label>
            <input id='newMakrRoadPart' type='tel'
                  onChange={props.onChange.bind(this, 'newMarkRoadPart', 'integer', 0)}
                  value={props.newMarkRoadPart}/>
          </div>
          <div className='column'>
            <label htmlFor='newMarkRoadDistance'>Paalu</label>
            <input id='newMakrRoadDistance' type='tel'
                  onChange={props.onChange.bind(this, 'newMarkRoadDistance', 'integer', 0)}
                  value={props.newMarkRoadDistance}/>
          </div>
        </div>
        <button onClick={props.makeNewMark}>
          Luo merkki
        </button>
      </div>
    </div>
  );
};

const FindRoadInfo = props => {
  if (!props.show) return null;

  return (
    <div onClick={props.toggle} className='modal'>
      <div onClick={e => e.stopPropagation()} id='observation-modal'>
        <div className='close' onClick={props.toggle}/>
        <h4>Etsi tierekisteri</h4>
        <div className='row'>
          <div className='column'>
            <label htmlFor='findRoadNumber'>Tienumero</label>
            <input id='findRoadNumber' type='tel'
                  onChange={props.onChange.bind(this, 'findRoadNumber', 'integer', 0)}
                  value={props.findRoadNumber || ''}/>
          </div>
          <div className='column'>
            <label htmlFor='findRoadPart'>Tieosa</label>
            <input id='findRoadPart' type='tel'
                  onChange={props.onChange.bind(this, 'findRoadPart', 'integer', 0)}
                  value={props.findRoadPart || ''}/>
          </div>
          <div className='column'>
            <label htmlFor='findRoadDistance'>Paalu</label>
            <input id='findRoadDistance' type='tel'
                  onChange={props.onChange.bind(this, 'findRoadDistance', 'integer', 0)}
                  value={props.findRoadDistance}/>
          </div>
        </div>
        <button onClick={props.findRoadInfo}>
          Etsi
        </button>
      </div>
    </div>
  );
};

const AreaData = props => {
  if (!props.data) return null;

  if (props.data.get('type') === 1) {
    return (
      <div>
        <h5>Alueen asetukset</h5>
        <div className='template-field'>
          <label htmlFor='radius'>Säde (m)</label>
          <input id='radius' type='number'
                onChange={props.onChange.bind(this, 'areaRadius', 'float', '')}
                value={props.state.areaRadius || ''}/>
        </div>
      </div>
    );
  }
  else {
    return (
      <div>
        <h5>Alueen asetukset</h5>
        <div>
          <div className='template-field'>
            <label htmlFor='radius'>Kanta (m)</label>
            <input id='radius' type='number'
                  onChange={props.onChange.bind(this, 'areaWidth', 'float', '')}
                  value={props.state.areaWidth || ''}/>
          </div>
          <div className='template-field'>
            <label htmlFor='height'>Korkeus (m)</label>
            <input id='height' type='number'
                  onChange={props.onChange.bind(this, 'areaHeight', 'float', '')}
                  value={props.state.areaHeight || ''}/>
          </div>
        </div>
      </div>
    );
  }
};

export const NameTheNewReport = props => {
  if (!props.show) return null;

  return (
    <div onClick={props.toggle} className='modal'>
      <div onClick={e => e.stopPropagation()} id='lock-to-road-modal'>
        <div className='close' onClick={props.toggle}/>
        <h4>Luo uusi raportti</h4>
        <label htmlFor='name'>Nimi</label>
        <input id='name'
               onChange={props.onChange.bind(this, 'newReportName', 'string', '')}
               value={props.newReportName || ''} />
        <br/>
        { props.loading ?
          <div className='loader'/>
          :
          <button onClick={props.create}>
            Luo
          </button>
        }
      </div>
    </div>
  );
};

export const Settings = props => {
  if (!props.show) return null;

  return (
    <div onClick={props.toggle} className='modal'>
      <div onClick={e => e.stopPropagation()} id='lock-to-road-modal'>
        <div className='close' onClick={props.toggle}/>
        <h4>Asetukset</h4>
        <label htmlFor='neviLocatorGPS' className='checkbox-container'>
          Käytä NeviLocator GPS
          <input id='neviLocatorGPS'
                 type='checkbox'
                 onChange={props.toggleNeviLocatorGPS}
                 checked={props.neviLocatorGPS}
                 disabled={props.loadingNeviLocatorGPS || props.externalGPS} />
          <span className='checkmark'/>
        </label>
        <label htmlFor='externalGPS' className='checkbox-container'>
          Käytä ulkoista GPS
          <input id='externalGPS'
                 type='checkbox'
                 onChange={props.toggleExternalGPS}
                 checked={props.externalGPS}
                 disabled={props.loadingExternalGPS || props.neviLocatorGPS} />
          <span className='checkmark'/>
        </label>
        <label htmlFor='roadData' className='checkbox-container'>
          Käytä tierekisteriä
          <input id='roadData'
                 type='checkbox'
                 onChange={props.onChange.bind(this, 'useRoadData', 'boolean', true)}
                 checked={props.useRoadData} />
          <span className='checkmark'/>
        </label>
        <label htmlFor='fullscreen' className='checkbox-container'>
          Koko näyttö tila
          <input id='fullscreen'
                 type='checkbox'
                 onChange={props.toggleFullscreen}
                 checked={props.fullscreen} />
            <span className='checkmark'/>
        </label>
        <label htmlFor='touchLock' className='checkbox-container'>
          Kosketuslukko
          <input id='touchLock'
                 type='checkbox'
                 onChange={props.onChange.bind(this, 'touchLock', 'boolean', true)}
                 checked={props.touchLock} />
            <span className='checkmark'/>
        </label>
        <br/>
        <button onClick={props.toggle}>
          Ok
        </button>
      </div>
    </div>
  );
};

export const TouchLock = props => {
  if (!props.show) return null;

  return (
    <div className='modal touch-lock'>
      <div id='touch-lock-text'>
        Näyttö lukittu
        <br/>
        Vedä oikealta vasemmalle avatakseen
      </div>
    </div>
  );
};

export const InputCoordinates = props => {
  if (!props.show) return null;

  return (
    <div onClick={props.toggle} className='modal'>
      <div onClick={e => e.stopPropagation()} id='observation-modal'>
        <div className='close' onClick={props.toggle}/>
        <h4>Luo havainto koordinaatti tiedostosta</h4>
        <label>
          {'Koordinaatisto tiedosto'}
          <input type='file' accept={''}
                onChange={props.onChange.bind(this, 'selectedInputCoordinatesFile', 'file', null)} />
        </label>
        <label htmlFor={'Havaintopohja'}>Havaintopohja</label>
        <select id={'Havaintopohja'}
                onChange={props.onChange.bind(this, 'selectedInputCoordinatesTemplate', 'integer', null)}
                value={props.value}>
          <option value={''}>
            Valitse havaintopohja
          </option>
          {
            props.templates.sort().map(template => (
              <option key={template.get('id')} value={template.get('id')}>
                {template.get('name')}
              </option>
            ))
          }
        </select>
        <button onClick={props.createObservation}>
          Luo havainto
        </button>
      </div>
    </div>
  );
};

export class ChangeObservation extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      template: null,
      loading: false
    };

    this.changeState = this.changeState.bind(this);
    this.doChangeObservation = this.doChangeObservation.bind(this);
    this.clearFiles = this.clearFiles.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.observation != null && this.props.observation !== nextProps.observation) {
      const observation = nextProps.observation;
      const template = this.props.templates.find(template => template.get('id') === observation.get('template'));

      template.get('fields').forEach(field => {
        const key = field.get('name');
        const value = observation.get('fields').get(key);
        const fieldType = field.get('type');
        if (fieldType === 'image' || fieldType === 'video' || fieldType === 'audio') {
          if (value === '-') {
            this.setState({ [key]: [] });
          }
          else {
            let array = [];

            for (let i = 0; i < value.length; i++) {
             array.push({name: value[i].name, file: value[i].file});
            }

            this.setState({ [key]: array });
          }
        }
        else if (value === '-') {
          this.setState({ [key]: null });
        }
        else {
          this.setState({ [key]: value || null });
        }
      });

      if (observation.get('area')) {
        if (observation.get('area').get('type') === 1) {
          this.setState({
            areaRadius: observation.get('area').get('radius'),
          });
        }
        else {
          this.setState({
            areaWidth: observation.get('area').get('width'),
            areaHeight: observation.get('area').get('height'),
            oldAreaWidth: observation.get('area').get('width'),
            oldAreaHeight: observation.get('area').get('height')
          });
        }
      }

      this.setState({ template: template });
    }
  }

  changeState(propertyName, type, defaultValue, event) {
    if (type === 'file') {
      const files = event.target.files;

      for (let i = 0; i < files.length; i++) {
        let reader = new FileReader();

        reader.onloadend = () => {
          this.setState({
            [propertyName]: this.state[propertyName].concat({
                              name: files[i].name,
                              file: reader.result
                            })
          });
        }

        reader.readAsDataURL(files[i]);
      }

      return;
    }
    else {
      const value = stateValueParser(event, type, defaultValue);

      if (value == null) {
        return;
      }

      this.setState({ [propertyName]: value });
    }
  }

  async doChangeObservation() {
    this.setState({ loading: true });

    let fields = {};

    this.state.template.get('fields').forEach(field => {
      const data = this.state[field.get('name')];

      if (Array.isArray(data)) {
        if (data.length === 0) {
          fields[field.get('name')] = '-';
        }
        else {
          fields[field.get('name')] = data;
        }
      }
      else if (data != null) {
        fields[field.get('name')] = data;
      }
      else {
        fields[field.get('name')] = '-';
      }
    });

    let observation = {};
    observation.id = this.props.observation.get('id');
    observation.report = this.props.observation.get('report');
    observation.template = this.props.observation.get('template');
    observation.time = this.props.observation.get('time');
    observation.order = this.props.observation.get('order');
    observation.distance = this.props.observation.get('distance');
    observation.latitude = this.props.observation.get('latitude');
    observation.longitude = this.props.observation.get('longitude');
    observation.roadData = this.props.observation.get('roadData');
    observation.roadNumber = this.props.observation.get('roadNumber');
    observation.roadPart = this.props.observation.get('roadPart');
    observation.roadDistance = this.props.observation.get('roadDistance');
    observation.path = this.props.observation.get('path');
    observation.fields = fields;

    if (this.props.observation.get('notSaved')) {
      observation.notSaved = true;
    }

    if (this.props.observation.get('area')) {
      observation.area = {position: {}};
      if (this.props.observation.get('area').get('type') === 1) {
        const currentCoordinates = this.props.observation.get('area').get('position'); 
        observation.area.position.lat = currentCoordinates.get('lat');
        observation.area.position.lng = currentCoordinates.get('lng');
        observation.area.radius = parseFloat(this.state.areaRadius);
        observation.area.type = 1;
      }
      else {
        const width = parseFloat(this.state.areaWidth);
        const height = parseFloat(this.state.areaHeight);
        const offsetWidth = width - this.state.oldAreaWidth;
        const offsetHeight = height - this.state.oldAreaHeight;
        const currentCoordinates = this.props.observation.get('area').get('position');
        const coordinates = calculateOffsetCoordinates(currentCoordinates.get(0).get(0), currentCoordinates.get(0).get(1),
                                                       -offsetWidth / 2, -offsetHeight / 2);
        const otherSideCoordinates = calculateOffsetCoordinates(currentCoordinates.get(1).get(0), currentCoordinates.get(1).get(1),
                                                                offsetWidth / 2, offsetHeight / 2);
        observation.area.position =[[coordinates.latitude, coordinates.longitude],
                                    [otherSideCoordinates.latitude, otherSideCoordinates.longitude]];
        observation.area.width = width;
        observation.area.height = height;
        observation.area.type = 2;
      }
    }

    await this.props.changeObservation(observation);
    this.setState({ loading: false });
  }

  clearFiles(state) {
    this.setState({ [state]: [] });

    fileInputs[state].value = '';
  }

  render() {
    if (this.props.observation == null) return null;

    return (
      <div onClick={this.props.clear} className='modal'>
        <div onClick={e => e.stopPropagation()} id='observation-modal'>
          <div className='close' onClick={this.props.clear}/>
          <h3 className='center'>{this.props.observation.get('order')}. Havainto</h3>
          <TemplateForm template={this.state.template} onChange={this.changeState}
                        state={this.state} clearFiles={this.clearFiles}/>
          <AreaData data={this.props.observation.get('area')} onChange={this.changeState}
                    state={this.state}/>
          { !this.state.loading ?
            <button onClick={this.doChangeObservation}>
              Tallenna
            </button>
            :
            <div className='loader' />
          }
        </div>
      </div>
    );
  }
}

export const ViewObservation = props => {
  const observation = props.observation;

  if (observation == null) return null;

  const template = props.templates.find(template => template.get('id') === observation.get('template'));

  const templateFields = template.get('fields');
  let fields = [];

  observation.get('fields').forEach((value, key) => {
    const field = templateFields.find(field => field.get('name') === key);
    let viewValue = [];

    if (field.get('type') === 'checkbox') {
      viewValue = value ? 'Kyllä' : 'Ei';
    }
    else if (field.get('type') === 'radio' || field.get('type') === 'dropdown') {
      viewValue = field.get('values').get(value);
    }
    else if (field.get('type') === 'image' && value !== '-') {
      value.forEach((image, index) => {
        viewValue.push(<img key={index} className='observation-image-view'
                        alt={'Kuva ' + index} src={image.get('file')} />);
      });
    }
    else if (field.get('type') === 'video') {
      value.forEach((video, index) => {
        const file = video.get('file');
        viewValue.push(<video key={index} controls className='observation-image-view'>
                    <source src={file} type={file.substring(5, file.indexOf(';'))}/>
                    Selain ei tuo videoita
                    </video>);
      });
    }
    else if (field.get('type') === 'audio') {
      value.forEach((audio, index) => {
        const file = audio.get('file');
        viewValue.push(<audio  key={index} controls className='observation-image-view'>
                      <source src={file} type={file.substring(5, file.indexOf(';'))}/>
                      Selain ei tuo äänitiedostoja
                    </audio >);
      });
    }
    else if (field.get('type') === 'file') {
      value.forEach((file, index) => {
        viewValue.push(<p key={index}>
                        {file.get('file')}
                      </p >);
      });
    }
    else {
      viewValue = value;
    }

    fields.push(<span className='observation-view'>
                  <strong>{key}: </strong>
                  {viewValue}
                </span>);
  });

  const date = new Date(observation.get('time'));
  const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
                + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) +
                ':' + paddedNumber(date.getSeconds());

  return (
    <div onClick={props.close} className='modal'>
      <div onClick={e => e.stopPropagation()} id='observation-modal'>
        <h4>{observation.get('order')}. havainto ({template.get('name')})</h4>
        {time}
        <br/>
        {fields}
        <br/>
        <button className='button-outline' onClick={props.close}>
          Sulje
        </button>
      </div>
    </div>
  );
}

export const MapView = (properties) => {
  const props = properties;

  let map;
  let thisObservations = [];
  const [observationsState, setObservationsState] = useState([]);
  let draggingObject;
  let selectedLatitude;
  let selectedLongitude;
  let removingMark;

  const [selectedObservation, setSelectedObservation] = useState(null);
  const [hoveredObservation, setHoveredObservation] = useState(null);
  const [draggingObservation, setDraggingObservation] = useState(null);
  const [editingObservation, setEditingObservation] = useState(null);
  const [lockedLocation, setLockedLocation] = useState(true);
  const [position, setPosition] = useState([64.1, 26.5]);
  const [zoom, setZoom] = useState(6);
  const [maxZoom, setMaxZoom] = useState(16);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [drawInfo, setDrawInfo] = useState(false);
  const [findRoadNumber, setFindRoadNumber] = useState(null);
  const [findRoadPart, setFindRoadPart] = useState(null);
  const [findRoadDistance, setFindRoadDistance] = useState(0);
  const [newMarkRoadNumber, setNewMarkRoadNumber] = useState(null);
  const [newMarkRoadPart, setNewMarkRoadPart] = useState(null);
  const [newMarkRoadDistance, setNewMarkRoadDistance] = useState(0);
  const [marks, setMarks] = useState(localStorage.mapMarks != null ? JSON.parse(localStorage.mapMarks) : []);
  const [mapTilesUrl, setMapTilesUrl] = useState('');
  const [mapTilesAttribution, setMapTilesAttribution] = useState('');
  const [propertyName, setPropertyName] = useState();
  const [newMarkName, setNewMarkName] = useState();
  const [targetMark, setTargetMark] = useState();
  const [roadNumber, setRoadNumber] = useState(null);
  const [roadPart, setRoadPart] = useState(null);
  const [roadDistance, setRoadDistance] = useState(null);
  const [cityState, setCityState] = useState(null);
  const [addressState, setAddressState] = useState(null);
  const [distance, setDistance] = useState();
  const [distanceRoad, setDistanceRoad] = useState();

  const update = (props) => {
    if (draggingObservation) return;

    thisObservations = [];
    let observations;

    if (props.observations.size != null) {
      observations = props.observations.concat(fromJS(props.runningObservations));
    }
    else {
      observations = fromJS(props.runningObservations);
    }

    for (let i = 0; i < observations.size; i++) {
      if (observations.get(i).get('hide')) {
        continue;
      }

      const date = new Date(observations.get(i).get('time'));
      const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
        + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) +
        ':' + paddedNumber(date.getSeconds());

      let fields = [];
      let image = null;

      let template = props.templates.find(template => template.get('id') ===
        observations.get(i).get('template'));

      let color = '#000000'

      if (template != null) {
        const templateFields = template.get('fields');

        for (let templateField of templateFields) {
          const name = templateField.get('name');
          const type = templateField.get('type');
          const value = observations.get(i).get('fields').get(name);

          let valueString = [];

          if (value == null) {
            continue;
          }

          if (type === 'checkbox') {
            valueString = value && value !== '-' ? 'Kyllä' : 'Ei';
          }
          else if (type === 'date' && value !== '-') {
            const dateValue = new Date(value);
            valueString = dateValue.getDate() + '.' + (dateValue.getMonth() + 1) + '.' + dateValue.getFullYear()
              + ' ' + paddedNumber(dateValue.getHours()) + ':' + paddedNumber(dateValue.getMinutes());
          }
          else if ((type === 'image' || type === 'video' ||
            type === 'audio' || type === 'file') &&
            value !== '-' && value.length !== 0) {
            if (type === 'image' && image == null) {
              image = <img className='observation-image' alt='Havainto kuva' src={value[0].file} />;
            }

            valueString = value.length + ' kpl';
          }
          else {
            valueString = value;
          }

          fields.push(<span key={name}>
            <strong>{name + ': '}</strong>{valueString}
            <br />
          </span>);
        }

        color = template.get('color');
      }
      else {
        template = fromJS({
          name: 'Tuntematon',
          fields: {}
        })
      }

      const editingObs = editingObservation != null &&
        editingObservation.get('id') === observations.get(i).get('id');

      if (observations.get(i).get('path').size !== 0) {
        let path = [];


        if (observations.get(i).get('path').size === 1) {
          path.push([observations.get(i).get('path').get(0).get('latitude'), observations.get(i).get('path').get(0).get('longitude')],
            [observations.get(i).get('path').get(0).get('latitude'), observations.get(i).get('path').get(0).get('longitude')]);
        }
        else {
          for (let p = 0; p < observations.get(i).get('path').size - 1; p++) {
            path.push([observations.get(i).get('path').get(p).get('latitude'), observations.get(i).get('path').get(p).get('longitude')],
              [observations.get(i).get('path').get(p + 1).get('latitude'), observations.get(i).get('path').get(p + 1).get('longitude')]);
          }
        }

        const opacity = 0.7;

        if (props.drawingPdf) {
          thisObservations.push(
            <Polyline key={observations.get(i).get('id')}
              zIndexOffset={observations.get(i).get('order')}
              positions={path}
              weight={12}
              color={color}
              opacity={opacity} />
          );
        }
        else {
          thisObservations.push(
            <AntPath key={observations.get(i).get('id')}
              zIndexOffset={observations.get(i).get('order')}
              positions={path}
              options={{
                weight: 12,
                color: color,
                pulseColor: '#FFFFFF',
                opacity: opacity,
                delay: 1200,
                dashArray: [5, 80]
              }}
              onClick={selectObservation.bind(this, i)}
              onMouseOver={hoverObservation.bind(this, i)}>
              <Popup ref={ref => props.goToObservation === observations.get(i).get('id') && setTimeout(() =>
                x(ref, [observations.get(i).get('path').first().get('latitude'), observations.get(i).get('path').first().get('longitude')]))}
                autoPan={false}>
                <span>
                  <strong>{observations.get(i).get('order')}. Havainto ({template.get('name')})</strong>
                  <br />
                  {time}
                  {observations.get(i).get('roadData') ?
                    <span>
                      {observations.get(i).get('path').first().get('roadNumber') ?
                        <span>
                          <br />
                          <strong>Aloitus: </strong>
                          {observations.get(i).get('path').first().get('roadNumber')} /
                          {' ' + observations.get(i).get('path').first().get('roadPart')} /
                          {' ' + observations.get(i).get('path').first().get('roadDistance')}
                        </span>
                        : null
                      }
                      {observations.get(i).get('path').last().get('roadNumber') ?
                        <span>
                          <br />
                          <strong>Lopetus: </strong>
                          {observations.get(i).get('path').last().get('roadNumber')} /
                          {' ' + observations.get(i).get('path').last().get('roadPart')} /
                          {' ' + observations.get(i).get('path').last().get('roadDistance')}
                        </span>
                        : null
                      }
                    </span>
                    :
                    null
                  }
                  <br />
                  <strong>Pituus:</strong> {Math.round(observations.get(i).get('distance') * 10) / 10 + ' m'}
                  <br />
                  {fields}
                  {image}
                  {!props.readOnly ?
                    (observations.get(i).get('running') ?
                      <div>
                        <br />
                        <button className='observation-button' onClick={props.endObservation.bind(null, parseInt(i, 10) - props.observations.size)}>
                          Pysäytä
                        </button>
                      </div>
                      :
                      <div>
                        <br />
                        <button className='observation-button' onClick={props.goChangeObservation.bind(null, observations.get(i))}
                          disabled={template.get('fields').size === 0}>
                          Muokkaa
                        </button>
                        <br />
                        <button className='observation-button' onClick={props.confirmRemoveObservation.bind(null, observations.get(i))}>
                          Poista
                        </button>
                      </div>
                    )
                    :
                    <button className='observation-button' onClick={props.viewObservation.bind(null, observations.get(i))}>
                      Näytä
                    </button>
                  }
                </span>
              </Popup>
            </AntPath>
          );
        }
      }
      else if (observations.get(i).get('area') != null) {
        if (observations.get(i).get('area').get('type') === 1) {
          thisObservations.push(
            <Circle key={observations.get(i).get('id')}
              zIndexOffset={observations.get(i).get('order')}
              center={{
                lat: observations.get(i).get('area').get('position').get('lat'),
                lng: observations.get(i).get('area').get('position').get('lng')
              }}
              radius={observations.get(i).get('area').get('radius')}
              fillColor={color}
              color={color}
              onClick={selectObservation.bind(this, i)}
              onMouseOver={hoverObservation.bind(this, i)}
              ref={editingObs ? element => draggingObject = element : null}>
              <Popup ref={ref => props.goToObservation === observations.get(i).get('id') && setTimeout(() =>
                x(ref, [observations.get(i).get('area').get('position').get('lat'), observations.get(i).get('area').get('position').get('lng')]))}
                autoPan={false}>
                <span>
                  <strong>{observations.get(i).get('order')}. Havainto ({template.get('name')})</strong>
                  <br />
                  {time}
                  <br />
                  <strong>Säde:</strong> {Math.round(observations.get(i).get('area').get('radius') * 10) / 10 + ' m'}
                  <br />
                  {fields}
                  {image}
                  {!props.readOnly ? (
                    <div>
                      <button className='observation-button' onClick={props.goChangeObservation.bind(null, observations.get(i))}>
                        Muokkaa
                      </button>
                      <br />
                      {editingObs ?
                        <button className='observation-button' onClick={lockObservationLocation}>
                          Lukitse sijainti
                        </button>
                        :
                        <button className='observation-button' onClick={editObservationLocation.bind(this, observations.get(i))}>
                          Siirrä
                        </button>
                      }
                      <br />
                      <button className='observation-button' onClick={props.confirmRemoveObservation.bind(null, observations.get(i))}>
                        Poista
                      </button>
                    </div>
                  )
                    :
                    <button className='observation-button' onClick={props.viewObservation.bind(null, observations.get(i))}>
                      Näytä
                    </button>
                  }
                </span>
              </Popup>
            </Circle>
          );
        }
        else {
          const bounds = [[observations.get(i).get('area').get('position').get(0).get(0), observations.get(i).get('area').get('position').get(0).get(1)],
          [observations.get(i).get('area').get('position').get(1).get(0), observations.get(i).get('area').get('position').get(1).get(1)]]
          thisObservations.push(
            <Rectangle key={observations.get(i).get('id')}
              zIndexOffset={observations.get(i).get('order')}
              bounds={bounds}
              fillColor={color}
              color={color}
              onClick={selectObservation.bind(this, i)}
              onMouseOver={hoverObservation.bind(this, i)}
              ref={editingObs ? element => draggingObject = element : null}>
              <Popup ref={ref => props.goToObservation === observations.get(i).get('id') && setTimeout(() =>
                x(ref, bounds[0]))} autoPan={false}>
                <span>
                  <strong>{observations.get(i).get('order')}. Havainto ({template.get('name')})</strong>
                  <br />
                  {time}
                  <br />
                  <strong>Kanta:</strong> {Math.round(observations.get(i).get('area').get('width') * 10) / 10 + ' m'}
                  <br />
                  <strong>Korkeus:</strong> {Math.round(observations.get(i).get('area').get('height') * 10) / 10 + ' m'}
                  <br />
                  {fields}
                  {image}
                  {!props.readOnly ? (
                    <div>
                      <button className='observation-button' onClick={props.goChangeObservation.bind(null, observations.get(i))}>
                        Muokkaa
                      </button>
                      <br />
                      {editingObs ?
                        <button className='observation-button' onClick={lockObservationLocation}>
                          Lukitse sijainti
                        </button>
                        :
                        <button className='observation-button' onClick={editObservationLocation.bind(this, observations.get(i))}>
                          Siirrä
                        </button>
                      }
                      <br />
                      <button className='observation-button' onClick={props.confirmRemoveObservation.bind(null, observations.get(i))}>
                        Poista
                      </button>
                    </div>
                  )
                    :
                    <button className='observation-button' onClick={props.viewObservation.bind(null, observations.get(i))}>
                      Näytä
                    </button>
                  }
                </span>
              </Popup>
            </Rectangle>
          );
        }
      }
      else {
        if (props.drawingPdf) {
          thisObservations.push(
            <Marker key={observations.get(i).get('id')}
              zIndexOffset={observations.get(i).get('order')}
              position={[observations.get(i).get('latitude'), observations.get(i).get('longitude')]}
              icon={new L.Icon({
                iconUrl: (props.readOnly ? '../observation' : 'observation') + color.replace('#', '-') + '.png',
                iconSize: [25, 41],
                iconAnchor: [12.5, 41],
                popupAnchor: [null, -36],
              })} />
          );
        }
        else {
          thisObservations.push(
            <Marker key={observations.get(i).get('id')}
              zIndexOffset={observations.get(i).get('order')}
              position={[observations.get(i).get('latitude'), observations.get(i).get('longitude')]}
              icon={new L.divIcon({
                className: 'observation ' + (i === selectedObservation ? ' selected ' : '') + color.replace('#', 'color-'),
                iconSize: [25, 41],
                iconAnchor: [12.5, 41],
                popupAnchor: [null, -36],
                html: observations.get(i).get('order')
              })}
              onMouseOver={hoverObservation.bind(this, i)}
              onClick={selectObservation.bind(this, i)}
              draggable={editingObs}
              eventHandlers={markerEventHandlers}
              ref={ref => props.goToObservation === observations.get(i).get('id') && setTimeout(() =>
                x(ref, [observations.get(i).get('latitude'), observations.get(i).get('longitude')]))}>
              <Popup autoPan={false}>
                <span>
                  <strong>{observations.get(i).get('order')}. Havainto ({template.get('name')})</strong>
                  <br />
                  {time}
                  {observations.get(i).get('roadData') ?
                    <span>
                      <br />
                      <strong>Tie:</strong> {observations.get(i).get('roadNumber') ? observations.get(i).get('roadNumber') : '-'}
                      <strong> Osa:</strong> {observations.get(i).get('roadPart') ? observations.get(i).get('roadPart') : '-'}
                      <strong> Paalu:</strong> {observations.get(i).get('roadDistance') ? observations.get(i).get('roadDistance') : '-'}
                    </span>
                    :
                    null
                  }
                  <br />
                  {fields}
                  {image}
                  <br />
                  {!props.readOnly ? (
                    <div>
                      <button className='observation-button' onClick={props.goChangeObservation.bind(null, observations.get(i))}
                        disabled={template.get('fields').size === 0}>
                        Muokkaa
                      </button>
                      <br />
                      {editingObs ?
                        <button className='observation-button' onClick={lockObservationLocation}>
                          Lukitse sijainti
                        </button>
                        :
                        <button className='observation-button' onClick={editObservationLocation.bind(this, observations.get(i))}>
                          Siirrä
                        </button>
                      }
                      <br />
                      <button className='observation-button' onClick={props.confirmRemoveObservation.bind(null, observations.get(i))}>
                        Poista
                      </button>
                    </div>
                  )
                    :
                    <button className='observation-button' onClick={props.viewObservation.bind(null, observations.get(i))}>
                      Näytä
                    </button>
                  }
                </span>
              </Popup>
            </Marker>
          );
        }
      }
    }
    setObservationsState(thisObservations);
  }

  const markerEventHandlers = useMemo(
    () => ({
      dragstart() {
        startDragObservation();
      },
      dragend(event) {
        dragObservation(event);
      },
    }),
    [editingObservation],
  )  

  const x = (ref, pos) => {
    if (ref == null) return;
    ref.openPopup();
    map.setView(pos, 17);
  }

  const updateMarkDistance = async (mark, latitude, longitude, roadNumber, roadPart, roadDistance) => {
    if (roadNumber != null) {
      mark.roadDistanceToMark = await calculateRoadDistance(roadNumber, roadPart, roadDistance,
        mark.roadNumber, mark.roadPart, mark.roadDistance);
    }

    mark.distanceToMark = Math.round(calculateDistance(latitude, longitude,
      mark.latitude, mark.longitude) * 10) / 10;
  }

  const updateMarkDistances = async (latitude, longitude, roadNumber, roadPart, roadDistance) => {
    let marks1 = marks;

    for (let index in marks1) {
      let mark = marks1[index];
      await updateMarkDistance(mark, latitude, longitude, roadNumber, roadPart, roadDistance);
    }

    setMarks(marks1);
  }

  const selectObservation = (index) => {
    setSelectedObservation(index);
  }

  const hoverObservation = (index) => {
    setHoveredObservation(index);
  }

  const editObservationLocation = (observation) => {
    map.closePopup();
    setEditingObservation(observation);
    props.showNotice('Vedä havaintoa siirtääksesi', 'Ok');
  }

  useEffect(() => {
    update(props);
    if (draggingObject == null) return;

    draggingObject.on({
      mousedown: function () {
        startDragObservation();
        map.dragging.disable();
        map.on('mousemove', function (e) {
          if (draggingObject.setLatLng != null) {
            draggingObject.setLatLng(e.latlng);
          }
          else {
            const latitude = e.latlng.lat;
            const longitude = e.latlng.lng;
            const width = editingObservation.get('area').get('width');
            const height = editingObservation.get('area').get('height');
            const coordinates = calculateOffsetCoordinates(latitude, longitude,
              -width / 2, -height / 2);
            const otherSideCoordinates = calculateOffsetCoordinates(latitude, longitude,
              width / 2, height / 2);
            const bounds = [[coordinates.latitude, coordinates.longitude],
            [otherSideCoordinates.latitude, otherSideCoordinates.longitude]];
            draggingObject.setBounds(bounds);
          }
        }.bind(this));
        map.on('mouseup', function (e) {
          dragObservation(e);
          map.dragging.enable();
          map.off('mousemove');
          map.off('mouseup');
          draggingObject = null;
        }.bind(this));
      }.bind(this),
    });
  }, [editingObservation])  

  const startDragObservation = () => {
    setDraggingObservation(true);
  }

  const dragObservation = async (event) => {
    let latitude;
    let longitude;

    if (event.latlng != null) {
      latitude = event.latlng.lat;
      longitude = event.latlng.lng;
    }
    else {
      latitude = event.target._latlng.lat;
      longitude = event.target._latlng.lng;
    }

    const editingObs = editingObservation;
    let position;

    if (editingObs.get('area') != null) {
      if (editingObs.get('area').get('type') === 1) {
        position = { lat: latitude, lng: longitude }
      }
      else {
        const width = editingObs.get('area').get('width');
        const height = editingObs.get('area').get('height');
        const coordinates = calculateOffsetCoordinates(latitude, longitude,
          -width / 2, -height / 2);
        const otherSideCoordinates = calculateOffsetCoordinates(latitude, longitude,
          width / 2, height / 2);
        position = [[coordinates.latitude, coordinates.longitude],
        [otherSideCoordinates.latitude, otherSideCoordinates.longitude]];
      }
    }
    else {
      position = { latitude: latitude, longitude: longitude }
    }

    props.changeObservationLocation(editingObs.get('id'), position);

    setDraggingObservation(false);
  }

  const lockObservationLocation = () => {
    setEditingObservation(null);
  }

  const toggleLockedLocation = () => {
    setLockedLocation(!lockedLocation);
  }

  const setInfoCoordinates = async (event) => {
    if (selectedObservation != null) {
      setSelectedObservation(null);
    }

    let latitude = event.latlng.lat;
    let longitude = event.latlng.lng;

    if (props.selectLocation) {
      selectedLatitude = latitude;
      selectedLongitude = longitude;
      props.makeObservation();
      return;
    }

    const converted = toETRSTM35FIN(latitude, longitude);
    const data = await getRoadData(converted.y, converted.x, 10);

    if (data != null) {
      const xDifference = data.x - converted.x;
      const yDifference = data.y - converted.y;

      if (Math.sqrt((xDifference * xDifference) + (yDifference * yDifference)) <= 10) {
        const convertedRoadDataLocation = toWGS84(data.y, data.x);

        latitude = convertedRoadDataLocation.latitude;
        longitude = convertedRoadDataLocation.longitude;

        setRoadNumber(data.road);
        setRoadPart(data.part);
        setRoadDistance(data.distance);
      }
    }

    let address = data ? data.address : null;
    let city = data ? data.city : null;

    if (address == null) {
      const addressUrl = ROAD_URL2 + '/muunna?y=' + converted.y +
        '&x=' + converted.x + '&sade=10';

      const addressData = await (await window.fetch(addressUrl)).json();
      address = addressData.osoite;
      city = addressData.kunta;
    }

    setLatitude(latitude);
    setLongitude(longitude);
    setCityState(address);
    setAddressState(city);
  }

  const changeState = async (propertyName, type, defaultValue, event) => {
    const value = stateValueParser(event, type, defaultValue);

    if (value === undefined) {
      return;
    }

    switch (propertyName) {
      case 'newMarkName':
        setNewMarkName(value);
        break;
      case 'newMarkRoadNumber':
        setNewMarkRoadNumber(value);
        break;
      case 'newMarkRoadPart':
        setNewMarkRoadPart(value);
        break;
      case 'newMarkRoadDistance':
        setNewMarkRoadDistance(value);
        break;
      case 'findRoadInfo':
        setFindRoadInfo(value);
        break;
      case 'findRoadNumber':
        setFindRoadNumber(value);
        break;
      case 'findRoadPart':
        setFindRoadPart(value);
        break;
      case 'findRoadDistance':
        setFindRoadDistance(value);
        break;
    }
  }

  const makeNewMark = async () => {
    if (newMarkName == null || newMarkName === '') {
      props.showNotice('Nimeä ei ole annettu', 'Warning');
      return;
    }
    if (newMarkRoadNumber == null || newMarkRoadNumber === 0) {
      props.showNotice('Tienumeroa ei ole annettu', 'Warning');
      return;
    }
    if (newMarkRoadPart == null || newMarkRoadPart === 0) {
      props.showNotice('Tieosaa ei ole annettu', 'Warning');
      return;
    }
    if (newMarkRoadDistance == null) {
      props.showNotice('Paalua ei ole annettu', 'Warning');
      return;
    }

    setLockedLocation(false);

    const data = await getRoadCoordinates(newMarkRoadNumber,
      newMarkRoadPart,
      newMarkRoadDistance);

    if (data == null) {
      props.showNotice('Virheellinen tieosoite', 'Warning');
      return;
    }

    let address = data.address;
    let city = data.city;

    if (address == null) {
      const addressUrl = ROAD_URL2 + '/muunna?y=' + data.y +
        '&x=' + data.x + '&sade=10';

      const addressData = await (await window.fetch(addressUrl)).json();
      address = addressData.osoite;
      city = addressData.kunta;
    }

    const coordinates = toWGS84(data['y'], data['x']);

    let marks1 = marks;

    let mark = {
      id: marks1.length,
      name: newMarkName,
      latitude: coordinates.latitude,
      longitude: coordinates.longitude,
      roadNumber: newMarkRoadNumber,
      roadPart: newMarkRoadPart,
      roadDistance: newMarkRoadDistance,
      city: address,
      address: city,
    }

    await updateMarkDistance(mark, props.yourLatitude, props.yourLongitude,
      props.yourRoadNumber, props.yourRoadPart, props.yourRoadDistance);

    marks.push(mark);

    setMarks(marks1);

    map.setView([coordinates.latitude, coordinates.longitude], 17);

    props.toggleShowMakeMark();

    localStorage.mapMarks = JSON.stringify(marks);
  }

  const removeMark = () => {
    let marks1 = marks;
    if (targetMark != null &&
      targetMark.id === marks[removingMark].id) {
      setTargetMark(null);
    }
    marks.splice(removingMark, 1);
    setMarks(marks1);
    localStorage.mapMarks = JSON.stringify(marks1);
    props.update();
  }
  
  const confirmRemoveMark = (index) => {
    removingMark = index;
    props.showConfirm('Poistetaanko merkintä?', removeMark);
  }

  const findRoadInfo = async () => {
    if (findRoadNumber == null || findRoadNumber === 0) {
      props.showNotice('Tienumeroa ei ole annettu', 'Warning');
      return;
    }
    if (findRoadPart == null || findRoadPart === 0) {
      props.showNotice('Tieosaa ei ole annettu', 'Warning');
      return;
    }
    if (findRoadDistance == null) {
      props.showNotice('Paalua ei ole annettu', 'Warning');
      return;
    }

    setRoadNumber(null);
    setRoadPart(null);
    setRoadDistance(null);
    setCityState(null);
    setAddressState(null);
    setLockedLocation(false);

    const data = await getRoadCoordinates(findRoadNumber,
      findRoadPart,
      findRoadDistance);

    if (data == null) {
      props.showNotice('Virheellinen tieosoite', 'Warning');
      return;
    }

    let address = data.address;
    let city = data.city;

    if (address == null) {
      const addressUrl = ROAD_URL2 + '/muunna?y=' + data.y +
        '&x=' + data.x + '&sade=10';

      const addressData = await (await window.fetch(addressUrl)).json();
      address = addressData.osoite;
      city = addressData.kunta;
    }

    const coordinates = toWGS84(data['y'], data['x']);

    map.setView([coordinates.latitude, coordinates.longitude], 17);

    setLatitude(coordinates.latitude);
    setLongitude(coordinates.longitude);
    setRoadNumber(findRoadNumber);
    setRoadPart(findRoadPart);
    setRoadDistance(findRoadDistance);
    setAddressState(address);
    setCityState(city);

    props.toggleShowFindRoadInfo();
  }

  const toggleShowMakeMarkAndSetupRoad = (roadNumber, roadPart, roadDistance) => {
    setNewMarkRoadNumber(roadNumber);
    setNewMarkRoadPart(roadPart);
    setNewMarkRoadDistance(roadDistance);

    props.toggleShowMakeMark();
  }

  const setTargetMarkAndUpdate = (mark) => {
    properties.targetMarkSet(mark);
    setTargetMark(mark);
    update(props);
  }

  useEffect(() => {
    update(props);
  }, [targetMark])

  const openGoogleMapDirections = (targetLatitude, targetLongitude) => {
    if (props.yourLatitude == null) {
      props.showNotice('Sinun sijaintia ei ole paikannettu', 'Warning');
      return;
    }
    let googleURL = 'https://www.google.com/maps/dir/?api=1&origin=' +
      props.yourLatitude + ',' + props.yourLongitude +
      '&destination=' + targetLatitude + ',' + targetLongitude;
    window.open(googleURL, '_blank', 'location=yes');
  }

  function MapControl() {
    map = useMap();
    properties.setMap(map);

    useMapEvent('click', (event) => {
      setInfoCoordinates(event);
    });

    const mapCenter = map.getCenter();
    const converted = toETRSTM35FIN(mapCenter.lat, mapCenter.lng);
    const mapTiles = MapTiles(converted.x, converted.y);

    if (mapTilesUrl !== mapTiles.url) {
      setMapTilesUrl(mapTiles.url);
      setMapTilesAttribution(mapTiles.attribution);
      setMaxZoom(mapTiles.maxZoom);
    }
  }  

  useEffect(() => {
    if (lockedLocation && props.yourLatitude) {
      map.setView([props.yourLatitude, props.yourLongitude], 17);
    }
    update(props);
  }, [props.yourLatitude, props.yourLongitude, lockedLocation])

  useEffect(() => {
    if (props.noOwnLocation && props.observations.size !== 0 &&
      props.areaBound != null) {
      map.fitBounds(nextProps.areaBound);
    }
    update(props);
  }, [props.areaBound])

  useEffect(() => {
    updateMarkDistances(props.yourLatitude, props.yourLongitude,
      props.yourRoadNumber, props.yourRoadPart,
      props.yourRoadDistance);
    update(props);
  }, [props.yourLatitude, props.yourLongitude, props.yourRoadDistance])

  useEffect(() => {
    setDrawInfo(true);
    setDistance(null);
    setDistanceRoad(null);
    update(props);
    setDrawInfo(false);
  }, [latitude, longitude])

  useEffect(() => {
    if (props.goToObservation != null) {
      const observations = props.observations.concat(fromJS(props.runningObservations));
      const index = observations.findIndex(observervation => observervation.get('id') === props.goToObservation);
      const observation = observations.get(index);

      if (observation.get('path').size !== 0) {
        map.setView([observation.get('path').get(observation.get('path').size / 2).get('latitude'),
        observation.get('path').get(observation.get('path').size / 2).get('longitude')], 17);
      }
      else if (observation.get('area') != null) {
        let latitude;
        let longitude;

        if (observation.get('area').get('type') === 1) {
          latitude = observation.get('area').get('position').get('lat');
          longitude = observation.get('area').get('position').get('lng');
        }
        else {
          latitude = observation.get('area').get('position').get(0).get(0);
          longitude = observation.get('area').get('position').get(0).get(1);
        }
        map.setView([latitude, longitude], 17);
      }
      else {
        map.setView([observation.get('latitude'), observation.get('longitude')], 17);
      }

      setSelectedObservation(index);
    }
  }, [props.goToObservation])    

  useEffect(() => {
    update(props);
  }, [props.observations])    

  return (
    <div>
      <MapContainer id="observation-map" center={position} zoom={zoom} maxZoom={maxZoom} preferCanvas={true}>
      <MapControl />
        <TileLayer url={mapTilesUrl}
          attribution={mapTilesAttribution}
          maxZoom={maxZoom} />
        {props.lockedRoadPath.length !== 0 ?
          <Polyline positions={props.lockedRoadPath}
            weight={12}
            color={'#FFFF00'}
            opacity={0.6} />
          : null
        }
        {props.yourLatitude != null ? (
          <Marker position={[props.yourLatitude, props.yourLongitude]}
            icon={new L.Icon({
              iconUrl: 'your_location.gif',
              iconSize: [18, 43],
              iconAnchor: [9, 43],
              popupAnchor: [null, -43]
            })}
            zIndexOffset={-1000}>
            <Popup autoPan={false}>
              <span>
                Sijaintisi
                <br />
                {Math.round(props.yourLatitude * 100000) / 100000}, {Math.round(props.yourLongitude * 100000) / 100000}
              </span>
            </Popup>
          </Marker>
        ) : null}

        {observationsState}

        {marks.map((mark, index) => (
          <CircleMarker key={index}
            center={{ lat: mark.latitude, lng: mark.longitude }}
            radius={12}
            color='#0000FF'
            fillColor='#0000FF'
            fillOpacity={1}>
            <Popup autoPan={false}>
              <span>
                <strong>{mark.name}</strong>
                <br />
                {Math.round(mark.latitude * 100000) / 100000}, {Math.round(mark.longitude * 100000) / 100000}
                <br />
                <strong>Tie:</strong> {mark.roadNumber ? mark.roadNumber : '-'}
                <strong> Osa:</strong> {mark.roadPart ? mark.roadPart : '-'}
                <strong> Paalu:</strong> {mark.roadDistance != null ? mark.roadDistance : '-'}
                {mark.address != null ?
                  <span>
                    <br />
                    {mark.address} {mark.city}
                  </span>
                  : '-'}
                <br />
                <strong>Tie etäisyys:</strong> {(mark.roadDistanceToMark || '-') + ' m'}
                <br />
                <strong>Etäisyys:</strong> {(mark.distanceToMark || '-') + ' m'}
                <button className={'observation-button'}
                  onClick={openGoogleMapDirections.bind(this, mark.latitude, mark.longitude)}>
                  Näytä reitti tänne
                </button>
                <button className={'observation-button'}
                  onClick={setTargetMarkAndUpdate.bind(this, mark)}>
                  Aseta kohteeksi
                </button>
                <button className={'observation-button'}
                  onClick={confirmRemoveMark.bind(this, index)}>
                  Poista
                </button>
              </span>
            </Popup>
          </CircleMarker>
        ))}

        {latitude != null && !drawInfo ? (
          <Popup position={[latitude, longitude]}
            popup autoPan={false}>
            <span>
              {Math.round(latitude * 100000) / 100000 + ', ' + Math.round(longitude * 100000) / 100000}
              <br />
              <strong>Tie:</strong> {roadNumber ? roadNumber : '-'}
              <strong> Osa:</strong> {roadPart ? roadPart : '-'}
              <strong> Paalu:</strong> {roadDistance != null ? roadDistance : '-'}
              <strong> Osoite:</strong> {addressState ? addressState + ', ' + cityState : '-'}
              <button className={'observation-button'}
                onClick={toggleShowMakeMarkAndSetupRoad.bind(this, roadNumber,
                  roadPart, roadDistance)}>
                Aseta merkintä
              </button>
              <button className={'observation-button'}
                onClick={openGoogleMapDirections.bind(this, latitude, longitude)}>
                Näytä reitti tänne
              </button>
            </span>
          </Popup>
        )
          :
          null
        }
      </MapContainer>
      {!props.noOwnLocation && !props.showNewObservation ?
        <label className={lockedLocation ? 'lock-location selected' : 'lock-location'}>
          SEURAA SIJAINTIASI
          <input type='checkbox' checked={lockedLocation}
            onChange={toggleLockedLocation} />
        </label>
        : null
      }
      <MakeMark onChange={changeState}
        show={props.showMakeMark}
        toggle={props.toggleShowMakeMark}
        makeNewMark={makeNewMark}
        newMarkName={newMarkName}
        newMarkRoadNumber={newMarkRoadNumber}
        newMarkRoadPart={newMarkRoadPart}
        newMarkRoadDistance={newMarkRoadDistance} />
      <FindRoadInfo onChange={changeState}
        show={props.showFindRoadInfo}
        toggle={props.toggleShowFindRoadInfo}
        findRoadInfo={findRoadInfo}
        findRoadNumber={findRoadNumber}
        findRoadPart={findRoadPart}
        findRoadDistance={findRoadDistance} />
    </div>
  );
};
