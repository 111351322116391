import React from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { showNotice } from '../../../../src/notice/Actions';
import FindSelect from '../../../../src/findSelect/FindSelect';
import { fetch } from '../utils';
import { addReport } from './Actions';
import './Report.css';


class ReportNew extends React.Component {
  constructor(props) {
    super(props);

    this.getUsers();

    this.userSelect = null;

    this.state = {
      name: '',
      startTime: new Date().toISOString().substring(0, 10),
      users: [],
      selectedUsers: [],
      loadingUsers: true
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.addUser = this.addUser.bind(this);
  }

  changeState(propertyName, event) {
    const value = event.target.value;
    this.setState({
      [propertyName]: value
    });
  }

  addUser() {
    const newUser = this.userSelect.state.value;

    if (newUser === '') {
      return;
    }

    const exist = this.state.selectedUsers.find(user => user === newUser);

    if (!exist) {
      let users = this.state.selectedUsers;
      users.push(newUser);
      this.setState({
        selectedUsers: users,
      });
      this.userSelect.clearValue();
    }
    else {
      this.props.showNotice('Kyseinen käyttäjä on jo lisätty', 'Warning');
    }
  }

  removeUser(index) {
    let users = this.state.selectedUsers;
    users.splice(index, 1);
    this.setState({
      selectedUsers: users,
    });
  }

  getUsers() {
    fetch('query Person{ personList{email} }').then(response => {
      this.setState({
        users: response.data.personList
      });
    }).catch(error => {
      this.props.showNotice('Palvelimeen ei saatu yhteyttä', 'Warning');
    }).then(() => {
      this.setState({
        loadingUsers: false
      });
    });
  }

  saveReport(report) {
    if (typeof(Storage) === 'undefined') {
      this.props.showMessage('Virhe', 'Raporttia ei voitu tallentaa paikallisesti eikä palvelimelle', 'Error');
      return;
    }

    if (localStorage['savedReports'] == null) {
      localStorage['savedReports'] = JSON.stringify([]);
    }

    report['id'] = Date.now();
    report['state'] = {id: 0};
    report['not_saved'] = true;

    let reports = JSON.parse(localStorage['savedReports']);
    reports.push(report);
    localStorage['savedReports'] = JSON.stringify(reports);
    this.props.addReport(report);
    this.props.showNotice('Raportti luotu laitteelle ja lisätään palvelimelle kun saadaan yhteys palautettua', 'Warning');
  }

  onSubmit() {
    const report = {
                    name: this.state.name,
                    startTime: this.state.startTime,
                    receivers: this.state.selectedUsers,
                    state: {id: 1}
                   };

    fetch(`mutation NewReport {
              reportCreate(report: {
                  name:` + JSON.stringify(report.name) +
                  ` startTime:` + JSON.stringify(report.startTime) +
                  ` receivers:` + JSON.stringify(report.receivers) +
                  ` state: {id: 1}
              }) {
                  id
                  reporter{id}
                  state{
                      id
                      name
                  }
              }
          }`
    ).then(data => {
      this.props.showNotice('Raportti luotu', 'Ok');
    }).catch(error => {
      this.saveReport(report);
    }).then(() => {
      this.setState({
        redirect: true,
      });
    });
  }

  render() {
    if (this.state.redirect) return <Navigate to='/reports' push/>;

    return (
    <div className="container">
      <h1>Lisää raportti</h1>
      <label htmlFor='name'>Nimi</label>
      <input id='name' type='text'
             onChange={this.changeState.bind(this, 'name')}
             value={this.state.name}/>
      <label htmlFor='startTime'>Aloitus aika</label>
      <input id='startTime' type='date'
             onChange={this.changeState.bind(this, 'startTime')}
             value={this.state.startTime}/>
      <label htmlFor='user-list'>Lisää saajien sähköposteja</label>
      <div id='user-list'>
        {
          this.state.selectedUsers.map((user, index) => (
            <div key={user}>
              <div className='user-list-part'>{user}</div>
              <button className="button-outline" onClick={this.removeUser.bind(this, index)}>
                Poista
              </button>
            </div>
          ))
        }
      </div>
      <FindSelect ref={element => this.userSelect = element} items={this.state.users}
                  data={['id', 'email']}/>
      <button className="button-outline" onClick={this.addUser}>
        Lisää sähköposti
      </button>
      <br />
      <br />
      <button className="button-primary" onClick={this.onSubmit}>
        Lisää
      </button>
      <Link to="/reports/">
        <button className="button-outline">Peruuta</button>
      </Link>
    </div>
    );
  }
}

export default connect(state => ({
}), { showNotice, addReport })(ReportNew);
