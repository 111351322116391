import { fromJS } from 'immutable';

const initialState = fromJS({
  reports: [],
  templates: []
});

export default (state = initialState,
    action) => {
  switch (action.type) {
    case 'ADD_REPORTS': {
      const reports = fromJS(action.reports).sort((a, b) => b.get('id') - a.get('id'));;
      return state.set('reports', reports);
    }

    case 'REMOVE_REPORT': {
      const removableReport = state.get('reports').findIndex(report => report.get('id') === fromJS(action.report));
      if (removableReport !== -1) {
        return state.set('reports', state.get('reports').delete(removableReport));
      }
      return state;
    }
  
    case 'ADD_REPORT': {
     const newReport = fromJS(action.report);
      if (state.get('reports').find(report => report.get('id') === newReport.get('id')) === undefined) {
        return state.set('reports', state.get('reports').unshift(newReport));
      }
      return state;
    }

    case 'CHANGE_REPORT': {
      const changingIndex = state.get('reports').findIndex(report => report.get('id') === fromJS(action.report.id));
      
      if (changingIndex === -1) return state;

      if (state.get('reports').get(changingIndex) === fromJS(action.report)) {
        return state;
      }
      const newReports = state.get('reports').set(changingIndex, fromJS(action.report));
      return state.set('reports', newReports);
    }

    case 'ADD_TEMPLATES': {
      return state.set('templates', fromJS(action.templates));
    }
  
    case 'ADD_TEMPLATE': {
     const newTemplate = fromJS(action.template);
      if (state.get('templates').find(template => template.get('id') === newTemplate.get('id')) === undefined) {
        return state.set('templates', state.get('templates').push(newTemplate));
      }
      return state;
    }

    case 'CHANGE_TEMPLATE': {
      const changingIndex = state.get('templates').findIndex(template => template.get('id') === fromJS(action.template.id));
      
      if (changingIndex === -1) return state;

      if (state.get('templates').get(changingIndex) === fromJS(action.template)) {
        return state;
      }
      const newtemplates = state.get('templates').set(changingIndex, fromJS(action.template));
      return state.set('templates', newtemplates);
    }

    case 'REMOVE_TEMPLATE': {
      const removableTemplate = state.get('templates').findIndex(template => template.get('id') === fromJS(action.template));
      if (removableTemplate !== -1) {
        return state.set('templates', state.get('templates').delete(removableTemplate));
      }
      return state;
    }

    default: {
      return state;
    }
  }
};