import React from 'react';
import './ExtraMenu.css';


export default class ExtraMenu extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      showExtraMenu: false
    };

    this.toggleExtraMenu = this.toggleExtraMenu.bind(this);
  }

  toggleExtraMenu() {
    this.setState({
      showExtraMenu: !this.state.showExtraMenu
    });
  }

  render() {
    if (this.props.items == null) {
      return null;
    }

    if (!this.state.showExtraMenu) return <div id='extra-menu-button' onClick={this.toggleExtraMenu}/>

    return (
      <div className='modal-message' onClick={this.toggleExtraMenu}>
        <div id='extra-menu'>
        { this.props.items.map((item, index) => (
            <div key={item} className='extra-menu-item' onClick={this.props.functions[index]}>
              {item}
            </div>
          ))
        }
        </div>
      </div>
    );
  }
}
