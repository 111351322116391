const MinAccuracy = 1;

let QMetresPerSecond = 11;    
let lastTimeStamp;
let latitude;
let longitude;
let variance = -1; // P matrix.  Negative means object uninitialised


export function kalmanFilter(latitudeMeasurement, longitudeMeasurement, accuracy, timeStamp,
                             speed) {
    if (speed) {
        QMetresPerSecond = speed / 3.6;
    }

    if (accuracy < MinAccuracy) accuracy = MinAccuracy;

    // if variance < 0, object is unitialised, so initialise with current values
    if (variance < 0) {
        lastTimeStamp = timeStamp;
        latitude = latitudeMeasurement;
        longitude = longitudeMeasurement;
        variance = accuracy * accuracy;
    }
    // else apply Kalman filter methodology
    else {
        let timeInc = timeStamp - lastTimeStamp;

        if (timeInc > 0) {
            // time has moved on, so the uncertainty in the current position increases
            variance += timeInc * QMetresPerSecond * QMetresPerSecond / 1000;
            lastTimeStamp = timeStamp;
            
            // TO DO: USE VELOCITY INFORMATION HERE TO GET A BETTER ESTIMATE OF CURRENT POSITION
        }

        // Kalman gain matrix K = Covarariance * Inverse(Covariance + MeasurementVariance)
        // NB: because K is dimensionless, it doesn't matter that variance has different units to lat and lng
        const K = variance / (variance + accuracy * accuracy);

        latitude += K * (latitudeMeasurement - latitude);
        longitude += K * (longitudeMeasurement - longitude);
        // new Covarariance  matrix is (IdentityMatrix - K) * Covarariance 
        variance = (1 - K) * variance;
    }

    return {
        latitude: latitude,
        longitude: longitude
    }
}

export function resetKalmanFilter() {
    variance = -1;
}
