import React from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { showNotice } from '../../../../src/notice/Actions';
import { showConfirm } from '../../../../src/confirm/Actions';
import FindSelect from '../../../../src/findSelect/FindSelect';
import { fetch, timer } from '../utils';
import { changeReport } from './Actions';
import { fromJS } from 'immutable';
import { STATE_NAMES } from './Reports';
import './Report.css';


class ReportEdit extends React.Component {
  constructor(props) {
    super(props);
    
    this.userSelect = null;

    this.state = {
      users: [],
      selectedUsers: [],
      loadingUsers: true,
      editingReport: null
    };

    this.addUser = this.addUser.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.confirmRemoveReport = this.confirmRemoveReport.bind(this);
    this.removeReport = this.removeReport.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.getUsers();
    this.getReport(parseInt(this.props.params.id, 10));
  }

  changeState(propertyName, event) {
    const value = event.target.value;
    this.setState({
      [propertyName]: value
    });
  }

  getReport(id) {
    const foundReport = this.props.reports.find(report => report.get('id') === id);

    if (foundReport == null) {
      fetch('query Report{ report(id: ' + id + '){ id, name, startTime, reporter{id, first_name, last_name}, state{id}, receivers } }').then(response => {
        const data = response.data;
        if (data != null) {
          this.setState({
            editingReport: fromJS(data.report),
            name: data.report.name,
            startTime: data.report.startTime,
            state: data.report.state.id,
            selectedUsers: data.report.receivers
          });
        }
        else {
          this.setState({
            redirect: true,
          });
        }
      }).catch(error => {
        this.setState({
          redirect: true,
        });
        console.log(error);
      });
    }
    else {
      let users = [];

      foundReport.get('receivers').forEach(user => (
        users.push(user)
      ));

      this.setState({
        editingReport: foundReport,
        name: foundReport.get('name'),
        startTime: foundReport.get('startTime'),
        state: foundReport.get('state').get('id'),
        selectedUsers: users
      });
    }
  }

  getUsers() {
    fetch('query Persons{ personList{email} }').then(response => {
      this.setState({
        users: response.data.personList
      });
    }).catch(error => {
      this.props.showNotice('Palvelimeen ei saatu yhteyttä', 'Warning');
    }).then(() => {
      this.setState({
        loadingUsers: false
      });
    });
  }

  saveReport(report) {
    if (typeof(Storage) === 'undefined') {
      this.props.showMessage('Virhe', 'Raporttia ei voitu tallentaa paikallisesti eikä palvelimelle', 'Error');
      return;
    }

    if (localStorage['savedReports'] == null) {
      localStorage['savedReports'] = JSON.stringify([]);
    }

    report['state'] = {id: report.state};
    report['not_saved'] = true;

    let reports = JSON.parse(localStorage['savedReports']);

    const foundReportIndex = reports.findIndex(r => r.id === report.id)

    if (foundReportIndex !== -1) {
      reports.splice(foundReportIndex, 1);
    }

    reports.push(report);
    localStorage['savedReports'] = JSON.stringify(reports);
    this.props.changeReport(report);
    this.props.showNotice('Raportti muokattu laitteelle ja muokataan myös palvelimelle kun saadaan yhteys palautettua', 'Warning');
  }

  addUser() {
    const newUser = this.userSelect.state.value;

    if (newUser === '') {
      return;
    }

    const exist = this.state.selectedUsers.find(user => user === newUser);

    if (!exist) {
      let users = this.state.selectedUsers;
      users.push(newUser);
      this.setState({
        selectedUsers: users,
      });
      this.userSelect.clearValue();
    }
    else {
      this.props.showNotice('Kyseinen käyttäjä on jo lisätty', 'Warning');
    }
  }

  removeUser(index) {
    let users = this.state.selectedUsers;
    users.splice(index, 1);
    this.setState({
      selectedUsers: users,
    });
  }

  onSubmit() {
    const report = {
                  id: this.state.editingReport.get('id'),
                  name: this.state.name,
                  startTime: this.state.startTime,
                  state: this.state.state,
                  receivers: this.state.selectedUsers,
                };

    fetch(`mutation ReportChange{
            reportUpdate(id:` + report.id + `,
              report: {
                name:` + JSON.stringify(report.name) +
                ` startTime:` + JSON.stringify(report.startTime) +
                ` receivers:` + JSON.stringify(report.receivers) +
                ` state: {id:` + report.state + `}
              }
            )
            { id }
          }`
    ).then(response => {
      if (response.data.reportUpdate.id != null) {
        this.props.showNotice('Raportti muokattu', 'Ok');
      }
      else {
        this.saveReport(report);
      }
    }).catch(error => {
      this.saveReport(report);
    }).then(() => {
      this.setState({
        redirect: true,
      });
    });
  }

  confirmRemoveReport(event) {
    event.preventDefault();
    this.props.showConfirm('Poistetaanko raportti (' + this.state.editingReport.get('name') +
                           ') ja kaikki sen havainnot?', this.removeReport);
  }
  
  removeReport() {
    // Without timer does not work. Error: (Reducers may not dispatch actions.)
    timer(0).then(() => {
      if (typeof(Storage) !== 'undefined' &&
          localStorage['savedReports'] != null &&
          JSON.parse(localStorage['savedReports']).length !== 0) {
        let reports = JSON.parse(localStorage['savedReports']);
        reports = reports.filter(reports => reports['id'] !== this.state.editingReport.get('id'));
        localStorage['savedReports'] = JSON.stringify(reports);
      }

      if (localStorage['savedObservations'] != null) {
        let observations = JSON.parse(localStorage['savedObservations']);
        observations = observations.filter(observation => observation['report'] !== this.state.editingReport.get('id'));
        localStorage['savedObservations'] = JSON.stringify(observations);
      }

      if (localStorage['savedRunningObservations'] != null) {
        let runningObservations = JSON.parse(localStorage['savedRunningObservations']);
        runningObservations = runningObservations.filter(observation => observation['report'] !== this.state.editingReport.get('id'));
        localStorage['savedRunningObservations'] = JSON.stringify(runningObservations);
      }

      if (this.state.editingReport.get('notSaved') == null) {
        fetch(`mutation RemoveReport{
                          reportDelete(id:` + this.state.editingReport.get('id') + `)
                          { success }
                        }`
        ).then(data => {
          this.props.showNotice('Raportti poistettu', 'Ok');
          this.setState({
            redirect: true,
          });
        }).catch(error => {
          this.props.showNotice('Raportin poisto epäonnistui', 'Error');
        });
      }
      else {
        this.props.showNotice('Raportti poistettu', 'Ok');
        this.setState({
          redirect: true,
        });
      }
    });
  }

  render() {
    if (this.state.redirect) return <Navigate to='/reports' push/>;
    
    if (this.state.editingReport == null) {
      return (
        <div className="container">
          <div className="loader"></div>
        </div>
      );
    }

    return (
      <div className="container">
        <h1>Muokkaa raporttia</h1>
        <h2>{this.state.editingReport.get('name')}</h2>
        <label htmlFor='name'>Nimi</label>
        <input id='name' type='text'
              onChange={this.changeState.bind(this, 'name')}
              value={this.state.name}/>
        <label htmlFor='startTime'>Aloitusaika</label>
        <input id='startTime' type='date'
              onChange={this.changeState.bind(this, 'startTime')}
              value={this.state.startTime}/>
        <label htmlFor='state'>Tila</label>
        <select id='state' onChange={this.changeState.bind(this, 'state')}
                value={this.state.state}>
          <option value='1'>{STATE_NAMES[1]}</option>
          <option value='2'>{STATE_NAMES[2]}</option>
          <option value='3'>{STATE_NAMES[3]}</option>
        </select>
        <label htmlFor='user-list'>Kohde sähköpostit</label>
        <div id='user-list'>
          {
            this.state.selectedUsers.map((user, index) => (
              <div key={user}>
                <div className='user-list-part'>{user}</div>
                <button className="button-outline" onClick={this.removeUser.bind(this, index)}>
                  Poista
                </button>
              </div>
            ))
          }
        </div>
        <FindSelect ref={element => this.userSelect = element} items={this.state.users}
                    data={['id', 'email']}/>
        <button className="button-outline" onClick={this.addUser}>
          Lisää sähköposti
        </button>
        <br />
        <br />
        <button className="button-primary" onClick={this.onSubmit}>
          Tallenna
        </button>
        <Link className="button-primary" to="/reports/">
          <button className="button-outline">Peruuta</button>
        </Link>
        <button className="float-right red-text button-outline"
                onClick={this.confirmRemoveReport}>
          Poista
        </button>
      </div>
    );
  }
}

export default connect(state => ({
  reports: state.report.get('reports'),
}), { showNotice, changeReport, showConfirm })(ReportEdit);
