import React from 'react';
import './Report.css'

export const ReportSelect = props => (
  <div id='report-select'>
    <select id='report'
            className={props.value == null ? 'required' : ''}
            onChange={props.onChange.bind(this, 'selectedReport', 'integer', null)}
            value={props.value || ''}>
      <option value=''>
        Valitse raportti
      </option>
      {
        props.reports.map(report => {
          return (
            <option key={report.get('id')} value={report.get('id')}>
              {report.get('name')}
            </option>
          );
        })
      }
    </select>
  </div>
);

export const ObservationTemplateSelect = props => {
  let running = {};

  props.runningObservations.forEach((runningObservation, index) => {
    if (running[runningObservation.template] == null) {
      running[runningObservation.template] = index;
    }
  });

  let templates = props.templates;

  for (let i = 0; i < templates.size; i++) {
    let template = templates.get(i);
    const color = template.get('color').replace('#', 'color-');
    template = template.set('color', color);
    templates = templates.set(i, template);
  }

  const templateOrder = props.templateOrder;

  templates = templates.sort((a, b) => {
    const valueA = templateOrder[a.get('id')] || 0;
    const valueB = templateOrder[b.get('id')] || 0;
    return valueB - valueA;
  });

  return (
    <div id='observation-template-area'>
        {
          templates.map(template => {
            if (template.get('hidden')) {
              return null;
            }
            return (
              <div key={template.get('id')}
              className={'observation-template-select ' + template.get('color') + (props.creatingObservation ? ' disabled' : '')}
                   onClick={running[template.get('id')] == null ? props.selectTemplate.bind(this, template.get('id'))
                   : props.endObservation.bind(this, running[template.get('id')])}>
                { running[template.get('id')] != null ?
                <div className='running'>
                  {'Käynnissä'}
                </div>
                :
                null
                }
                {template.get('name')}
              </div>
            );
          })
        }
        { props.creatingObservation ? <div className='main loader' /> : null }
    </div>
  );
}
