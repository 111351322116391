import React from "react";
import { combineReducers, createStore } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes, useLocation, useParams } from "react-router-dom";
import LoginReducer from '../../../../src/login/Reducer.js';
import MenuReducer from '../../../../src/menu/Reducer.js';
import MessageReducer from '../../../../src/message/Reducer.js';
import NoticeReducer from '../../../../src/notice/Reducer.js';
import ConfirmReducer from '../../../../src/confirm/Reducer.js';
import ObservationReducer from "../observation/Reducer";
import ReportReducer from "../report/Reducer";
import Login from '../../../../src/login/Login.js';
import Logout from '../../../../src/login/Logout.js';

import App from "./Application.js";
import Observation from "../observation/Observation.js";
import Reports from "../report/Reports.js";
import ReportNew from "../report/ReportNew.js";
import ReportEdit from "../report/ReportEdit.js";

export const store = createStore(
  combineReducers({
    login: LoginReducer,
    menu: MenuReducer,
    message: MessageReducer,
    notice: NoticeReducer,
    confirm: ConfirmReducer,
    observation: ObservationReducer,
    report: ReportReducer,
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const AppWithLocation = ({ store }) => {
  const location = useLocation();

  return <App store={store} location={location} />;
};

const ObservationWithLocation = ({ store }) => {
  const location = useLocation();

  return <Observation store={store} location={location} />;
};

const ReportEditWithParams = ({ store }) => {
  const params = useParams();

  return <ReportEdit store={store} params={params} />;
}

const Root = () => (
  <Provider store={store}>
    <span id="timer" />
    <BrowserRouter>
      <AppWithLocation store={store} />
      <Routes>
        <Route path="/" element={<></>} />
        <Route path="/login" element={<Login store={store} />} />
        <Route path="/logout" element={<Logout store={store} />} />
        <Route path="/new" element={<ObservationWithLocation store={store} />} />
        <Route path="/edit" element={<ObservationWithLocation store={store} />} />
        <Route path="/reports" element={<Reports store={store} />} />
        <Route path="/reports/new" element={<ReportNew store={store} />} />
        <Route path="/report/edit/:id" element={<ReportEditWithParams store={store} />} />
        <Route path="/report/:id" element={<ObservationWithLocation store={store} />} />
      </Routes>
    </BrowserRouter>
  </Provider>
);

export default Root;
