import React from 'react';
import { connect } from 'react-redux';
import Form from "@rjsf/core";
import { login } from './Actions';
import { showMessage } from '../message/Actions';
import { showNotice } from '../notice/Actions';
import { transformErrors, fetch } from '../utils.js';
import validator from '@rjsf/validator-ajv8';


const schema = {
  type: "object",
  required: ["username", "password"],
  properties: {
    username: { type: "string", title: "Sähköposti", format: "email" },
    password: { type: "string", title: "Salasana" }
  },
  messages: {
    required: {
      username: "Käyttäjänimi on vaadittu kenttä",
      password: "Salasana on vaadittu kenttä",
    }
  }
};


const uiSchema = {
  username: {
    "ui:autofocus": true,
    "ui:placeholder": "esimerkki@esim.fi"
  },
  password: {
    "ui:widget": "password",
  }
};


class Login extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      userFetched: true,
      formData: {}
    };

    this.readMessage = this.readMessage.bind(this);
    this.logIn = this.logIn.bind(this);
  }

  UNSAFE_componentWillMount () {
    try {
      if (window.self !== window.top) {
        window.addEventListener("message", this.readMessage);
        return;
      }
    } catch (e) {

    }
    this.getProfile();
  }

  componentWillUnmount () {
    window.removeEventListener('message', this.readMessage);
  }


  getProfile () {
    if (localStorage['login'] == null) {
      return;
    }

    fetch('/user/profile/', 'GET', null, true).then(request => {
      request.json().then(json => {
        this.props.login(json);
      }).catch(error => {
        localStorage.removeItem('login');
        this.forceUpdate();
      });
    }).catch(error => {
      localStorage.removeItem('login');
      this.forceUpdate();
    });
  }

  readMessage (e) {
    if (e.data.type !== 'storage') return;

    localStorage['login'] = e.data.login;
    localStorage['contract'] = e.data.contract;
    localStorage['site'] = e.data.site;

    this.getProfile();
  }

  logIn ({ formData }) {
    this.setState({ userFetched: false });
    fetch('/login', 'POST', { 'email': formData.username.toLowerCase(), 'password': formData.password }, true)
      .then(request => {
        localStorage['login'] = request.headers.get('x-nevia-auth');
        request.json().then(json => {
          this.props.login(json);
          this.props.showNotice('Kirjautuminen onnistui', 'Success');
        });
      })
      .catch(error => {
        if (error.message === '401') {
          this.props.showMessage('Huomatus', 'Käyttäjätunnus tai salasana on/ovat väärin', 'Warning');
          return;
        }

        this.props.showMessage('Virhe', 'Palvelimeen ei saatu yhteyttä', 'Error');
      }).then(user => {
        this.setState({
          userFetched: true
        });
      });
  }


  render () {
    if (localStorage['login'] != null) {
      return (
        <div id="loading">
          <div id='logo' />
          <div className='loader' />
        </div>
      );
    }
    return (
      <div className="container page">
        <h1>Kirjaudu sisään</h1>
        <Form schema={schema}
        validator={validator}
          uiSchema={uiSchema}
          transformErrors={transformErrors}
          onSubmit={this.logIn}
          formData={this.state.formData}
          onChange={({ formData }) => this.setState({ formData })} >
          {!this.state.userFetched ? (
            <div>
              <button type="submit" disabled>Kirjaudu</button>
              <div className='loader' />
            </div>
          )
            :
            (
              <div>
                <button type="submit">Kirjaudu</button>
              </div>
            )
          }
        </Form>
      </div>
    );
  }
}


export default connect(state => ({
  user: state.login.get('user'),
}), { login, showMessage, showNotice })(Login);
